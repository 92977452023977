import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import { Tooltip } from '@mui/material';
import './CommonMyListButton.scss';

const CommonListButton = ({ list = [], navigateTo }) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(navigateTo);
  };

  const getTheListButton = () => (
    <Button
      variant="contained"
      size="large"
      startIcon={<PersonIcon className="icon" />}
      onClick={handleButtonClick}
      disabled={!list.length}
      className={`button ${list.length ? 'hasItems' : ''}`} 
    >
      MY LIST ({list.length})
    </Button>
  );

  return (
    <div className="buttonWrapper">
      {list.length ? (
        getTheListButton()
      ) : (
        <Tooltip title="No items added" arrow>
          <div>{getTheListButton()}</div>
        </Tooltip>
      )}
    </div>
  );
};

export default CommonListButton;
