import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { supportedLangs } from "./LanguageList";

function localizationUtility() {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
      supportedLngs: supportedLangs,
      fallbackLng: "en",
      detection: {
        order: ["cookie", "htmlTag", "localStorage", "path", "subdomain"],
        caches: ["cookie"],
      },
      backend: {
        loadPath: "/assets/locale/{{lng}}/translation.json",
      },
    });
}

export { localizationUtility };
