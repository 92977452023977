import React, { useState, useEffect } from 'react';
import BMHeader from './BMHeader';
import { useLocation, useParams } from 'react-router-dom';
import './ProductDetailsPage.scss';
import { getProductDetails } from './BMService';
import LoadingIndicator from '../Common/LoadingIndicator';
import ProductInfoTable from './ProductInfoTable';
import ResolutionDisclaimer from '../Common/ResolutionDisclaimer';
import CommonErrorFallback from '../Common/CommonErrorFallback';

const ProductDetailsPage = () => {
  const { productId } = useParams();
  const location = useLocation();

  const collectionId = location.state?.collectionId ;
  const collectionName = location.state?.collectionName;
  const [apiFailReported, setApiFailReported] = useState(false);
  const [productDetails, setProductDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchProductDetails();
  }, [productId]);

  const fetchProductDetails = async () => {
    try {
      const productDetailsResponse = await getProductDetails({ rowsPerPage: 1, page: 1, productId: Number(productId) });
      setProductDetails(productDetailsResponse.data.data);
    } catch (error) {
      console.error('Failed to fetch product details:', error);
      setApiFailReported(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="product-details-page-wrapper">
      {isLoading && <LoadingIndicator />}
      <BMHeader
        collectionId={collectionId}
        collectionName={collectionName}
        productName={productDetails?.shortDescription}
      />
      {apiFailReported ? (
        <CommonErrorFallback />
      ) : (
        <ProductInfoTable productData={productDetails} />
      )}
      <ResolutionDisclaimer />
    </div>
  );
};

export default ProductDetailsPage;
