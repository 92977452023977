import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  myList: [],
  myListData: []
};

const PBCatalogSlice = createSlice({
  name: 'pbCatalog',
  initialState,
  reducers: {
    addItemToList(state, action) {
      state.myList= action.payload;
      localStorage.setItem("pb_my_list",JSON.stringify(action.payload));
    },
    addItemsDataToList(state, action) {
      state.myListData= action.payload;
    }
  },
});

export const { addItemToList, addItemsDataToList } = PBCatalogSlice.actions;

export default PBCatalogSlice.reducer;
