import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { localizationUtility } from "./Constants/LocalisationUtility";
import store from "./components/Assets/PrivateBrandCatalog/Store";
import { Provider } from "react-redux";

localizationUtility();

const loadingMarkup = <h5>Loading...</h5>;

const rootContainer = document.getElementById("root");
const root = createRoot(rootContainer);
root.render(
  <Provider store={store}>
    <Suspense fallback={loadingMarkup}>
      <React.StrictMode>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </React.StrictMode>
    </Suspense>
  </Provider>
);
