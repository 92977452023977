import { React, useState, useEffect, forwardRef } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { FiImage } from "react-icons/fi";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import Dropzone from "react-dropzone";
import { Avatar } from "@mui/material";
import LinearProgressBar from "../../../Utils/LinearProgressBar";
import FileViewer from "react-file-viewer";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { add_file, create_post, transcoder } from "../../../config/environment";
import { useSelector } from "react-redux";

var axios = require("axios");
var qs = require("qs");

const ToggleAlert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Wrapper = styled.div`
  margin-bottom: 2rem;
  padding: 2rem;
  padding-bottom: 0rem;
  padding-top: 1rem;
  background: white;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  border: 1px solid #e0dede;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.08);
`;
const ProgressWrapper = styled.div`
  margin-bottom: 2rem;
  padding: 2rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  background: white;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  border: 1px solid #e0dede;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.08);
`;
const TextInput = styled.div`
  padding: 1rem;
  border-radius: 1.5rem;
  border-style: none;
  border: 1px solid #f1eeee;
  width: 100%;
  resize: none;
  margin-bottom: 0rem;
  height: 15px;
  background-color: rgb(252 249 249);
  color: grey;
  align-items: center;
  display: flex;
  &: focus-visible {
    outline-width: 0;
    border: 1px solid #797878;
  }
`;
const ConfirmOptionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const ConfirmOption = styled.button`
  width: 10%;
  padding: 0.2rem;
  margin-right: 0.3rem;
  height: 1.5rem;
  border: none;
  border-radius: 2rem;
  font-size: small;
  background-color: ${(props) =>
    props.disabled ? "rgb(220 217 217)" : "#147350"};
  color: ${(props) => (props.disabled ? "grey" : "white")};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  margin: 1rem 1rem;
  margin-right: 0.2rem;
  position: relative;
  text-align: center;
  @media screen and (max-width: 930px) {
    height: 1.8rem;
    width: 13%;
  }
  @media screen and (max-width: 600px) {
    width: 18%;
  }
`;
const ConfirmUpload = styled.button`
  width: 4rem;
  padding: 0.1rem 0rem;
  height: 1.7rem;
  border-radius: 2rem;
  font-size: small;
  border: none;
  background-color: ${(props) =>
    props.disabled ? "rgb(220 217 217)" : "#147350"};
  color: ${(props) => (props.disabled ? "grey" : "white")};
  cursor: ${(props) => (props.disabled ? "arrow" : "pointer")};
  margin: 1rem 0.5rem;
  margin-right: 0;
  position: relative;
  text-align: center;
`;
const ConfirmCancel = styled.button`
  width: 4rem;
  padding: 0.1rem 0rem;
  height: 1.7rem;
  border-radius: 2rem;
  border: 1px solid #147350;
  font-size: small;
  background-color: white;
  color: rgb(20, 115, 80);
  cursor: pointer;
  margin: 1rem 1rem;
  margin-right: 0;
  position: relative;
  text-align: center;
`;
const PostOptionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 3rem;
`;
const PostOptionImage = styled.button`
  padding: ${(props) => (props.showImgDnD ? "1rem" : "1rem 0.5rem 1rem 0rem")};
  margin: 0.2rem 0.2rem 0.5rem;
  height: 2.5rem;
  background: ${(props) =>
    props.showImgDnD ? "rgb(234,234,234)" : "#ffffff00"};
  border: none;
  border-radius: 0.3rem;
  color: black;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;
const PostOptionVideo = styled.button`
  padding: ${(props) => (props.showVidDnD ? "1rem" : "1rem 0.5rem 1rem 0rem")};
  margin: 0.2rem 0.2rem 0.5rem;
  height: 2.5rem;
  background: ${(props) =>
    props.showVidDnD ? "rgb(234,234,234)" : "#ffffff00"};
  border: none;
  border-radius: 0.3rem;
  color: black;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;
const PostOptionFile = styled.button`
  padding: ${(props) => (props.showFileDnD ? "1rem" : "1rem 0.5rem 1rem 0rem")};
  margin: 0.2rem 0.2rem 0.5rem;
  height: 2.5rem;
  background: ${(props) =>
    props.showFileDnD ? "rgb(234,234,234)" : "#ffffff00"};
  border: none;
  border-radius: 0.3rem;
  color: black;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;
const PreviewImgContainer = styled.div`
  max-height: auto;
  max-width: auto;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
`;
const PreviewImgBlock = styled.div`
  width: 46%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-flow: nowrap;
  place-content: flex-start;
  padding: 0.5rem;
`;
const PreviewFileBlock = styled(PreviewImgBlock)`
  width: 95%;

  ${FileViewer}.pg-viewer-wrapper {
    width: 100%;
    height: 10rem;
    border: 1px solid rgb(241, 238, 238);
    border-radius: 0.4rem;
    padding: 1rem;
  }
  ${FileViewer}.document-container {
    width: 95%;
    height: 95%;
  }
`;
const PreviewImg = styled.img`
  width: 100%;
  height: auto;
`;
const PreviewVid = styled.video`
  width: 100%;
  height: auto;
`;
const HorizontalLine = styled.div`
  border-top: 1px solid rgb(233 224 224);
  margin-top: 1rem;
`;
const IconContainer = styled.div`
  margin: 0.5rem;
  margin-left: 0rem;
  display: inline-block;
`;
const ModalWrapper = styled.div`
  position: fixed;
  top: 0rem;
  left: 0rem;
  right: 0;
  bottom: 0;
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
`;
const ModalBody = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 0px 1rem;
  padding-top: 2px;
  width: 70%;
  max-width: 38rem;
  max-height: 85vh;
  /* overflow-y: scroll; */
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
`;
const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 600px) {
    justify-content: center;
    height: auto;
    max-height: 3rem;
  }
`;
const ModalUploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 1rem;
  border: 1px solid #f1eeee;
  width: 90%;
  overflow-y: scroll;
  max-height: 20rem;
  height: auto;
  @media (max-width: 600px) {
    max-height: 12rem;
  }
  & .ck.ck-editor,
  & .editor-block {
    width: 95%;
    display: block;
    margin: 2.5% auto 0 auto;
  }
`;
const ModalUploadComponent = styled.div`
  /* overflow-y: scroll; */
  padding: 1rem 0 0.5rem 1rem;
  width: 100%;
`;
const AvatarUploadComponentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 1rem;
  width: 100%;
  overflow-y: hidden;
  /* @media (max-width: 600px) {
    max-height: 15rem;
  } */
`;
const ModalFileInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 0.5rem;
`;
const DropDiv = styled.div`
  min-width: 150px;
  width: 20rem;
  min-height: 50px;
  height: 140px;
  border: 1px dashed black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.5rem 0 0.5rem 0;
  padding-bottom: 0.5rem;
  cursor: pointer;

  &.disable-cls {
    opacity: 0.4;
    cursor: default;
  }
`;
const ProgressBarSection = styled.div`
  width: 98%;
`;
const ProgressBarComponent = styled.div`
  display: flex;
  flex-direction: row;
  width: 80%;
`;
const ProgressBarContainer = styled.div`
  width: 100%;
  height: 0.2rem;
  border: 0.25px solid #c7c7c7;
  border-radius: 2rem;
  margin: 0.3rem 0;
`;
const ProgressBarFiller = styled.div`
  height: 100%;
  width: ${(props) => props.progress}%;
  max-width: 100%;
  background-color: #516ea0;
  border-radius: 2rem;
  transition: width 0.7s linear;
`;
const PBarPercentContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const PercentContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 0.5rem;
  align-items: center;
`;
const ProgressBarList = styled.li`
  list-style: none;
  margin-top: 0.5rem;
  padding-bottom: 0;
`;
const ProgressBarUList = styled.ul`
  padding-left: 0;
`;
const FileInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
const FilePBContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 69%;
`;
const NameSizeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const DetailPara = styled.p`
  font-size: 0.7rem;
  line-height: 0.8rem;
`;
const DetailParaBold = styled(DetailPara)`
  font-weight: 800;
`;
const CloseProgressBtnContainer = styled.div`
  display: flex;
  position: relative;
`;
const DropDivIconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: -0.5rem;
`;
const DropDivText = styled.p`
  display: flex;
  justify-content: center;
`;
const DropDivText2nd = styled.p`
  display: flex;
  justify-content: center;
  font-size: 0.7rem;
  color: gray;
`;

const CreatePost = (props) => {
  const { t } = useTranslation();
  const [text, setText] = useState("");
  const [uploadFile, setUploadFile] = useState([]);
  const [diffFileType, setDiffFileType] = useState(false);
  const [fileSizeLimit, setFileSizeLimit] = useState(false);
  const [fileNumLimit, setFileNumLimit] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const [showImgDnD, setShowImgDnD] = useState(false);
  const [showVidDnD, setShowVidDnD] = useState(false);
  const [showFileDnD, setShowFileDnD] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [progress, setProgress] = useState(null);
  const [valProgress, setValProgress] = useState(0);
  const [showPercent, setShowPercent] = useState(false);
  const [showFallback, setShowFallback] = useState(false);
  const [isEditorReady, setIsEditorReady] = useState(false);
  // const create_post = process.env.REACT_APP_CREATE_POST;
  // const transcoder = process.env.REACT_APP_TRANSCODER;
  // const add_file = process.env.REACT_APP_ADD_FILE;
  const checkAccessToken = localStorage.getItem("access_token");
  const {userFullName= ""} = useSelector(state=> state.userManagement);
  let signedPutUrls = [];

  uploadFile.sort((a, b) => (a.file.size > b.file.size ? 1 : -1));

  const acceptedImageTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "image/gif",
    "image/svg+xml",
    "image/webp",
    "image/bmp",
    "image/x-icon",
    "image/tiff",
  ];
  const acceptedVideoTypes = [
    "video/mp4",
    "video/webm",
    "video/ogg",
    "video/quicktime",
    "video/x-msvideo",
    "video/mpeg",
    "video/x-ms-wmv",
    "video/x-flv",
  ];
  const acceptedFileTypes = [
    "application/pdf",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];

  const trimFileName = (fileName) => {
    const index = fileName.lastIndexOf('.');
    return fileName.substring(0, index).replace(/[^a-zA-Z0-9]/g, "_") + fileName.substring(index);
  };

  const updateDb = () => {
    let bodyArr = [];
    uploadFile.map((files) => {
      let temp = [];
      const fileName = trimFileName(files.file.name); // files.file.name.replace(/[^a-zA-Z0-9.]/g, "_");
      if (
        files.file.type.substring(0, 5) === "video" &&
        files.file.size > 52428800
      ) {
        temp.push(`7in_post/Video/${fileName}`);
        temp.push(files.file.type);
        temp.push(`7in_post/${fileName}`);
      } else {
        temp.push(`7in_post/${fileName}`);
        temp.push(files.file.type);
      }
      bodyArr.push(temp);
    });
    var axiosData = qs.stringify({
      postText: text,
      accessToken: checkAccessToken,
      postFileKeys: bodyArr,
    });
    var config = {
      method: "post",
      url: create_post,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: axiosData,
    };
    axios(config)
      .then((data) => {
        const putURLs = data.data.body.message;
        for (let i = 0; i < uploadFile.length; i++) {
          var setURL = uploadFile[i].file;
          var config = {
            method: "put",
            body: setURL,
          };
          var requestOptions = {
            headers: { ContentType: uploadFile[i].file.type },
            redirect: "follow",
          };
        }
        props.handlePendingPostReload();
        setApiCalled(false);
        setValProgress(0);
      })
      .catch(() => {
        setShowFallback(true);
      });
  };

  const transcoderAPI = async ({ key, i }) => {
    let axiosData = qs.stringify({
      accessToken: checkAccessToken,
      key: key,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: transcoder,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: axiosData,
    };
    axios
      .request(config)
      .then((response) => {
        if (i === uploadFile.length - 1) {
          updateDb();
        }
      })
      .catch((error) => {
        if (i === uploadFile.length - 1) {
          updateDb();
        }
      });
  };

  const createPostNew = async () => {
    setApiCalled(true);
    let myHeaders = new Headers();
    let resStatus, key;
    if (uploadFile.length === 0) {
      const test1 = await updateDb();
    } else {
      return await Promise.all(
        uploadFile.map(async (singleFile, i, uploadFile) => {
          /* let fileName = singleFile.file.name,
          index = fileName.lastIndexOf('.');
          const file_name = fileName.substring(0, index).replace(/[^a-zA-Z0-9.]/g, "_"),
          fileType = fileName.substring(index); */
          const fileName = trimFileName(singleFile.file.name);
          key = "7in_post/" + Date.now() + fileName;
          myHeaders.append("key", key);
          myHeaders.append("filetype", singleFile.file.type);
          try {
            const resp = await axios.request({
              method: "post",
              url: add_file,
              headers: {
                fileType: singleFile.file.type,
                key: `7in_post/${fileName}`,
              },
              data: qs.stringify({
                accessToken: checkAccessToken,
              }),
            });
            if (uploadFile.length === 1) {
              setApiCalled(true);
              axios
                .request({
                  method: "put",
                  url: `${resp.data.body.message}`,
                  headers: {
                    "Content-Type": singleFile.file.type,
                  },
                  transformRequest: (data, headers) => {
                    delete headers.common['Authorization'];
                    return data;
                  },
                  data: singleFile.file,
                  onUploadProgress: (p) => {
                    setApiCalled(true);
                    const curProgress = (p.loaded / p.total) * 100;
                    setValProgress((prevProgress) =>
                      prevProgress >= 100 ? 100 : curProgress
                    );
                  },
                })
                .then(async (data) => {
                  const key = `7in_post/${trimFileName(singleFile.file.name)}`;
                  if (
                    singleFile.file.type.substring(0, 5) === "video" &&
                    singleFile.file.size > 52428800
                  ) {
                    transcoderAPI({ key, i });
                  } else if (i === uploadFile.length - 1) {
                    const test1 = await updateDb();
                  }
                  myHeaders.delete("filetype");
                  myHeaders.delete("key");
                })
                .catch((err) => {
                  setShowFallback(true);
                });
            } else {
              setApiCalled(true);
              let requestOptions = {
                method: "PUT",
                body: singleFile.file,
              };
              const res = await axios
                .request({
                  method: "put",
                  url: `${resp.data.body.message}`,
                  headers: {
                    "Content-Type": singleFile.file.type,
                  },
                  transformRequest: (data, headers) => {
                    delete headers.common['Authorization'];
                    return data;
                  },
                  data: singleFile.file,
                  onUploadProgress: (p) => {
                    if (i === uploadFile.length - 1) {
                      setApiCalled(true);
                      const curProgress = (p.loaded / p.total) * 100;
                      setValProgress((prevProgress) =>
                        prevProgress >= 100 ? 100 : curProgress
                      );
                    }
                  },
                })
                .then(async (data) => {
                  const key = `7in_post/${trimFileName(singleFile.file.name)}`;
                  if (
                    singleFile.file.type.substring(0, 5) === "video" &&
                    singleFile.file.size > 52428800
                  ) {
                    transcoderAPI({ key, i });
                  } else if (i === uploadFile.length - 1) {
                    const test1 = await updateDb();
                  }
                })
                .catch((err) => {
                  setShowFallback(true);
                });
              signedPutUrls.push(resp.data.body.message);
              myHeaders.delete("filetype");
              myHeaders.delete("key");
            }
          } catch (err) {
            setApiCalled(false);
            resStatus = 500;
          }
        })
      );
    }
  };

  useEffect(() => {
    if (progress === null) {
      return;
    }
    setTimeout(() => {
      setUploadFile((prevFiles) =>
        prevFiles.map((file) => {
          if (!file.isUploaded) {
            const newProgress = file.progress + 20;
            if (newProgress <= 100) {
              setProgress(newProgress);
            }
            return {
              ...file,
              progress: newProgress,
              isUploaded: newProgress === 100 ? true : false,
            };
          } else {
            return { ...file };
          }
        })
      );
    }, 500);
    if (progress >= 100) {
      setProgress(null);
    }
    return () => {};
  }, [progress]);

  function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["B", "KB", "MB", "GB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  useEffect(() => {
    const len = uploadFile.length;
    if (!len) return;
    if (len > 10) {
      setFileNumLimit(true);
    } else {
      setFileNumLimit(false);
    }
    setTimeout(() => {
      uploadElement.scrollTop = uploadElement.scrollHeight;
    }, 500);
  }, [uploadFile]);

  const removeFile = (objToRemove) => {
    const newUploadFile = uploadFile.filter((obj) => obj !== objToRemove);
    setUploadFile(newUploadFile);

    if (uploadFile.length === 1) {
      setShowPreview(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    if (text) {
      setText("");
    }
    if (showPreview) {
      setShowPreview(false);
    }
    if (showPercent) {
      setShowPercent(false);
    }
    if (uploadFile.length) {
      setUploadFile([]);
    }
    if (showImgDnD) setShowImgDnD(false);
    if (showVidDnD) setShowVidDnD(false);
    if (showFileDnD) setShowFileDnD(false);
  };

  const handleToggleModal = () => {
    setShowModal((prevState) => !prevState);
  };

  const currentUser = userFullName;

  function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string?.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  function stringAvatar(name) {
    name = String(name).toUpperCase();
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${
        name?.includes(" ")
          ? name?.split(" ")[0][0] + "" + name?.split(" ")[1][0]
          : name?.[0][0]
      }`,
    };
  }

  const uploadElement = document.getElementById("uploadElement");

  let postOptions;
  postOptions = (
    <>
      <PostOptionImage
        showImgDnD={showImgDnD}
        onClick={(e) => {
          setShowModal(true);
          setShowFileDnD(false);
          setShowVidDnD(false);
          setShowPreview(false);
          setShowImgDnD(true);
          if (diffFileType) setDiffFileType(false);
          if (fileSizeLimit) setFileSizeLimit(false);
          if (fileNumLimit) setFileNumLimit(false);
          e.stopPropagation();
        }}
      >
        <IconContainer>
          <FiImage color="rgb(69 87 172)" size={"22.5px"} />
        </IconContainer>
        Image
      </PostOptionImage>
      <PostOptionVideo
        showVidDnD={showVidDnD}
        onClick={(e) => {
          setShowModal(true);
          setShowImgDnD(false);
          setShowFileDnD(false);
          setShowPreview(false);
          setShowVidDnD(true);
          if (diffFileType) setDiffFileType(false);
          if (fileSizeLimit) setFileSizeLimit(false);
          if (fileNumLimit) setFileNumLimit(false);
          e.stopPropagation();
        }}
      >
        <IconContainer>
          <AiOutlinePlayCircle color="rgb(193 2 63)" size={"22.5px"} />
        </IconContainer>
        Video
      </PostOptionVideo>
      <PostOptionFile
        showFileDnD={showFileDnD}
        onClick={(e) => {
          setShowModal(true);
          setShowImgDnD(false);
          setShowVidDnD(false);
          setShowPreview(false);
          setShowFileDnD(true);
          if (diffFileType) setDiffFileType(false);
          if (fileSizeLimit) setFileSizeLimit(false);
          if (fileNumLimit) setFileNumLimit(false);
          e.stopPropagation();
        }}
      >
        <IconContainer>
          <svg
            width="22"
            height="22"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.61687 24.25C6.54687 24.25 4.57937 23.4175 3.07687 21.9062C0.0918676 18.9025 -0.0306323 14.14 2.80312 11.2887L12.0356 2.00125C12.9969 1.03375 14.2944 0.5 15.6881 0.5C17.1794 0.5 18.5969 1.09875 19.6781 2.18625C21.8294 4.35 21.9119 7.78875 19.8606 9.85125L10.6169 19.1375C10.0181 19.7412 9.21187 20.0725 8.34687 20.0725C7.43312 20.0725 6.56687 19.7075 5.90937 19.0462C4.59312 17.72 4.55187 15.6062 5.81812 14.3312L14.3494 5.7625C14.8369 5.2725 15.6269 5.27 16.1169 5.7575C16.6056 6.245 16.6081 7.03625 16.1206 7.525L7.59062 16.095C7.29062 16.3975 7.33187 16.9312 7.68312 17.2838C7.86562 17.4675 8.10812 17.5725 8.34687 17.5725C8.48437 17.5725 8.68187 17.5387 8.84437 17.375L18.0881 8.08875C19.1719 6.9975 19.0906 5.14125 17.9056 3.94875C16.7719 2.80875 14.8481 2.71875 13.8081 3.76375L4.57562 13.0513C2.70812 14.93 2.83062 18.1125 4.85062 20.1437C5.87937 21.18 7.21687 21.75 8.61687 21.75C9.86812 21.75 11.0281 21.2775 11.8806 20.42L21.1144 11.1325C21.6006 10.6437 22.3919 10.64 22.8819 11.1275C23.3706 11.615 23.3731 12.405 22.8869 12.895L13.6531 22.1825C12.3281 23.515 10.5394 24.25 8.61687 24.25Z"
              fill="#E69B63"
            />
          </svg>
        </IconContainer>
        File
      </PostOptionFile>
    </>
  );

  let uploadBtnOptions;
  uploadBtnOptions = (
    <>
      <ConfirmCancel
        onClick={(e) => {
          setShowPreview(false);
          setUploadFile([]);
          if (showImgDnD) setShowImgDnD(false);
          if (showVidDnD) setShowVidDnD(false);
          if (showFileDnD) setShowFileDnD(false);
          if (showPercent) setShowPercent(false);
          if (diffFileType) setDiffFileType(false);
          if (fileSizeLimit) setFileSizeLimit(false);
          if (fileNumLimit) setFileNumLimit(false);
        }}
      >
        Cancel
      </ConfirmCancel>
      <ConfirmUpload
        onClick={() => {
          if (showImgDnD) setShowImgDnD(false);
          if (showVidDnD) setShowVidDnD(false);
          if (showFileDnD) setShowFileDnD(false);
          if (showPercent) setShowPercent(false);
          if (diffFileType) setDiffFileType(false);
          if (fileSizeLimit) setFileSizeLimit(false);
          if (fileNumLimit) setFileNumLimit(false);
          setShowPreview(true);
          uploadElement.scrollTop = 0;
        }}
        disabled={uploadFile.length > 10 ? true : false}
      >
        Upload
      </ConfirmUpload>
    </>
  );

  let dropDiv;
  dropDiv = ({ getRootProps, getInputProps }) => (
    <DropDiv
      {...getRootProps()}
      className={uploadFile.length >= 10 ? "disable-cls" : ""}
    >
      <input {...getInputProps()} />
      <DropDivIconContainer>
        <svg
          width="100"
          height="50"
          viewBox="0 0 135 82"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M97.401 23.3593C96.9013 23.3593 96.405 23.3776 95.9134 23.4108C94.2511 13.928 85.8376 6.71298 75.7067 6.71298C71.0738 6.71298 66.8004 8.22254 63.3667 10.7671C59.6562 4.3377 52.627 0 44.565 0C32.6363 0 22.966 9.49652 22.966 21.2116C22.966 21.9538 23.0051 22.6869 23.0809 23.4097C22.5915 23.3765 22.0975 23.3593 21.599 23.3593C9.67026 23.3593 0 32.8559 0 44.571C0 56.2861 9.67026 65.7826 21.599 65.7826H97.401C109.33 65.7826 119 56.2861 119 44.571C119 32.8559 109.33 23.3593 97.401 23.3593Z"
            fill="#F1F2F2"
          />
          <path
            d="M78.9148 53.64H41V40.4199H46.7105V48.0535H73.2043V40.4199H78.9148V53.64Z"
            fill="#686867"
          />
          <path
            d="M53.5753 30.5619V44.4373H66.3391V30.5619H71.8153L59.9578 18.96L48.0991 30.5619H53.5753Z"
            fill="#516EA0"
          />
        </svg>
      </DropDivIconContainer>
      <DropDivText>click to upload, or drag n drop</DropDivText>
      <DropDivText2nd>Maximum file size is 200Mb</DropDivText2nd>
    </DropDiv>
  );

  let progressComp = (
    <ProgressBarSection id="progressElement">
      <ProgressBarUList>
        {uploadFile.map((file, index) => (
          <ProgressBarList key={`${file.file.name}_${index}`}>
            <ProgressBarComponent>
              <FiImage
                color="#231F20"
                size={"22.5px"}
                style={{ marginRight: "0.3rem" }}
              />
              <FilePBContainer>
                <FileInfoContainer>
                  <NameSizeContainer>
                    <DetailParaBold>{file.file.name}</DetailParaBold>
                    <DetailPara>{formatBytes(file.file.size)}</DetailPara>
                  </NameSizeContainer>
                  <CloseProgressBtnContainer>
                    <RxCross2
                      style={{
                        height: "0.7rem",
                        width: "0.6rem",
                        position: "absolute",
                        right: "0",
                        top: "0.25rem",
                        background: "white",
                        color: "black",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        removeFile(file);
                      }}
                    />
                  </CloseProgressBtnContainer>
                </FileInfoContainer>
                <PBarPercentContainer>
                  <ProgressBarContainer>
                    <ProgressBarFiller progress={file.progress} />
                  </ProgressBarContainer>
                  <PercentContainer>
                    {file.progress <= 100 && (
                      <DetailPara>{file.progress}%</DetailPara>
                    )}
                    {file.progress > 100 && <DetailPara>100%</DetailPara>}
                  </PercentContainer>
                </PBarPercentContainer>
              </FilePBContainer>
            </ProgressBarComponent>
          </ProgressBarList>
        ))}
      </ProgressBarUList>
    </ProgressBarSection>
  );

  const handleFileUpload = (acceptedFiles, fileExt, acceptedExtTypes) => {
    const filteredFiles = acceptedFiles.filter((file) =>
      acceptedExtTypes.includes(file.type)
    );
    if (acceptedFiles.length !== filteredFiles.length) {
      setDiffFileType(true);
      return;
    }
    const sizeFilteredFiles = filteredFiles.filter(
      (file) => file.size <= 209715200
    );
    if (filteredFiles.length !== sizeFilteredFiles.length) {
      setFileSizeLimit(true);
    }
    const newFiles = sizeFilteredFiles.map((file) => ({
      file,
      progress: 0,
      isUploaded: false,
      type: fileExt,
      fileExt: file.type,
      fileURL: URL.createObjectURL(file),
    }));
    setProgress(0);
    setUploadFile([...uploadFile, ...newFiles]);
  };

  const DialogPopup = (props) => {
    return (
      <Dialog
        open={showFallback}
        onClose={() => {
          setShowFallback(false);
          document.location.reload();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent
          sx={{
            padding: "24px 18px 0px 18px",
          }}
        >
          <DialogContentText id="alert-dialog-description">
            Oops! Something went wrong.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "0px 8px 0px 8px",
            display: "flex",
          }}
        >
          <button
            style={{
              backgroundColor: "rgb(182, 180, 180)",
              color: "white",
              cursor: "pointer",
              margin: "1rem 0rem",
              marginRight: "0.8rem",
              position: "relative",
              border: "1px solid rgb(210, 210, 210)",
              fontSize: "15px",
              width: "3.5rem",
              height: "1.5rem",
              borderRadius: "3px",
            }}
            onClick={() => {
              setShowFallback(false);
              document.location.reload();
            }}
          >
            Okay
          </button>
        </DialogActions>
      </Dialog>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      setIsEditorReady(true);
    }, 500);
  }, []);

  return (
    <>
      {apiCalled ? (
        <ProgressWrapper>
          <h4
            style={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            {valProgress < 90
              ? "Uploading your post..."
              : valProgress === 100
              ? "Processing your file..."
              : "Almost there..."}
          </h4>
          <LinearProgressBar value={valProgress} />
        </ProgressWrapper>
      ) : (
        <Wrapper>
          <AvatarUploadComponentWrapper>
            {props.displayPic?.split("//").length <= 2 ? (
              <Avatar
                alt="Profile Picture"
                src={props.displayPic}
                style={{
                  padding: "0rem",
                  margin: "0rem 0.5rem 0.5rem 0rem",
                  top: "-3px",
                  width: "3.5rem",
                  height: "3.5rem",
                  cursor: "pointer",
                  border: "1px solid rgb(222,222,222)",
                }}
                onClick={() => {
                  props.setProfileClicked(true);
                }}
              />
            ) : (
              <Avatar
                {...stringAvatar(
                  String(
                    props.userFullName ? props.userFullName.trim() : currentUser
                  ).toUpperCase()
                )}
                style={{
                  padding: ".1rem",
                  margin: "0 0.5rem 0 .5rem",
                  top: "5px",
                  scale: "1",
                  cursor: "pointer",
                }}
                onClick={() => {
                  props.setProfileClicked(true);
                }}
              />
            )}
            <TextInput
              id="postPopup"
              onClick={(e) => {
                e.stopPropagation();
                handleToggleModal();
              }}
            >
              Share something...
            </TextInput>
          </AvatarUploadComponentWrapper>
          {diffFileType ? (
            <Snackbar
              id="postPopup"
              onClick={(e) => {
                e.stopPropagation();
              }}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={diffFileType}
              autoHideDuration={3000}
              onClose={() => {
                setDiffFileType(false);
              }}
              key={"top" + "right"}
            >
              <ToggleAlert
                onClose={() => {
                  setDiffFileType(false);
                }}
                severity={"error"}
                sx={{ width: "100%" }}
              >
                Unexpected file type.
              </ToggleAlert>
            </Snackbar>
          ) : fileSizeLimit ? (
            <Snackbar
              id="postPopup"
              onClick={(e) => {
                e.stopPropagation();
              }}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={fileSizeLimit}
              autoHideDuration={3000}
              onClose={() => {
                setFileSizeLimit(false);
              }}
              key={"top" + "right"}
            >
              <ToggleAlert
                onClose={() => {
                  setFileSizeLimit(false);
                }}
                severity={"error"}
                sx={{ width: "100%" }}
              >
                File size exceeded.
              </ToggleAlert>
            </Snackbar>
          ) : (
            fileNumLimit && (
              <Snackbar
                id="postPopup"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={fileNumLimit}
                autoHideDuration={3000}
                onClose={() => {
                  setFileNumLimit(false);
                }}
                key={"top" + "right"}
              >
                <ToggleAlert
                  onClose={() => {
                    setFileNumLimit(false);
                  }}
                  severity={"error"}
                  sx={{ width: "100%" }}
                >
                  Maximum file added.
                </ToggleAlert>
              </Snackbar>
            )
          )}
          <ModalWrapper
            show={showModal}
            onClick={() => {
              closeModal();
            }}
          >
            <ModalBody
              id="postPopup"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <AvatarUploadComponentWrapper>
                {props.displayPic?.split("//").length <= 2 ? (
                  <Avatar
                    alt="Profile Picture"
                    src={props.displayPic}
                    style={{
                      padding: "0rem",
                      margin: "0rem 0.5rem 0.5rem 0rem",
                      top: "0px",
                      width: "3.5rem",
                      height: "3.5rem",
                      cursor: "pointer",
                      border: "1px solid rgb(222, 222, 222)",
                    }}
                  />
                ) : (
                  <Avatar
                    {...stringAvatar(
                      String(
                        props.userFullName ? props.userFullName.trim() : currentUser
                      ).toUpperCase()
                    )}
                    style={{
                      padding: ".1rem",
                      margin: "0 1rem 0 .5rem",
                      top: "5px",
                      scale: "1.1",
                    }}
                  />
                )}
                <ModalUploadWrapper>
                  <div className="editor-block">
                    {isEditorReady && (
                      <CKEditor
                        data={text}
                        editor={DecoupledEditor}
                        config={{
                          toolbar: {
                            items: [
                              "heading",
                              "|",
                              "alignment",
                              "|",
                              "bold",
                              "italic",
                              "strikethrough",
                              "underline",
                              "|",
                              "link",
                              "|",
                              "bulletedList",
                              "numberedList",
                              "|",
                              "fontFamily",
                              "fontSize",
                              "fontColor",
                              "fontBackgroundColor",
                            ],
                          },
                          link: {
                            addTargetToExternalLinks: true,
                          },
                        }}
                        onReady={(editor) => {
                          if (editor) {
                            editor?.ui
                              ?.getEditableElement()
                              ?.parentElement.insertBefore(
                                editor.ui.view.toolbar.element,
                                editor.ui.getEditableElement()
                              );
                          }
                        }}
                        onChange={(event, editor) => {
                          const ckEditorData = editor.getData();
                          setText(ckEditorData);
                        }}
                      />
                    )}
                  </div>
                  <ModalUploadComponent id="uploadElement">
                    {showImgDnD && (
                      <ModalFileInput>
                        <p>Upload and attach Images</p>
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            handleFileUpload(
                              acceptedFiles,
                              "image",
                              acceptedImageTypes
                            );
                          }}
                          multiple={true}
                          disabled={uploadFile.length >= 10}
                        >
                          {dropDiv}
                        </Dropzone>
                        {progressComp}
                      </ModalFileInput>
                    )}
                    {showVidDnD && (
                      <ModalFileInput>
                        <p>Upload and attach videos</p>
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            handleFileUpload(
                              acceptedFiles,
                              "video",
                              acceptedVideoTypes
                            );
                          }}
                          multiple={true}
                          disabled={uploadFile.length >= 10}
                        >
                          {dropDiv}
                        </Dropzone>
                        {progressComp}
                      </ModalFileInput>
                    )}
                    {showFileDnD && (
                      <ModalFileInput>
                        <p>Upload and attach files</p>
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            handleFileUpload(
                              acceptedFiles,
                              "appli",
                              acceptedFileTypes
                            );
                          }}
                          multiple={true}
                          disabled={uploadFile.length >= 10}
                        >
                          {dropDiv}
                        </Dropzone>
                        {progressComp}
                      </ModalFileInput>
                    )}
                    {showPreview && (
                      <PreviewImgContainer>
                        {uploadFile.map((doc, i) =>
                          doc.type !== "appli" ? (
                            <PreviewImgBlock key={`${doc.file.name}-${i}`}>
                              {doc.type === "video" ? (
                                <PreviewVid controls>
                                  <source
                                    src={URL.createObjectURL(doc.file)}
                                    type="video/mp4"
                                    id={doc.file.name}
                                  />
                                </PreviewVid>
                              ) : (
                                <PreviewImg
                                  key={doc.file.name}
                                  src={URL.createObjectURL(doc.file)}
                                  alt="image"
                                />
                              )}
                              <svg
                                width="50"
                                height="15"
                                viewBox="0 0 18 18"
                                fill="none"
                                style={{
                                  color: "#ffffff",
                                  marginLeft: "-2rem",
                                  marginBottom: "9rem",
                                  marginTop: "0.5rem",
                                  zIndex: "1",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => {
                                  removeFile(doc);
                                }}
                                id={doc.file.path}
                              >
                                <circle
                                  cx="9"
                                  cy="9"
                                  r="8.75"
                                  fill="white"
                                  stroke="#D1D1D1"
                                  strokeWidth="0.5"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                                <path
                                  d="M6.68372 5L8.96279 8.12891H9.03721L11.3163 5H12.9814L10.014 9L13 13H11.3256L9.03721 9.91406H8.96279L6.67442 13H5L8.03721 9L5.0186 5H6.68372Z"
                                  fill="#FF0000"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              </svg>
                            </PreviewImgBlock>
                          ) : (
                            <PreviewFileBlock
                              key={Math.random()}
                              style={{ width: "45.5%", position: "relative" }}
                            >
                              <FileViewer
                                key={Math.random()}
                                fileType={doc.file.name.substring(
                                  doc.file.name.lastIndexOf(".") + 1,
                                  doc.file.name.length
                                )}
                                filePath={doc.fileURL}
                                onError={() => {}}
                                style={{
                                  width: "95%",
                                  border: "2px solid black",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              />
                              <svg
                                width="50"
                                height="15"
                                viewBox="0 0 18 18"
                                fill="none"
                                style={{
                                  color: "#ffffff",
                                  position: "absolute",
                                  right: "-0.5rem",
                                  top: "0.5rem",
                                  zIndex: "1",
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeFile(doc);
                                }}
                                id={doc.fileURL}
                              >
                                <circle
                                  cx="9"
                                  cy="9"
                                  r="8.75"
                                  fill="white"
                                  stroke="#D1D1D1"
                                  strokeWidth="0.5"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                                <path
                                  d="M6.68372 5L8.96279 8.12891H9.03721L11.3163 5H12.9814L10.014 9L13 13H11.3256L9.03721 9.91406H8.96279L6.67442 13H5L8.03721 9L5.0186 5H6.68372Z"
                                  fill="#FF0000"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              </svg>
                            </PreviewFileBlock>
                          )
                        )}
                      </PreviewImgContainer>
                    )}
                  </ModalUploadComponent>
                </ModalUploadWrapper>
              </AvatarUploadComponentWrapper>
              <HorizontalLine></HorizontalLine>
              <ModalButtons>
                <PostOptionContainer>{postOptions}</PostOptionContainer>
                {!(showImgDnD || showVidDnD || showFileDnD) && (
                  <ConfirmOption
                    disabled={!(text || uploadFile.length)}
                    onClick={(e) => {
                      setApiCalled(true);
                      createPostNew();
                      setText("");
                      setUploadFile([]);
                      handleToggleModal();
                      if (showImgDnD) setShowImgDnD(false);
                      if (showVidDnD) setShowVidDnD(false);
                      if (showFileDnD) setShowFileDnD(false);
                    }}
                  >
                    Post
                  </ConfirmOption>
                )}
                {(showImgDnD || showVidDnD || showFileDnD) && (
                  <ConfirmOptionContainer>
                    {uploadBtnOptions}
                  </ConfirmOptionContainer>
                )}
              </ModalButtons>
            </ModalBody>
          </ModalWrapper>
          <DialogPopup />
          <HorizontalLine></HorizontalLine>
          <PostOptionContainer>{postOptions}</PostOptionContainer>
        </Wrapper>
      )}
    </>
  );
};

export default CreatePost;
