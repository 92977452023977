import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useContext, useState, useRef } from "react";
import { UserContext } from "../../App";
import { FiDownload } from "react-icons/fi";
import { BsFileEarmark, BsFolder } from "react-icons/bs";
import { FiChevronRight } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import "../Footer/ContactUs.css";
import "./CustomTable.css";
import "../Assets/DigitalAssets.css";
import { AiOutlineDelete, AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { ImBlocked } from "react-icons/im";
import fileSizeCheck from "../../Utils/fileSizeCheck";
import { Button } from "@mui/material";
import LinearProgressBar from "../../Utils/LinearProgressBar";
import CircularRotatingLines from "../../Utils/CircularLoader";
import ResponseModal from "../../Utils/ResponseModal";
import OptionsModal from "../../Utils/OptionsModal";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import styled from "styled-components";
import FileViewer from "react-file-viewer";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import { signed_url, delete_file, add_file, delete_folder } from "../../config/environment";
import { useSelector } from "react-redux";
import { checkFeatureAccess } from "../../Utils/CommonUtils";
import { useEffect } from 'react';
import { logoDASubFolder } from "../../Constants/Constant";
import RingLoader from "../Layout/RingLoader";
var qs = require("qs");
var axios = require("axios");

const ModalWrapperPreview = styled.div`
  position: fixed;
  top: 0rem;
  left: 0rem;
  right: 0;
  bottom: 0;
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
`;
const ModalBodyPreview = styled.div`
  max-width: 90vw;
  max-height: 90vh;
  min-width: 30rem;
  overflow-y: scroll;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background-color: rgb(1, 1, 1);
  border-radius: 0px;
  padding: 0px 16px;
  padding-top: 2px;
  width: auto;
  height: auto;
`;
const PreviewContainer = styled.div`
  width: 100%;
  height: 60%;
  width: 80%;
  height: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: relative;
`;
const PreviewVid = styled.video`
  width: 100%;
  height: auto;
`;
const PreviewImg = styled.img`
  width: 100%;
  height: auto;
`;
const PostImageComponent = styled.div`
  width: 100%;
  padding-right: 0.7rem;
  margin: 0 0rem 1rem;
  display: flex;
  flex-direction: column;
`;
const PostFileComponent = styled(PostImageComponent)`
  width: 97%;
  margin-bottom: 0.2rem;

  ${FileViewer}.pg-viewer-wrapper {
    width: 98%;
    height: 95%;
    border: 1px solid rgb(241, 238, 238);
    border-radius: 0.4rem;
    padding: 0.6rem;
    display: flex;
    justify-content: center;
    background: #fff;
  }
  ${FileViewer}.pg-viewer {
    width: 100%;
  }
  ${FileViewer}.document-container {
    width: 95%;
    height: 95%;
  }
  ${FileViewer}.pdf-canvas {
    width: 95%;
    height: 95%;
    display: flex;
    justify-content: center;
  }
`;
const PreviewFile = styled(PostFileComponent)`
  margin-bottom: -0.15rem;
  max-height: 95vh;
  ${FileViewer}.pg-viewer-wrapper {
    border: none;
  }
  ${FileViewer}.pdf-viewer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  ${FileViewer}.pdf-canvas {
    width: 100%;
  }
`;

export default function BrandTable(props) {
  const { t } = useTranslation();
  const location = useLocation();

  // const signed_url = process.env.REACT_APP_SIGNED_URL;
  // const delete_file = process.env.REACT_APP_DELETE_FILE;
  // const add_file = process.env.REACT_APP_ADD_FILE;
  // const delete_folder = process.env.REACT_APP_DELETE_FOLDER;

  const { state, dispatch } = useContext(UserContext);
  const [progress, setProgress] = useState(1);
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadFolderModal, setUploadFolderModal] = useState(false);
  const [uploadChoose, setUploadChoose] = useState(false);
  const [uploadProcessing, setUploadProcessing] = useState(false);
  const [multipleProcessing, setMultipleProcessing] = useState(false);
  const [file, setFile] = useState("");
  const [folder, setFolder] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteProcessing, setDeleteProcessing] = useState(false);
  const [deleteKey, setDeleteKey] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(400);
  const [uploadFolderStatus, setUploadFolderStatus] = useState(400);
  const [isFolder, setIsFolder] = useState(false);
  const [deleteFolderKey, setDeleteFolderKey] = useState("");
  const [previewModal, setPreviewModal] = useState(false);
  const [previewFile, setPreviewFile] = useState([]);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [folderContents, setFolderContents] = useState([]);
  const [accessLoading, setAccessLoading] = useState(false);

  const {userAccess, isAdmin=false} = useSelector(state => state.userManagement);

  useEffect(() => {
    let tempFolderContentsArr = [];
    props.tableContentFolders.map(row => {
      if (checkFeatureAccess(row[0], "view") || !logoDASubFolder.includes(row[0])) {
        tempFolderContentsArr.push(row);
      }
    });
    setFolderContents(tempFolderContentsArr);
    if(!userAccess){
      setAccessLoading(true);
    }else{
      setAccessLoading(false);
    }
    return () => {
      setFolderContents([]);
    }
  }, [props.parentFolder,userAccess]);

  const chosenFiles = Array.prototype.slice.call(file);

  const closeUploadChoose = () => {
    setUploadChoose(false);
  };
  const closeUploadModal = () => {
    setUploadModal(false);
  };
  const closeUploadFolderModal = () => {
    setUploadFolderModal(false);
  };
  const closeUploadProcessing = () => {
    setUploadProcessing(false);
  };
  const closeMultipleProcessing = () => {
    setMultipleProcessing(false);
  };

  const checkAccessToken = localStorage.getItem("access_token");
  let userPermission = localStorage.getItem("userpermission");
  const fileInputRef = useRef();
  const folderInputRef = useRef();

  const trimFileName = (fileName) => {
    const trimmedName = fileName.trim();
    const index = trimmedName.lastIndexOf('.');
    const nameWithoutExtension = trimmedName.substring(0, index).replace(/\s+/g, "_").replace(/[^a-zA-Z0-9_]/g, "");
    return nameWithoutExtension + trimmedName.substring(index);
  };

  const uploadFile = async () => {
    setIsSending(true);
    closeUploadChoose();
    closeUploadProcessing();
    closeMultipleProcessing();
    // const formData = new FormData();
    // formData.append("file", file);
    //pathname to re-fetch the folder value
    let code = location.pathname;
    props.setReloadPath(code);
    let resStatus, key;
    let keyStr = props.parentFolder.trim();
    if (keyStr[0] === "/") {
      keyStr = keyStr.substr(1, keyStr.length);
    }
    //key=> subfolder address

    let partSize = 100 / file.length;
    for (let i = 0; i < file.length; i++) {
      key = keyStr + "/" + trimFileName(file[i].name); //.replace(/[^a-zA-Z0-9.]/g, "_");
      closeUploadChoose();
      try {
        const resp = await axios.request({
          method: "post",
          url: add_file,
          headers: {
            fileType: file[i].type,
            key: key,
          },
          data: qs.stringify({
            accessToken: checkAccessToken,
          }),
        });
        closeMultipleProcessing();
        closeUploadProcessing();
        setIsSending(true);
        const data = resp.data;
        if (file.length === 1) {
          closeMultipleProcessing();
          axios
            .request({
              method: "put",
              url: `${data.body.message}`,
              headers: {
                "Content-Type": file[0].type,
              },
              transformRequest: (data, headers) => {
                delete headers.common['Authorization'];
                return data;
              },
              data: file[0],
              onUploadProgress: (p) => {
                setIsSending(true);
                const curProgress = (p.loaded / p.total) * 100;
                setProgress((prevProgress) =>
                  prevProgress >= 100 ? 100 : curProgress
                );
              },
            })
            .then((data) => {
              const resStatus = data.status;
              setUploadStatus(resStatus);
              if (resStatus === 200) {
                setFile("");
                props.setCallFetchBucket();
                props.setIsLoading(true);
                setIsSending(false);
              }
            })
            .catch((err) => {
              setIsSending(false);
              setMultipleProcessing(true);
            });
        } else {
          let requestOptions = {
            method: "PUT",
            body: file[i],
          };
          const res = await fetch(`${data.body.message}`, requestOptions);
          setProgress((prevProgress) =>
            prevProgress >= 100 ? 100 : prevProgress + partSize
          );
          resStatus = await res.status;
        }
      } catch (err) {
        resStatus = 500;
      }
    }
    setProgress(1);
    setUploadStatus(resStatus);
    if (resStatus && resStatus !== 200) {
      setIsSending(false);
      setMultipleProcessing(true);
    } else if (file.length > 1) {
      props.setCallFetchBucket();
      props.setIsLoading(true);
    }
    closeUploadProcessing();
    closeUploadChoose();
  };

  const uploadFolder = async () => {
    setIsSending(true);
    closeUploadChoose();
    closeUploadProcessing();
    //pathname to re-fetch the folder value
    let code = location.pathname;
    props.setReloadPath(code);
    let keyStr = props.parentFolder.trim();
    if (keyStr[0] === "/") {
      keyStr = keyStr.substr(1, keyStr.length);
    }
    let resStatus;
    let partSize = 100 / folder.length;
    const removeSpecialChar = (str) => str.replace(/[^a-zA-Z0-9. ]/g, "_")
    for (let i = 0; i < folder.length; i++) {
      let directories = folder[i].webkitRelativePath.split('/').map(dir => removeSpecialChar(dir.trim()));
      let filename = trimFileName(directories[directories.length - 1]); //.replace(/[^a-zA-Z0-9.]/g, "_");
      let encoded_folder_path = directories.slice(0, -1).concat(filename).join('/');
      let key = keyStr + "/" + encoded_folder_path;
      try {
        const resp = await axios.request({
          method: "post",
          url: add_file,
          headers: {
            fileType: folder[i].type,
            key: key,
          },
          data: qs.stringify({
            accessToken: checkAccessToken,
          }),
        });
        const data = resp.data;
        let requestOptions = {
          method: "PUT",
          body: folder[i],
        };
        const res = await fetch(`${data.body.message}`, requestOptions);
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 100 : prevProgress + partSize
        );
        resStatus = await res.status;
      } catch (err) {
        resStatus = 500;
      }
    }
    setProgress(1);
    setUploadFolderStatus(resStatus);
    if (resStatus && resStatus !== 200) {
      setIsSending(false);
      setMultipleProcessing(true);
    } else if (folder.length >= 1) {
      props.setCallFetchBucket();
      props.setIsLoading(true);
    }
    setIsSending(false);
    closeUploadProcessing();
    closeUploadChoose();
  };

  let statusMessageFile, statusMessageFolder;

  statusMessageFile = (
    <div className="contactFormInput-Res">
      {file.length === 1 ? (
        <label className="formInputLabel">{t("file_uploading")}</label>
      ) : (
        <></>
      )}
      <h3
        className={
          isSending
            ? `contact-response-black processing-modal-black`
            : uploadStatus === 200
            ? `contact-response-green processing-modal`
            : `contact-response-red`
        }
      >
        {isSending ? (
          <>
            <CircularRotatingLines />
          </>
        ) : uploadStatus === 401 ? (
          <>
            <ImBlocked
              style={{
                textAlign: "center",
                scale: "1",
                paddingRight: "10px",
                paddingTop: "4px",
              }}
            />
            {t("file_upload_access")}
          </>
        ) : uploadStatus === 200 ? (
          <>
            <MdOutlineMarkEmailRead
              style={{
                textAlign: "center",
                scale: "1.5",
                paddingRight: "10px",
                paddingTop: "4px",
              }}
            />
            {t("file_upload_success")}
          </>
        ) : uploadStatus === 413 ? (
          <>
            <ImBlocked
              style={{
                textAlign: "center",
                scale: "1",
                paddingRight: "10px",
                paddingTop: "4px",
              }}
            />
            {t("file_upload_limit")}
          </>
        ) : (
          <>
            <ImBlocked
              style={{
                textAlign: "center",
                scale: "1.5",
                paddingRight: "10px",
                paddingTop: "4px",
              }}
            />
            {t("file_upload_failed")}
          </>
        )}
      </h3>
    </div>
  );

  statusMessageFolder = (
    <div className="contactFormInput-Res">
      <label className="formInputLabel">{t("folder_uploading")}</label>
      <h1
        className={
          isSending
            ? `contact-response-black processing-modal-black`
            : uploadFolderStatus === 200
            ? `contact-response-green processing-modal`
            : `contact-response-red`
        }
      >
        {isSending ? (
          <>
            <LinearProgressBar value={progress} />
          </>
        ) : uploadFolderStatus === 401 ? (
          <>
            <ImBlocked
              style={{
                textAlign: "center",
                scale: "1",
                paddingRight: "10px",
                paddingTop: "4px",
              }}
            />
            {t("file_upload_access")}
          </>
        ) : uploadFolderStatus === 200 ? (
          <>
            <MdOutlineMarkEmailRead
              style={{
                textAlign: "center",
                scale: "1.5",
                paddingRight: "10px",
                paddingTop: "4px",
              }}
            />
            {t("file_upload_success")}
          </>
        ) : uploadFolderStatus === 413 ? (
          <>
            <ImBlocked
              style={{
                textAlign: "center",
                scale: "1",
                paddingRight: "10px",
                paddingTop: "4px",
              }}
            />
            {t("file_upload_limit")}
          </>
        ) : (
          <>
            <ImBlocked
              style={{
                textAlign: "center",
                scale: "1.5",
                paddingRight: "10px",
                paddingTop: "4px",
              }}
            />
            {t("file_upload_failed")}
          </>
        )}
      </h1>
    </div>
  );

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };
  const closeProcessingModal = () => {
    setDeleteProcessing(false);
  };

  let statusMessage2;

  statusMessage2 = (
    <div className="contactFormInput-Res">
      <label className="formInputLabel delete-processing">
        {t("file_deleting")}
      </label>
      <h1
        className={
          isSending
            ? `contact-response-black processing-modal-black`
            : uploadStatus === 200
            ? `contact-response-green processing-modal`
            : `contact-response-red`
        }
      >
        {isSending ? (
          <>
            <CircularRotatingLines />
          </>
        ) : uploadStatus === 401 ? (
          <>
            <ImBlocked
              style={{
                textAlign: "center",
                scale: "1",
                paddingRight: "10px",
                paddingTop: "4px",
              }}
            />
            {t("file_upload_access")}
          </>
        ) : uploadStatus === 500 ? (
          <>
            <ImBlocked
              style={{
                textAlign: "center",
                scale: "1",
                paddingRight: "10px",
                paddingTop: "4px",
              }}
            />
            {t("file_upload_failed")}
          </>
        ) : uploadStatus === 200 ? (
          <>
            <MdOutlineMarkEmailRead
              style={{
                textAlign: "center",
                scale: "1.5",
                paddingRight: "10px",
                paddingTop: "4px",
              }}
            />
            {t("file_delete_success")}
          </>
        ) : (
          <>
            <ImBlocked
              style={{
                textAlign: "center",
                scale: "1.5",
                paddingRight: "10px",
                paddingTop: "4px",
              }}
            />
            {t("file_upload_failed")}
          </>
        )}
      </h1>
    </div>
  );

  const deleteFile = async (key) => {
    setIsSending(true);
    let code = location.pathname;
    props.setReloadPath(code);
    try {
      const res = await fetch(delete_file, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify({
          key: key,
          accessToken: checkAccessToken,
        }),
      });
      const data = await res.json();
      const resStatus = await res.status;
      setUploadStatus(resStatus);
      setIsSending(false);
      if (resStatus === 200) {
        props.setCallFetchBucket();
        props.setIsLoading(true);
      }
      //window.alert(data.msg);
    } catch (err) {}
    setIsSending(false);
  };

  const deleteFolder = async (key) => {
    setIsSending(true);
    let code = location.pathname;
    props.setReloadPath(code);
    try {
      const res = await fetch(delete_folder, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify({
          key: key,
          accessToken: checkAccessToken,
        }),
      });
      const data = await res.json();
      const resStatus = await res.status;
      setUploadStatus(resStatus);
      setIsSending(false);
      if (resStatus === 200) {
        props.setCallFetchBucket();
        props.setIsLoading(true);
      }
    } catch (err) {}
    setIsFolder(false);
    setIsSending(false);
  };

  const signedUrl = async (key) => {
    try {
      const res = await fetch(signed_url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify({
          key: key,
          accessToken: checkAccessToken,
        }),
      });
      const data = await res.json();
      window.open(data.body.message.url, "_blank");
    } catch (err) {}
  };

  // const noPreviewFiles = ["ai", "eps", "ppt", "pptx"]; //file types for which we dont support preview
  const previewFileTypes = [
    "jpeg", "jpg", "png", "gif", "svg", "webp", "bmp", "ico", "tiff", "mp4", "webm", "ogg", "mov", "avi", "mpeg", "wmv", "flv", "pdf", "xlsx", "docx" 
  ];
  const filePreview = async (fileName, key) => {
    console.log("DA files",file)
    setPreviewLoading(true);
    setPreviewModal(true);
    try {
      const res = await fetch(signed_url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify({
          key: key,
          accessToken: checkAccessToken,
        }),
      });

      const data = await res.json();
      const signedUrl = data.body.message.url;

      const response = await fetch(signedUrl);
      const contentType = response.headers.get("content-type");
      const fileType = fileName.substring(
        fileName.lastIndexOf(".") + 1,
        fileName.length
      );
      setPreviewFile([signedUrl, contentType, fileType]);
    } catch (err) {
      console.error("Error:", err);
      setPreviewModal(false);
    } finally {
      setPreviewLoading(false);
    }
  };

  let content;

  let conditionalUpload;
  if (isAdmin) {
    conditionalUpload = (
      <>
        <div className="uploadContainer">
          <button
            className="uploadFileButton"
            onClick={() => setUploadChoose(true)}
          >
            {t("upload_button")}
          </button>
          <Modal
            className="contactModal"
            isOpen={uploadChoose}
            onRequestClose={closeUploadChoose}
            appElement={document.getElementById("root")}
          >
            <div className="contact-block">
              <div className="contact-container">
                <div className="btnContainer">
                  <div className="contactModalCloseBtn">
                    <button onClick={closeUploadChoose}>x</button>
                  </div>
                </div>
                <div className="contactFormInput">
                  <div className="confirmationBtnContainer-upload">
                    <div className="uploadButtonContainer">
                      <Button
                        className="uploadFileButton"
                        onClick={() => fileInputRef.current.click()}
                        style={{ color: "white", backgroundColor: "#147350" }}
                        startIcon={<UploadFileIcon />}
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                      >
                        {t("file_upload")}
                      </Button>
                      <input
                        onChange={(e) => {
                          if (e.target.files) setUploadModal(true);
                          setFile(e.target.files);
                        }}
                        ref={fileInputRef}
                        type="file"
                        name="file"
                        multiple
                        hidden
                      />
                      <Modal
                        className="contactModal"
                        isOpen={uploadModal}
                        onRequestClose={closeUploadModal}
                        appElement={document.getElementById("root")}
                      >
                        <div className="contact-block">
                          <div className="contact-container">
                            <div className="btnContainer">
                              <div className="contactModalCloseBtn">
                                <button onClick={closeUploadModal}>x</button>
                              </div>
                            </div>
                            <div className="contactFormInput">
                              <div className="formInputLabel">
                                <h3 style={{ color: "black" }}>
                                  {t("are_u_sure")}
                                </h3>
                                <p
                                  style={{
                                    color: "#959292",
                                    fontSize: "0.83em",
                                    lineHeight: "1.3rem",
                                  }}
                                >
                                  {t("file_are_u_sure_upload")}
                                </p>
                              </div>
                              <div className="confirmationBtnContainer">
                                <button
                                  className="submitBtn confirmBtn"
                                  style={{ backgroundColor: "#b7b4b4" }}
                                  onClick={() => closeUploadModal()}
                                >
                                  {t("file_cancel")}
                                </button>

                                <button
                                  className="submitBtn confirmBtn"
                                  onClick={() => {
                                    closeUploadModal();
                                    setIsSending(true);
                                    uploadFile();
                                    setUploadProcessing(true);
                                  }}
                                >
                                  {t("file_upload")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                      <Modal
                        className="contactModal"
                        isOpen={uploadProcessing}
                        onRequestClose={closeUploadProcessing}
                        appElement={document.getElementById("root")}
                      >
                        <div className="contact-block">
                          <div className="contact-container">
                            <div className="btnContainer">
                              <div className="contactModalCloseBtn">
                                <button onClick={closeUploadProcessing}>
                                  X
                                </button>
                              </div>
                            </div>
                            <div className="contact-modal-body">
                              {statusMessageFile}
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>

                    <div className="uploadButtonContainer">
                      <Button
                        className="uploadFileButton"
                        onClick={() => folderInputRef.current.click()}
                        style={{ color: "white", backgroundColor: "#147350" }}
                        startIcon={<DriveFolderUploadIcon />}
                        aria-controls="customized-menu"
                        aria-haspopup="true"
                        variant="contained"
                      >
                        {t("folder_upload")}
                      </Button>
                      <input
                        onChange={(e) => {
                          if (e.target.files) setUploadFolderModal(true);
                          setFolder(e.target.files);
                        }}
                        ref={folderInputRef}
                        directory=""
                        webkitdirectory=""
                        type="file"
                        name="file"
                        hidden
                      />
                      <Modal
                        className="contactModal"
                        isOpen={uploadFolderModal}
                        onRequestClose={closeUploadFolderModal}
                        appElement={document.getElementById("root")}
                      >
                        <div className="contact-block">
                          <div className="contact-container">
                            <div className="btnContainer">
                              <div className="contactModalCloseBtn">
                                <button onClick={closeUploadFolderModal}>
                                  x
                                </button>
                              </div>
                            </div>
                            <div className="contactFormInput">
                              <div className="formInputLabel">
                                <h3 style={{ color: "black" }}>
                                  {t("are_u_sure")}
                                </h3>
                                <p
                                  style={{
                                    color: "#959292",
                                    fontSize: "0.83em",
                                    lineHeight: "1.3rem",
                                  }}
                                >
                                  {t("folder_are_u_sure_upload")}
                                </p>
                              </div>
                              <div className="confirmationBtnContainer">
                                <button
                                  className="submitBtn confirmBtn"
                                  style={{ backgroundColor: "#b7b4b4" }}
                                  onClick={() => closeUploadFolderModal()}
                                >
                                  {t("folder_cancel")}
                                </button>

                                <button
                                  className="submitBtn confirmBtn"
                                  onClick={() => {
                                    closeUploadFolderModal();
                                    uploadFolder();
                                    setUploadProcessing(true);
                                  }}
                                >
                                  {t("folder_upload")}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Modal>
                      <Modal
                        className="contactModal"
                        isOpen={uploadProcessing}
                        onRequestClose={closeUploadProcessing}
                        appElement={document.getElementById("root")}
                      >
                        <div className="contact-block">
                          <div className="contact-container">
                            <div className="btnContainer">
                              <div className="contactModalCloseBtn">
                                <button onClick={closeUploadProcessing}>
                                  X
                                </button>
                              </div>
                            </div>
                            <div className="contact-modal-body">
                              {statusMessageFolder}
                            </div>
                          </div>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </>
    );
  } else {
    conditionalUpload = <></>;
  }
  const conditionalDeleteHead =
  isAdmin ? (
      <TableCell
        style={{
          color: "white",
          fontWeight: "bold",
          fontSize: "1rem",
          fontFamily: '"Roboto Condensed", sans-serif',
        }}
      >
        {t("table_head_delete")}
      </TableCell>
    ) : (
      <></>
    );
  const conditionalDeleteFolder = (key) =>
  isAdmin ? (
      <TableCell style={{ backgroundColor: "#F6F6F6" }} align="center">
        <button
          value="delete"
          onClick={(e) => {
            setDeleteFolderKey(key);
            setIsFolder(true);
            setDeleteModal(true);
          }}
          style={{ cursor: "pointer" }}
        >
          <AiOutlineDelete />
        </button>
        <OptionsModal
          modalIsOpen={deleteModal}
          closeModal={closeDeleteModal}
          headerText={t("are_u_sure")}
          headerQuestion={t("file_are_u_sure_delete")}
          leftButtonText={t("file_cancel")}
          rightButtonText={t("file_delete")}
          isFolder={isFolder}
          deleteFolder={deleteFolder}
          deleteFile={deleteFile}
          deleteFolderKey={deleteFolderKey}
          deleteKey={deleteKey}
          setDeleteProcessing={setDeleteProcessing}
        />
        <ResponseModal
          setCallFetchBucket={props.setCallFetchBucket}
          processing={deleteProcessing}
          closeProcessing={closeProcessingModal}
          statusMessage={statusMessage2}
        />
      </TableCell>
    ) : (
      <></>
    );

  const conditionalDelete = (key) => {
    if (isAdmin) {
      return (
        <TableCell style={{ backgroundColor: "#F6F6F6" }} align="center">
          <button
            value="delete"
            onClick={(e) => {
              setDeleteKey(key);
              setDeleteModal(true);
            }}
            style={{ cursor: "pointer" }}
          >
            <AiOutlineDelete />
          </button>
          <Modal
            className="contactModal"
            isOpen={deleteModal}
            onRequestClose={closeDeleteModal}
            appElement={document.getElementById("root")}
          >
            <div className="contact-block">
              <div className="contact-container">
                <div className="btnContainer">
                  <div className="contactModalCloseBtn">
                    <button onClick={closeDeleteModal}>
                      <AiOutlineClose className="modal-close-btn" />
                    </button>
                  </div>
                </div>
                <div className="contactFormInput">
                  <div className="formInputLabel">
                    <h3 className="confirm-dialog-header">{t("are_u_sure")}</h3>
                    <p className="confirm-dialog-message">
                      {t("file_are_u_sure_delete")}
                    </p>
                  </div>
                  <div className="confirmationBtnContainer">
                    <button
                      className="submitBtn confirmBtn"
                      style={{ backgroundColor: "#b7b4b4" }}
                      onClick={() => closeDeleteModal()}
                    >
                      {t("file_cancel")}
                    </button>

                    <button
                      className="submitBtn confirmBtn"
                      onClick={() => {
                        closeDeleteModal();
                        if (isFolder) deleteFolder(deleteFolderKey);
                        else deleteFile(deleteKey);
                        setDeleteProcessing(true);
                      }}
                    >
                      {t("file_delete")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            className="contactModal"
            isOpen={deleteProcessing}
            onRequestClose={closeProcessingModal}
            appElement={document.getElementById("root")}
          >
            <div className="contact-block">
              <div className="contact-container">
                <div className="btnContainer">
                  <div className="contactModalCloseBtn">
                    <button onClick={closeProcessingModal}>
                      <AiOutlineClose className="modal-close-btn" />
                    </button>
                  </div>
                </div>
                <div className="contact-modal-body processing-modal">
                  {statusMessage2}
                </div>
              </div>
            </div>
          </Modal>
        </TableCell>
      );
    } else {
      return <></>;
    }
  };

  const folderRow = (row) => {
    return <TableRow
    key={row[0]}
    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
  >
    <TableCell
      style={{ backgroundColor: "#F6F6F6" }}
      component="th"
      scope="row"
    >
      <BsFolder style={{ paddingRight: "10px" }} />
      <NavLink to={row[3]}>{row[0]}</NavLink>
    </TableCell>
    {isSending && (file || folder) ? (
      <TableCell
        style={{
          backgroundColor: "#F6F6F6",
          width: "7rem",
        }}
      >
        {"-"}
      </TableCell>
    ) : (
      <></>
    )}
    <TableCell style={{ backgroundColor: "#F6F6F6" }} align="left">
      {"-"}
    </TableCell>
    <TableCell style={{ backgroundColor: "#F6F6F6" }} align="left">
      {row[1]}
    </TableCell>
    <TableCell style={{ backgroundColor: "#F6F6F6" }} align="left">
      {row[2]}
    </TableCell>
    <TableCell style={{ backgroundColor: "#F6F6F6" }} align="center">
      {"-"}
    </TableCell>
    {conditionalDeleteFolder(row[3])}
  </TableRow>
  }


  if (
    props.tableContentFiles.length === 0 &&
    folderContents.length === 0
  ) {
    content = <h3 className="container1-h1-center">{t("table_no_records")}</h3>;
  } else {
    content = (
      <>
        {props.tableContentFiles.map((row) => (
          <TableRow
            key={row[0]}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              style={{ backgroundColor: "#F6F6F6" }}
              component="th"
              scope="row"
            >
              <BsFileEarmark style={{ paddingRight: "10px" }} />
              {row[0].indexOf("=_") !== -1
                ? row[0].split("=_")[1]
                : row[0].substring(row[0].indexOf("_") + 1)}
            </TableCell>
            {isSending && (file || folder) ? (
              <TableCell
                style={{
                  backgroundColor: "#F6F6F6",
                  width: "7rem",
                }}
              >
                {"-"}
              </TableCell>
            ) : (
              <></>
            )}
            <TableCell style={{ backgroundColor: "#F6F6F6" }} align="center">
              {previewFileTypes.includes(
                row[0].substr(row[0].lastIndexOf(".") + 1, row[0].length)
              ) ? (
                <button
                  value="preview"
                  onClick={(e) => {
                    e.preventDefault();
                    filePreview(row[0], row[3]);
                  }}
                  style={{
                    cursor: "pointer",
                    height: "auto",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    viewBox="0 0 20 16"
                    fill="none"
                    style={{ width: "1rem", height: "1.1rem" }}
                  >
                    <path
                      d="M19.6038 7.4241C17.4877 2.96651 14.2891 0.723206 10.0011 0.723206C5.71093 0.723206 2.5145 2.96651 0.398427 7.42633C0.313551 7.60606 0.269531 7.80235 0.269531 8.00111C0.269531 8.19987 0.313551 8.39616 0.398427 8.57588C2.5145 13.0335 5.71316 15.2768 10.0011 15.2768C14.2913 15.2768 17.4877 13.0335 19.6038 8.57365C19.7757 8.21205 19.7757 7.7924 19.6038 7.4241ZM10.0011 13.6696C6.40066 13.6696 3.7645 11.8437 1.90512 7.99999C3.7645 4.15624 6.40066 2.33035 10.0011 2.33035C13.6016 2.33035 16.2377 4.15624 18.0971 7.99999C16.2399 11.8437 13.6038 13.6696 10.0011 13.6696ZM9.91182 4.07142C7.74218 4.07142 5.98325 5.83035 5.98325 7.99999C5.98325 10.1696 7.74218 11.9286 9.91182 11.9286C12.0815 11.9286 13.8404 10.1696 13.8404 7.99999C13.8404 5.83035 12.0815 4.07142 9.91182 4.07142ZM9.91182 10.5C8.53012 10.5 7.41182 9.38169 7.41182 7.99999C7.41182 6.6183 8.53012 5.49999 9.91182 5.49999C11.2935 5.49999 12.4118 6.6183 12.4118 7.99999C12.4118 9.38169 11.2935 10.5 9.91182 10.5Z"
                      fill="black"
                    />
                  </svg>
                </button>
              ) : (                
                <Tooltip title="No preview available. Please download." arrow>
                  <button
                    value="preview"
                    disabled
                    style={{
                      cursor: "default",
                      height: "auto",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <VisibilityOffOutlinedIcon
                      style={{
                        width: "1rem",
                        height: "1.1rem",
                      }}
                    />
                  </button>
                </Tooltip>
              )}
            </TableCell>
            <TableCell style={{ backgroundColor: "#F6F6F6" }} align="left">
              {row[1]}
            </TableCell>
            <TableCell style={{ backgroundColor: "#F6F6F6" }} align="left">
              {fileSizeCheck(row[2])}
            </TableCell>

            <TableCell style={{ backgroundColor: "#F6F6F6" }} align="center">
              <button
                value="download"
                onClick={(e) => {
                  e.preventDefault();
                  signedUrl(row[3]);
                }}
                style={{ cursor: "pointer" }}
              >
                <FiDownload />
              </button>
            </TableCell>
            {conditionalDelete(row[3])}
          </TableRow>
        ))}
        {isSending && file && !folder ? (
          chosenFiles.map((curFile) => (
            <TableRow
              key={curFile.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                style={{ backgroundColor: "#F6F6F6" }}
                component="th"
                scope="row"
              >
                <BsFileEarmark style={{ paddingRight: "10px" }} />
                {curFile.name.indexOf("=_") !== -1
                  ? curFile.name.split("=_")[1]
                  : curFile.name.substring(curFile.name.indexOf("_") + 1)}
              </TableCell>
              <TableCell style={{ backgroundColor: "#F6F6F6" }} align="left">
                {<LinearProgressBar value={progress} />}
              </TableCell>
              {isSending && (file || folder) ? (
                <TableCell
                  style={{
                    backgroundColor: "#F6F6F6",
                    width: "7rem",
                  }}
                >
                  {"-"}
                </TableCell>
              ) : (
                <></>
              )}
              <TableCell style={{ backgroundColor: "#F6F6F6" }} align="center">
                {"-"}
              </TableCell>
              <TableCell style={{ backgroundColor: "#F6F6F6" }} align="left">
                {"-"}
              </TableCell>
              <TableCell style={{ backgroundColor: "#F6F6F6" }} align="center">
                {"-"}
              </TableCell>
              <TableCell style={{ backgroundColor: "#F6F6F6" }} align="center">
                {"-"}
              </TableCell>
            </TableRow>
          ))
        ) : (
          <></>
        )}
        {folderContents.map((row) => (          
          folderRow(row)       
          ))
        }
        {isSending && !file && folder ? (
          <TableRow
            key={folder[0].webkitRelativePath.substring(
              0,
              folder[0].webkitRelativePath.indexOf("/")
            )}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              style={{ backgroundColor: "#F6F6F6" }}
              component="th"
              scope="row"
            >
              <BsFileEarmark style={{ paddingRight: "10px" }} />
              {folder[0].webkitRelativePath.substring(
                0,
                folder[0].webkitRelativePath.indexOf("/")
              )}
            </TableCell>
            <TableCell style={{ backgroundColor: "#F6F6F6" }} align="left">
              {<LinearProgressBar value={progress} />}
            </TableCell>
            <TableCell style={{ backgroundColor: "#F6F6F6" }} align="center">
              {"-"}
            </TableCell>
            <TableCell style={{ backgroundColor: "#F6F6F6" }} align="left">
              {"-"}
            </TableCell>
            <TableCell style={{ backgroundColor: "#F6F6F6" }} align="left">
              {"-"}
            </TableCell>
            <TableCell style={{ backgroundColor: "#F6F6F6" }} align="center">
              {"-"}
            </TableCell>
            <TableCell style={{ backgroundColor: "#F6F6F6" }} align="center">
              {"-"}
            </TableCell>
          </TableRow>
        ) : (
          <></>
        )}
      </>
    );
  }

  let renderTable;
  if (state) {
    renderTable = (
      <div className="folder-container">
        <h1>{props.heading}</h1>
        <div className="locationContainer">
          <div className="location">
            <h4>
              {props.directoryNames.map((row) => (
                <>
                  <NavLink to={row[1]}>{row[0]}</NavLink>
                  <FiChevronRight />
                </>
              ))}
            </h4>
          </div>
          {conditionalUpload}
        </div>
        <div className="table-container">
          <TableContainer
            component={Paper}
            sx={{
              "&::-webkit-scrollbar": {
                width: 10,
                height: 7,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "rgb(246, 246, 246)",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#dcdbdb",
                borderRadius: 1,
              },
            }}
          >
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead sx={{ background: "#147350" }}>
                <TableRow>
                  <TableCell
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      fontFamily: '"Roboto Condensed", sans-serif',
                    }}
                  >
                    {t("table_head_file")}
                  </TableCell>
                  {isSending && (file || folder) ? (
                    <TableCell
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "1rem",
                        fontFamily: '"Roboto Condensed", sans-serif',
                      }}
                    >
                      {t("table_head_progress")}
                    </TableCell>
                  ) : (
                    <></>
                  )}
                  <TableCell
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      fontFamily: '"Roboto Condensed", sans-serif',
                    }}
                  >
                    Preview
                  </TableCell>
                  <TableCell
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      fontFamily: '"Roboto Condensed", sans-serif',
                    }}
                  >
                    {t("table_head_lastmdf")}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      fontFamily: '"Roboto Condensed", sans-serif',
                    }}
                  >
                    {t("table_head_size")}
                  </TableCell>
                  <TableCell
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "1rem",
                      fontFamily: '"Roboto Condensed", sans-serif',
                    }}
                  >
                    {t("table_head_download")}
                  </TableCell>
                  {conditionalDeleteHead}
                </TableRow>
              </TableHead>
              <TableBody>{content}</TableBody>
            </Table>
          </TableContainer>
        </div>
        <ResponseModal
          setCallFetchBucket={props.setCallFetchBucket}
          processing={multipleProcessing}
          closeProcessing={closeMultipleProcessing}
          statusMessage={statusMessageFile}
        />
        <ModalWrapperPreview
          show={previewModal}
          onClick={(e) => {
            e.stopPropagation();
            setPreviewModal(false);
          }}
        >
          <ModalBodyPreview
            id="extraPreviewModal"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div style={{ position: "relative" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
              >
                {previewLoading && (
                  <CircularProgress
                    color="success"
                    style={{ color: "#147350" }}
                  />
                )}
                {!previewLoading && (
                  <PreviewContainer>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {previewFile[1]?.substr(0, 5) === "video" ? (
                        <PreviewVid controls>
                          <source
                            id="source_video"
                            src={previewFile[0]}
                            type="video/mp4"
                          />
                        </PreviewVid>
                      ) : previewFile[1]?.substr(0, 5) === "image" ? (
                        <PreviewImg src={previewFile[0]} alt="image" />
                      ) : (
                        previewFile[1]?.substr(0, 5) === "appli" && (
                          <PreviewFile>
                            <FileViewer
                              key={Math.random()}
                              fileType={previewFile[2]}
                              filePath={previewFile[0]}
                              style={{
                                width: "95%",
                                border: "2px solid black",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                          </PreviewFile>
                        )
                      )}
                    </div>
                  </PreviewContainer>
                )}
              </div>
              <svg
                width="50"
                height="15"
                viewBox="0 0 18 18"
                fill="none"
                style={{
                  color: "#ffffff",
                  marginLeft: "-2rem",
                  position: "absolute",
                  zIndex: "1",
                  top: "0",
                  right: "0",
                }}
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                  setPreviewModal(false);
                }}
              >
                <circle
                  cx="9"
                  cy="9"
                  r="8.75"
                  fill="white"
                  stroke="#D1D1D1"
                  strokeWidth="0.5"
                  style={{
                    cursor: "pointer",
                  }}
                />
                <path
                  d="M6.68372 5L8.96279 8.12891H9.03721L11.3163 5H12.9814L10.014 9L13 13H11.3256L9.03721 9.91406H8.96279L6.67442 13H5L8.03721 9L5.0186 5H6.68372Z"
                  fill="#FF0000"
                  style={{
                    cursor: "pointer",
                  }}
                />
              </svg>
            </div>
          </ModalBodyPreview>
        </ModalWrapperPreview>
      </div>
    );
  } else {
    renderTable = <h1 className="container1-h1">Access Denied</h1>;
  }

  return <>{accessLoading? <RingLoader/> : renderTable}</>;
}
