import React from 'react';
import { useSelector } from 'react-redux';
import './CategoryCard.scss'; 
import NoImage from '../PrivateBrandCatalog/assets/NoImage.png';
import AddToListButton from '../BrandedMerchandise/AddToListButton';

const CategoryCard = (props) => {
  const {
    itemId,
    cardName,
    imgSrc,
    source,
    handleEditClick,
    price,
    srp
  } = props;
  const { isAdmin = false } = useSelector((state) => state.userManagement);

  return (
    <div
      className="card-wrapper"
      onClick={() => {
        props.handleCardClick(cardName, itemId);
      }}
    >
      <div className="category-card-img">
        {imgSrc?.length ? (
          <img className="card-img" alt="card image" src={imgSrc} style={{objectFit:`${source==='CountryList'? 'cover': 'content'}`}}/>
        ) : (
          <img className="card-img" alt="fallback image" src={NoImage} />
        )}
      </div>
      <div className="category-card-name-container">
        <div className="category-card-name">{cardName}</div>

        {source === 'Collection Product List' &&(
          <div className="category-card-price-container">
            <div className="category-card-price">Price USD: ${`${price ? price.toFixed(2) :'-'}`}</div>
            <div className="category-card-srp">SRP USD: ${`${srp ? srp.toFixed(2) :'-'}`}</div>
              <AddToListButton itemId={itemId} />
            </div>
        )}
        
        {isAdmin && source === 'Branded Merchandise' && (
          <button
            className="edit-button"
            onClick={(e) => {
              e.stopPropagation();
              handleEditClick(itemId);
            }}
          >
            Edit
          </button>
        )}
      </div>
    </div>
  );
};

export default CategoryCard;
