import React from "react";
import Modal from "react-modal";
import { AiFillCloseCircle } from "react-icons/ai";
import { BsLinkedin } from "react-icons/bs";

const LeaderModal = ({ isOpen, onClose, image, name, title, quotes, linkedinUrl }) => {
  return (
    <Modal className="modal" isOpen={isOpen} onRequestClose={onClose}>
      <div className="modal-content" style={{display: 'flex', flexWrap: 'wrap', overflowX: 'scroll'}}>
        <div className="titleBtnContainer">
          <AiFillCloseCircle className="btn-modal-close" onClick={onClose} />
        </div>
          <div className="leadership-container">
            <div className="container">
              <div className="leadership-detail-image-model">
                <img src={image} height="250px" width="200px" alt={name} />
              </div>
              <div className="leadership-detail-bio">
                <h2 className="leader_name">{name}</h2>
                <p className="leader_title">{title}</p>
                <div className="leadership-detail-resources">
                  {linkedinUrl && (
                    <a href={linkedinUrl} target="_blank" className="social-link" rel="noreferrer">
                      <BsLinkedin />
                    </a>
                  )}
                </div>
                <div className="descriptionContainer">
                  {quotes.map((quote, index) => (
                    <p key={index} className="leadersDescription">
                      {quote}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
      </div>
    </Modal>
  );
};

export default LeaderModal;
