import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingIndicator from '../Common/LoadingIndicator';
import BMHeader from './BMHeader';
import { getCollectionsData, getProductTableData } from './BMService';
import CategoryCard from '../Common/CategoryCard';
import CategoryCollectionSection from './CategoryCollectionSection'; 
import './BrandedMerchandise.scss';
import { Typography } from '@mui/material';

const CollectionProductList = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const bmList = useSelector((state) => state.bmCollection.bmList);
  const [collections, setCollections] = useState([]);
  const [isCollectionsLoading, setIsCollectionsLoading] = useState(true);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [selectedCollectionId, setSelectedCollectionId] = useState(state?.collectionId || null);
  const [selectedCollectionName, setSelectedCollectionName] = useState(state?.collectionName || '');
  const [products, setProducts] = useState([]);
  const [quantities, setQuantities] = useState({});

  useEffect(() => {
    fetchCollections();
  }, []);

  useEffect(() => {
    if (selectedCollectionId !== null) {
      fetchProducts(selectedCollectionId);
    }
  }, [selectedCollectionId]);

  useEffect(() => {
    const initialQuantities = products.reduce((acc, product) => {
      acc[product.itemMasterID] = bmList.find(item => item.id === product.itemMasterID)?.count || 0;
      return acc;
    }, {});
    setQuantities(initialQuantities);
  }, [bmList, products]);

  useEffect(() => {
    if (state?.collectionId) {
      setSelectedCollectionId(state.collectionId);
      setSelectedCollectionName(state.collectionName || '');
    } else {
      setSelectedCollectionId(null);
      setSelectedCollectionName('');
    }
  }, [state]);
  

  const fetchCollections = async () => {
    try {
      setIsCollectionsLoading(true);
      const response = await getCollectionsData({ rowsPerPage: 50, page: 1 });
      setCollections(response.data.rows);
    } catch (error) {
      console.error('Error fetching collections data:', error);
    } finally {
      setIsCollectionsLoading(false);
    }
  };

  const fetchProducts = async (collectionId) => {
    try {
      setIsProductsLoading(true);
      const response = await getProductTableData({ rowsPerPage: 15, page: 1, collectionId });
      setProducts(response?.data?.data?.rows || []);
    } catch (error) {
      console.error('Error fetching products data:', error);
    } finally {
      setIsProductsLoading(false);
    }
  };

  const handleCollectionChange = (event) => {
    const newCollectionId = parseInt(event.target.value, 10);
    const newCollection = collections.find(collection => collection.id === newCollectionId);

    if (newCollectionId !== selectedCollectionId) {
      setSelectedCollectionId(newCollectionId);
      setSelectedCollectionName(newCollection?.description || '');

      navigate(`/brandedMerchandise/collections/${newCollectionId}`, {
        state: { collectionId: newCollectionId, collectionName: newCollection?.description || '' }
      });
    }
  };

  const handleCardClick = (cardName, itemId) => {
    navigate(`/brandedMerchandise/collections/${selectedCollectionId}/${itemId}`, {
      state: { collectionName: selectedCollectionName, collectionId: selectedCollectionId }
    });
  };

  return (
    <div className='collection-product-list-wrapper'>
      {isCollectionsLoading && <LoadingIndicator />}
      <BMHeader collectionName={selectedCollectionName} collectionId={selectedCollectionId} />
      <div className="content-wrapper">
        <CategoryCollectionSection
          collections={collections}
          selectedCollectionId={selectedCollectionId}
          handleCollectionChange={handleCollectionChange}
        />
        <div className='products-section'>
          {isProductsLoading ? (
            <LoadingIndicator />
          ) : products.length > 0 ? (
            <div className='product-cards'>
              {products.map((product) => (
                <CategoryCard
                  key={product.itemOriginID}
                  itemId={product.itemMasterID}
                  cardName={product.shortDescription}
                  imgSrc={product.imageUrl}
                  handleCardClick={handleCardClick}
                  price={product.brandedMerchandisePrice}
                  srp={product.minSrp}
                  source='Collection Product List'
                />
              ))}
            </div>
          ) : (
            <Typography>No products found for this collection.</Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default CollectionProductList;
 