import { React, useEffect, useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { FiChevronRight } from "react-icons/fi";
import NotifItem from "./AllNotifItem";
import appleFritter from "./assets/appleFritter.jpg";
import blueberryMuffin from "./assets/blueberryMuffin.jpg";
import cheezePuffs from "./assets/cheezePuffs.jpg";
import chickenCaesar from "./assets/chickenCaesar.jpg";
import chickenCaesarSalad from "./assets/chickenCaesarSalad.jpg";
import gummiBears from "./assets/gummiBears.jpg";
import gummiBlueSharks from "./assets/gummiBlueSharks.jpg";
import gummiPeachRings from "./assets/gummiPeachRings.jpg";
import gummiWorms from "./assets/gummiWorms.jpg";
import { AiFillCloseCircle } from "react-icons/ai";
import ErrorOutlineTwoToneIcon from "@mui/icons-material/ErrorOutlineTwoTone";
import WarningIcon from "@mui/icons-material/Warning";
import { Pagination } from "@mui/material";

const Wrapper = styled.div`
  padding: 10rem 5rem 4rem 5rem;
  margin-bottom: 2rem;
  min-height: 80vh;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f7fafc;
  position: relative;

  @media (min-width: 1521px) {
    padding-left: 10vw;
    padding-right: 10vw;
  }
`;
const RouteWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;
const RouteName = styled.h4`
  > a {
    color: #147350;
    text-decoration: none;
  }
  > svg {
    font-size: 1.5rem;
    color: darkgray;
    position: relative;
    top: 0.43rem;
    margin: 0 0.5rem;
  }
`;
const PublishOptContainer = styled.div`
  width: 95.8%;
  height: 2rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;
const PublishOption = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;
const PublishBtn = styled.button`
  width: auto;
  margin-bottom: 1rem;
  padding: 0.3rem 0.5rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  cursor: pointer;
  border: 1px solid #147350;
  color: ${(props) => (props.disabled ? "#147350" : "white")};
  background-color: ${(props) =>
    props.disabled ? "rgb(220, 217, 217)" : "#147350"};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  //box-shadow: 0 5px 5px -5px rgba(33, 33, 33, 0.7);
`;
const UnpublishBtn = styled.button`
  width: auto;
  margin-bottom: 1rem;
  padding: 0.3rem 0.5rem;
  border-radius: 0.3rem;
  font-size: 1rem;
  cursor: pointer;
  border: 1px solid #e37739;
  color: ${(props) => (props.disabled ? "#e37739" : "white")};
  background-color: ${(props) =>
    props.disabled ? "rgb(220, 217, 217)" : "#e37739"};
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  //box-shadow: 0 5px 5px -5px rgba(33, 33, 33, 0.7);
`;
const NotifCardContainer = styled.div`
  width: 100%;
  height: auto;
  min-height: 10rem;
  max-height: 60vh;
  overflow-x: auto;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgba(100, 116, 139, 0.8);

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the thumb (dragging part) */
    border-radius: 6px; /* Rounded corners for the thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the thumb on hover */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the track (the background) */
  }
`;
const ConfirmModalWrapper = styled.div`
  position: fixed;
  top: 0rem;
  left: 0rem;
  right: 0;
  bottom: 0;
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
`;
const ConfirmModalBody = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 0rem;
  padding-top: 2px;
  width: 40%;
  max-width: 38rem;
  max-height: 70vh;
  /* overflow-y: scroll; */
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background: #f2f2f2;
`;
const ConfirmModalHeader = styled.div`
  padding: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* overflow-y: scroll; */
`;
const ConfirmModalForm = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  margin-top: 0;
  padding: 1rem;
  border-radius: 0.5rem;
  max-width: 97%;
  width: auto;
  background: white;
  /* overflow-y: scroll; */
  input {
    padding: 0.6rem;
  }
  .MuiFilledInput-input {
    padding: 0.6rem;
  }
  .MuiInputBase-root {
    border-radius: 0.3rem;
  }
`;
const FormInputContainer = styled.div`
  margin-bottom: 0.3rem;
  margin-top: 0.5rem;
`;
const FormInputHeading = styled.p`
  font-weight: 500;
  font-size: 0.9rem;
  margin-bottom: 1rem;
`;
const WarningWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  background: #ffeebf;
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  padding: 0.5rem;
`;
const FormBtnWrapper = styled.div`
  width: 100%;
  margin-bottom: 0;
  margin-top: 0.5rem;
  float: right;
  display: flex;
  justify-content: flex-end;
`;
const SubmitBtn = styled.button`
  padding: 0.4rem 1rem;
  height: auto;
  background-color: #147350;
  color: white;
  border-style: none;
  border-radius: 0.3rem;
  margin-left: 0.5rem;
  cursor: pointer;
  font-weight: 600;
`;
const CancelBtn = styled.button`
  padding: 0.4rem 1rem;
  height: auto;
  font-weight: 500;
  text-align: center;
  border: 1px solid #d2d2d2;
  background-color: #f6f6f6;
  color: black;
  border-radius: 5px;
  cursor: pointer;
`;
const PdtNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  justify-content: center;
  align-items: flex-start;
  border-radius: 0.5rem;
  background: rgba(242, 242, 242, 0.5);
  height: auto;
  min-height: 2rem;
  max-height: 6rem;
  overflow-x: auto;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: rgba(100, 116, 139, 0.8);

  &::-webkit-scrollbar {
    width: 0.45rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888; /* Color of the thumb (dragging part) */
    border-radius: 6px; /* Rounded corners for the thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Color of the thumb on hover */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the track (the background) */
  }
`;
const PdtName = styled.p`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 31.976px;
`;

const AllNotification = () => {
  const [publishOpt, setPublishOpt] = useState(true); //
  const [selected, setSelected] = useState(true); //if publish/unpublish option is selected
  const [selectedList, setSelectedList] = useState([]); //list of items selected
  const [checked, setChecked] = useState([0]); //toggle checkbox
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [tabChange, setTabChange] = useState(false);
  const [publishTab, setPublishTab] = useState(false);
  const [unpublishTab, setUnpublishTab] = useState(false);
  const [othersTab, setOthersTab] = useState(false);
  const [publishToDisplay, setPublishToDisplay] = useState([]);
  const [unpublishToDisplay, setUnpublishToDisplay] = useState([]);
  const publishWarning =
    "Once published, the product will be available to all the users on this platform.";
  const unPublishWarning =
    "Once unpublished, the product will no more be available on this platform.";
  const itemsPerPage = 5;
  const publishNotifData = [
    {
      name: "licence A",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "1 days ago",
      action: "Publish",
      status: "pending",
      itemDetails: {
        slin: 1111,
        name: "Apple Fritters",
        image: appleFritter,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
    {
      name: "licence B",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "2 days ago",
      action: "Unpublish",
      status: "pending",
      itemDetails: {
        slin: 1112,
        name: "Cheeze Puffs",
        image: cheezePuffs,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
    {
      name: "licence C",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "1 days ago",
      action: "Publish",
      status: "pending",
      itemDetails: {
        slin: 1113,
        name: "Blue Berry Muffin",
        image: blueberryMuffin,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
    {
      name: "licence D",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "3 days ago",
      action: "Unpublish",
      status: "pending",
      itemDetails: {
        slin: 1114,
        name: "Chicken Caesar Salad",
        image: chickenCaesarSalad,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
    {
      name: "licence E",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "2 days ago",
      action: "Publish",
      status: "pending",
      itemDetails: {
        slin: 1115,
        name: "Chicken Caesar",
        image: chickenCaesar,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
    {
      name: "licence B",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "2 days ago",
      action: "Publish",
      status: "pending",
      itemDetails: {
        slin: 1116,
        name: "Gummi Worms",
        image: gummiWorms,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
    {
      name: "licence B",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "2 days ago",
      action: "Unpublish",
      status: "pending",
      itemDetails: {
        slin: 1117,
        name: "Gummi Bears",
        image: gummiBears,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
    {
      name: "licence B",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "2 days ago",
      action: "Publish",
      status: "pending",
      itemDetails: {
        slin: 1118,
        name: "Gummi Blue Sharks",
        image: gummiBlueSharks,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
    {
      name: "licence B",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "2 days ago",
      action: "Unpublish",
      status: "pending",
      itemDetails: {
        slin: 1119,
        name: "Gummi Peach Rings",
        image: gummiPeachRings,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
    {
      name: "licence E",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "2 days ago",
      action: "Publish",
      status: "pending",
      itemDetails: {
        slin: 1115,
        name: "Chicken Caesar",
        image: chickenCaesar,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
    {
      name: "licence B",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "2 days ago",
      action: "Publish",
      status: "pending",
      itemDetails: {
        slin: 1116,
        name: "Gummi Worms",
        image: gummiWorms,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
    {
      name: "licence B",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "2 days ago",
      action: "Unpublish",
      status: "pending",
      itemDetails: {
        slin: 1117,
        name: "Gummi Bears",
        image: gummiBears,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
  ];
  const unpublishNotifData = [
    {
      name: "licence E",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "2 days ago",
      action: "Publish",
      status: "pending",
      itemDetails: {
        slin: 1115,
        name: "Chicken Caesar",
        image: chickenCaesar,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
    {
      name: "licence B",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "2 days ago",
      action: "Publish",
      status: "pending",
      itemDetails: {
        slin: 1116,
        name: "Gummi Worms",
        image: gummiWorms,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
    {
      name: "licence B",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "2 days ago",
      action: "Unpublish",
      status: "pending",
      itemDetails: {
        slin: 1117,
        name: "Gummi Bears",
        image: gummiBears,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
    {
      name: "licence B",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "2 days ago",
      action: "Publish",
      status: "pending",
      itemDetails: {
        slin: 1118,
        name: "Gummi Blue Sharks",
        image: gummiBlueSharks,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
    {
      name: "licence B",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "2 days ago",
      action: "Unpublish",
      status: "pending",
      itemDetails: {
        slin: 1119,
        name: "Gummi Peach Rings",
        image: gummiPeachRings,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
    {
      name: "licence A",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "1 days ago",
      action: "Publish",
      status: "pending",
      itemDetails: {
        slin: 1111,
        name: "Apple Fritters",
        image: appleFritter,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
    {
      name: "licence B",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "2 days ago",
      action: "Unpublish",
      status: "pending",
      itemDetails: {
        slin: 1112,
        name: "Cheeze Puffs",
        image: cheezePuffs,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
    {
      name: "licence C",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "1 days ago",
      action: "Publish",
      status: "pending",
      itemDetails: {
        slin: 1113,
        name: "Blue Berry Muffin",
        image: blueberryMuffin,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
    {
      name: "licence D",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut enim ad minim veniam",
      date: "3 days ago",
      action: "Unpublish",
      status: "pending",
      itemDetails: {
        slin: 1114,
        name: "Chicken Caesar Salad",
        image: chickenCaesarSalad,
        description:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt Ut",
        category: "single serve",
        manufacturer: "",
        country: "USA",
        shelfLife: "1 month",
        size: "1000g",
        handledTemp: "Ambient",
        kosherHalal: "N",
        lDU: "12",
        casePackSize: "12",
        sRP: "$12",
      },
    },
  ];

  const [currentPublishPage, setCurrentPublishPage] = useState(1);
  const [currentUnpublishPage, setCurrentUnpublishPage] = useState(1);

  const handlePublishPageChange = (event, newPage) => {
    setCurrentPublishPage(newPage);
  };
  const handleUnpublishPageChange = (event, newPage) => {
    setCurrentUnpublishPage(newPage);
  };

  useEffect(() => {
    setPublishToDisplay(
      publishNotifData.slice(
        (currentPublishPage - 1) * itemsPerPage,
        currentPublishPage * itemsPerPage
      )
    );
  }, [currentPublishPage]);
  useEffect(() => {
    setUnpublishToDisplay(
      unpublishNotifData.slice(
        (currentUnpublishPage - 1) * itemsPerPage,
        currentUnpublishPage * itemsPerPage
      )
    );
  }, [currentUnpublishPage]);

  const totalPublishPages = Math.ceil(publishNotifData.length / itemsPerPage);
  const totalUnpublishPages = Math.ceil(
    unpublishNotifData.length / itemsPerPage
  );

  const showUnpublished = () => {
    setSelected(true);
    setPublishOpt(false);
    setSelectedList([]);
    setUnpublishToDisplay(unpublishNotifData.slice(0, itemsPerPage));
    setCurrentUnpublishPage(1);
  };
  const showPublished = () => {
    setSelected(true);
    setPublishOpt(true);
    setSelectedList([]);
    setPublishToDisplay(publishNotifData.slice(0, itemsPerPage));
    setCurrentPublishPage(1);
  };
  const showOthers = () => {
    setSelected(false);
    setPublishOpt(false);
    setSelectedList([]);
  };
  const handlePublishClick = (data) => {
    console.log(selectedList);
    setShowConfirmation(true);
  };
  const handleUnPublishClick = (data) => {
    console.log(selectedList);
    setShowConfirmation(true);
  };
  const handleSelectUnselect = (data) => {
    console.log(data);
    // const currentIndex = selectedList.indexOf(data);
    // const newSelected = [...selectedList];

    // if (currentIndex === -1) {
    //   newSelected.push(data);
    // } else {
    //   newSelected.splice(currentIndex, 1);
    // }
    const currentIndex = checked.indexOf(data);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(data);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);

    if (selectedList.includes(data)) {
      setSelectedList(
        selectedList.filter((selectedList) => selectedList !== data)
      );
    } else {
      setSelectedList([...selectedList, data]);
    }
  };

  return (
    <Wrapper>
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <RouteWrapper>
          <RouteName>
            <NavLink to="/">Home</NavLink>
            <FiChevronRight />
          </RouteName>
          <RouteName>
            <NavLink to="/notification">Notification</NavLink>
            <FiChevronRight />
          </RouteName>
        </RouteWrapper>
        <div style={{ marginBottom: "1rem" }}>
          <h1>Notification</h1>
        </div>
        <PublishOptContainer>
          <PublishOption>
            <h4
              style={{
                marginRight: "0.9rem",
                color: publishOpt ? "#147350" : "black",
                fontWeight: "500",
                borderBottom: publishOpt ? "2px solid #147350" : "",
              }}
              onClick={() => {
                if (selectedList.length === 0) {
                  showPublished();
                } else {
                  setTabChange(true);
                  setPublishTab(true);
                  if (unpublishTab) setUnpublishTab(false);
                  if (othersTab) setOthersTab(false);
                }
              }}
            >
              Publish
            </h4>
            <h4
              style={{
                marginRight: "0.9rem",
                color: selected && !publishOpt ? "#e37739" : "black",
                fontWeight: "500",
                borderBottom:
                  selected && !publishOpt ? "2px solid #e37739" : "",
              }}
              onClick={() => {
                if (selectedList.length === 0) {
                  showUnpublished();
                } else {
                  setTabChange(true);
                  setUnpublishTab(true);
                  if (publishTab) setPublishTab(false);
                  if (othersTab) setOthersTab(false);
                }
              }}
            >
              Unpublish
            </h4>
            <h4
              style={{
                marginRight: "0.9rem",
                color: "black",
                borderBottom: !selected && !publishOpt ? "2px solid black" : "",
              }}
              onClick={() => {
                if (selectedList.length === 0) {
                  showOthers();
                } else {
                  setTabChange(true);
                  setOthersTab(true);
                  if (unpublishTab) setUnpublishTab(false);
                  if (publishTab) setPublishTab(false);
                }
              }}
            >
              Others
            </h4>
          </PublishOption>
          {selected &&
            (publishOpt ? (
              <PublishBtn
                onClick={() => {
                  handlePublishClick();
                }}
                disabled={selectedList.length === 0}
              >
                Publish
              </PublishBtn>
            ) : (
              <UnpublishBtn
                onClick={() => {
                  handleUnPublishClick();
                }}
                disabled={selectedList.length === 0}
              >
                Unpublish
              </UnpublishBtn>
            ))}
          {!selected && <></>}
        </PublishOptContainer>
      </div>
      <NotifCardContainer>
        {selected &&
          publishOpt &&
          publishToDisplay.map((item) => {
            return (
              <NotifItem
                key={item.itemDetails.slin}
                notifData={item.itemDetails}
                handleSelectUnselect={handleSelectUnselect}
              />
            );
          })}
        {selected &&
          !publishOpt &&
          unpublishToDisplay.map((item) => {
            return (
              <NotifItem
                key={item.itemDetails.slin}
                notifData={item.itemDetails}
                handleSelectUnselect={handleSelectUnselect}
              />
            );
          })}
        {!selected && (
          <p style={{ color: "rgb(130 120 120)" }}>No new notification</p>
        )}
      </NotifCardContainer>
      <div>
        {selected && publishOpt && (
          <Pagination
            count={totalPublishPages}
            page={currentPublishPage}
            onChange={handlePublishPageChange}
            // color="primary"
          />
        )}
        {selected && !publishOpt && (
          <Pagination
            count={totalUnpublishPages}
            page={currentUnpublishPage}
            onChange={handleUnpublishPageChange}
            // color="primary"
          />
        )}
      </div>
      <ConfirmModalWrapper show={showConfirmation}>
        <ConfirmModalBody>
          <ConfirmModalHeader>
            <p style={{ fontWeight: "500" }}>
              Confirmation to {publishOpt ? `publish` : `unpublish`}
            </p>
            <AiFillCloseCircle
              style={{
                color: "#8F8F8F",
                position: "absolute",
                right: "1.6rem",
                top: "0.5rem",
                cursor: "pointer",
              }}
              onClick={() => {
                setShowConfirmation(false);
              }}
            />
          </ConfirmModalHeader>
          <ConfirmModalForm>
            <FormInputContainer>
              <FormInputHeading>Selected products:</FormInputHeading>
              <PdtNameContainer>
                {selectedList.map((item) => {
                  return <PdtName>{item}</PdtName>;
                })}
              </PdtNameContainer>
            </FormInputContainer>
            <WarningWrapper>
              <ErrorOutlineTwoToneIcon
                style={{ width: "15px", marginRight: "0.5rem" }}
              />
              {publishOpt && <p>{publishWarning}</p>}
              {!publishOpt && <p>{unPublishWarning}</p>}
            </WarningWrapper>
            <FormBtnWrapper>
              <CancelBtn
                onClick={() => {
                  setShowConfirmation(false);
                }}
              >
                Cancel
              </CancelBtn>
              <SubmitBtn
                onClick={() => {
                  //   handleUnpublishConfirmation();
                }}
              >
                Confirm
              </SubmitBtn>
            </FormBtnWrapper>
          </ConfirmModalForm>
        </ConfirmModalBody>
      </ConfirmModalWrapper>
      <ConfirmModalWrapper show={tabChange}>
        <ConfirmModalBody style={{ width: "auto" }}>
          <ConfirmModalHeader>
            <WarningIcon
              style={{
                width: "20px",
                marginRight: "0.5rem",
              }}
            />
            <p style={{ fontWeight: "500" }}>Warning</p>
            <AiFillCloseCircle
              style={{
                color: "#8F8F8F",
                position: "absolute",
                right: "1.6rem",
                top: "0.5rem",
                cursor: "pointer",
              }}
              onClick={() => {
                setTabChange(false);
              }}
            />
          </ConfirmModalHeader>
          <ConfirmModalForm>
            <WarningWrapper style={{}}>
              <p>On changing tab you will loose your selected items</p>
            </WarningWrapper>
            <FormBtnWrapper>
              <CancelBtn
                onClick={() => {
                  setTabChange(false);
                }}
              >
                Cancel
              </CancelBtn>
              <SubmitBtn
                onClick={() => {
                  //   handleUnpublishConfirmation();
                  setTabChange(false);
                  if (publishTab) showPublished();
                  if (unpublishTab) showUnpublished();
                  if (othersTab) showOthers();
                }}
              >
                Continue
              </SubmitBtn>
            </FormBtnWrapper>
          </ConfirmModalForm>
        </ConfirmModalBody>
      </ConfirmModalWrapper>
    </Wrapper>
  );
};
export default AllNotification;
