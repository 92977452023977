import { React } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Button from '@mui/material/Button';

const Wrapper = styled.div`
  display: flex;
  height: 50vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .MuiButton-contained{
    color: white;
    border-radius: 2rem;
    cursor: pointer;

    @media (max-width: 770px) {
      font-size: 0.7rem;
    }
  }

`;
const ImageContainer = styled.div`
  display: flex;
`;
const TextContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const HeadingText = styled.p`
  display: flex;
  font-weight: 500;
  font-size: 1.25rem;
  margin-bottom: 0.75rem;
`;
const SupportText = styled.p`
  color: #A0A0A0;
  font-weight: 500;
  font-size: 1rem;
`;

const CommonErrorFallback = () => {
    const navigate = useNavigate();
    const handleButtonClick = () => {
        navigate(`/`);
    };

    return (
        <Wrapper>
            <TextContainer>
                <HeadingText>Something went wrong!</HeadingText>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={()=>{handleButtonClick()}}
                >
                BACK TO HOME
                </Button>
            </TextContainer>
        </Wrapper>
    );
 }
 
 export default CommonErrorFallback;