import * as React from "react";
import Modal from "react-modal";
import "../components/Footer/ContactUs.css";
import "../components/Table/CustomTable.css";
import "../components/Assets/DigitalAssets.css";

const ResponseModal = (props) => {
    return (
        <Modal
          className="contactModal"
          isOpen={props.processing}
          onRequestClose={props.closeProcessing}
          appElement={document.getElementById("root")}
        >
          <div className="contact-block">
            <div className="contact-container">
              <div className="btnContainer">
                <div className="contactModalCloseBtn">
                  <button onClick={() => {props.closeProcessing(); props.setCallFetchBucket();}}>X</button>
                </div>
              </div>
              <div className="contact-modal-body">{props.statusMessage}</div>
            </div>
          </div>
        </Modal>
    )
}

export default ResponseModal;