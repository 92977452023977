import React from "react";
import Carousel_1 from "../../assets/images/Carousel_1.png";
import Carousel_2 from "../../assets/images/Carousel_2.png";
import Carousel_3 from "../../assets/images/Carousel_3.png";
import FullPageCarousel from "../../Utils/FullPageCarousel";
import { useTranslation } from "react-i18next";
import { countryUrl } from "../../Constants/Constant";
import "./Home.css";
import ContactUs from "../Assets/Common/ContactUs";
import { Autocomplete, TextField } from "@mui/material";

const countryUrlKeys = Object.keys(countryUrl);
countryUrlKeys.sort((a, b) => a.localeCompare(b));

function openLinkInNewTab(key) {
  const link = countryUrl[key];
  if (link) {
    window.open(link, "_blank");
  }
}

const Home = () => {
  const { t } = useTranslation();

  let contents = [
    /*{
      headings: "",
      description: ["", ""],
      content: Carousel_1,
    },*/
    {
      headings: "",
      description: ["", ""],
      content: Carousel_2,
    },/*
    {
      headings: "",
      description: ["", ""],
      content: Carousel_3,
    },*/
  ];

  return (
    <div>
      <div className="bg-home-video-container">
        <FullPageCarousel contents={contents} />
      </div>
      <div className="brandstory-vision">
        <h1>{t("brandstory_vision")}</h1>
        <p>{t("brandstory_quote1")}</p>
      </div>
      <div className="brandstory-misson">
        <h1>{t("brandstory_mission")}</h1>
        <p>
          {t("brandstory_quote2")}
          <br />
          {t("personalized_convenience")}
        </p>
      </div>
      <div className="header-container">
        <div className="global-sites">
          <Autocomplete
            id="global-sites-dropdown"
            clearOnEscape
            options={countryUrlKeys}
            getOptionLabel={(option) => `${option}`}
            onChange={(e, selectedOption) => {
              if (selectedOption) {
                openLinkInNewTab(selectedOption);
              }
            }}
            sx={{
              width: "500px",
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Global Sites"
                variant="outlined"
                InputLabelProps={{
                  sx: {
                    color: "black",
                  },
                }}
              />
            )}
          />
        </div>
      </div>
      <div className="homepage_footer">
        <ContactUs />
      </div>
    </div>
  );
};

export default Home;
