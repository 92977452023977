import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { 
    MenuItem,
    Pagination,
    Select,
    Stack,
    IconButton
} from '@mui/material';
import { Edit, Delete } from '@mui/icons-material'; 
import './CommonGrid.scss';

const CommonGrid = ({
    rows,
    columns,
    getRowId,
    filterModel,
    sortModel,
    onFilterModelChange,
    onSortModelChange,
    totalRows,
    rowsPerPage,
    page,
    onPageChange,
    onRowsPerPageChange,
    loading,
    onEditClick,
    onDeleteClick,
    rowsPerPageCount
}) => {
    const actionColumn = {
        field: 'actions',
        disableColumnMenu: true,
        headerName: 'ACTIONS',
        sortable: false,
        filterable: false,
        renderCell: (params) => (
            <div>
                <IconButton
                    aria-label="edit"
                    color="inherit"
                    onClick={() => onEditClick(params.row)}
                >
                    <Edit />
                </IconButton>
                <IconButton
                    aria-label="delete"
                    color="inherit"
                    onClick={() => onDeleteClick(params.row)}
                >
                    <Delete />
                </IconButton>
            </div>
        ),
        width: 150,
        align: 'left'
    };

    const formattedColumns = columns.map((col) => {
        if (col.field === 'priceUSD' || col.field === 'srpUSD') {
            return {
                ...col,
                renderCell: (params) => `$${Number(params.value).toFixed(2)}`,
            };
        }
        return col;
    });

    const updatedColumns = [...formattedColumns, actionColumn];


    return (
        <div className="common-grid-container">
            <DataGrid
                rows={rows}
                columns={updatedColumns} 
                getRowId={getRowId}
                hideFooter={true}
                disableColumnMenu={true}
                disableColumnSorting={true}
                localeText={{
                    columnMenuSortAsc: 'Sort By A-Z',
                    columnMenuSortDesc: 'Sort By Z-A',
                    filterPanelInputLabel: 'Search',
                    filterPanelInputPlaceholder: '',
                    filterPanelOperator: '',
                    filterPanelColumns: ''
                }}
                className="data-grid"
                onFilterModelChange={onFilterModelChange}
                onSortModelChange={onSortModelChange}
                disableColumnSelector
                filterModel={filterModel}
                sortModel={sortModel}
                filterMode='server'
                sortingMode='server'
                loading={loading}
            />
            <Stack spacing={2} direction="row" justifyContent={"space-between"} alignItems={"center"} marginBottom={"1rem"}>
                <div className='rows-count'>
                    Total number of rows: {totalRows}
                </div>
                <div className='pagination-container'>
                    <div>
                        <span>Rows per page </span>
                        <Select
                            id="rows-per-page"
                            value={rowsPerPage}
                            onChange={onRowsPerPageChange}
                            size='small'
                            variant='standard'
                            color="success"
                        >
                            {rowsPerPageCount.map((count, index) => (
                                <MenuItem key={index} value={count}>
                                    {count}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <Pagination
                        sx={{ justifyContent: "flex-end", marginTop: '1rem', alignItems: 'center' }}
                        count={Math.ceil(totalRows/rowsPerPage)}
                        page={page}
                        onChange={onPageChange}
                        size="small"
                    />
                </div>
            </Stack>
        </div>
    );
};

export default CommonGrid;
