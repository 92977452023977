import { React, useEffect, useState } from "react";
import "./ContactUsPage.css";
import { useTranslation } from "react-i18next";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { ImBlocked } from "react-icons/im";
import LayoutComponent from "../Layout/LayoutComponent";
import ContactForm from "./ContactForm";

function ContactUsPage(props) {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [resEmail, setResEmail] = useState("Processing...");
  return (
    <LayoutComponent>
      <div className="contactUsWrapper">
        {!submitted && (
          <div style={{ textAlign: "center" }}>
            <h2>{props.heading || "Contact Us"}</h2>
            <div style={{ textAlign: "center", padding: "2 0rem" }}>
              <h5>
                Thank you for your interest in partnering with 7-Eleven
                International. To submit your inquiry, please complete the form
                below.
              </h5>
              <h5>
                If you're a customer looking for assistance, please visit your
                local 7-Eleven store or contact the 7-Eleven page for your
                country/region <a href="/int-partnerships/#countries" style={{color:"#147350", textDecoration: 'none'}}>HERE</a>.
              </h5>
            </div>
          </div>
        )}
        {submitted && (
          <div className="contactFormInput-Res">
            <h1
              className={
                isSending
                  ? `contact-response-black processing-modal-black`
                  : resEmail === 200
                  ? `contact-response-green processing-modal`
                  : `contact-response-red`
              }
            >
              {isSending ? (
                <>{t("processing_contact_res")}</>
              ) : resEmail === 403 ? (
                <>
                  <ImBlocked
                    style={{
                      textAlign: "center",
                      scale: "1",
                      paddingRight: "10px",
                      paddingTop: "4px",
                    }}
                  />
                  {t("invalid_contact_res")}
                </>
              ) : resEmail === 500 ? (
                <>
                  <ImBlocked
                    style={{
                      textAlign: "center",
                      scale: "1",
                      paddingRight: "10px",
                      paddingTop: "4px",
                    }}
                  />
                  {t("wrong_contact_res")}
                </>
              ) : (
                <>
                  <MdOutlineMarkEmailRead
                    style={{
                      textAlign: "center",
                      scale: "1.5",
                      paddingRight: "10px",
                      paddingTop: "4px",
                    }}
                  />
                  {t("sent_contact_res")}
                </>
              )}
              !
            </h1>
          </div>
        )}
        <ContactForm
          onSubmit={() => setSubmitted(true)}
          onSending={(val) => setIsSending(val)}
          onApiResponseStatus={(code) => setResEmail(code)}
          isVisible={!submitted}
        />
      </div>
    </LayoutComponent>
  );
}

export default ContactUsPage;
