import { configureStore } from "@reduxjs/toolkit";
import PBCatalogSlice from "./PBCatalogSlice";
import UserManagementSlice from "../UserManagement/UserManagementSlice";
import BMCollectionSlice from "../BrandedMerchandise/BMCollectionSlice";

const store = configureStore({
  reducer: {
    pbCatalog: PBCatalogSlice,
    userManagement: UserManagementSlice,
    bmCollection:BMCollectionSlice,
  },
});

export default store;
