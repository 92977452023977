export const redirect_uri = process.env.REACT_APP_REDIRECT_URI;
export const app_url = process.env.REACT_APP_URI;
export const login_domain = process.env.REACT_APP_LOGIN_DOMAIN;
export const client_id = process.env.REACT_APP_SICI;
export const clientSecret = process.env.REACT_APP_SICS;
export const tokenUrl = process.env.REACT_APP_TOKEN_URL;
export const logout_uri = process.env.REACT_APP_LOGOUT_URI;
export const fetch_bucket = process.env.REACT_APP_FETCH_BUCKET;
export const signed_url = process.env.REACT_APP_SIGNED_URL;
export const submit_contact = process.env.REACT_APP_SUBMIT_CONTACT;
export const logoutRevoke = process.env.REACT_APP_LOGOUT_REVOKE;
export const delete_file = process.env.REACT_APP_DELETE_FILE;
export const add_file = process.env.REACT_APP_ADD_FILE;
export const isLoginEnabled = process.env.REACT_APP_LOGIN_ENABLED;
export const delete_folder = process.env.REACT_APP_DELETE_FOLDER;
export const fetch_post = process.env.REACT_APP_FETCH_POST;
export const approve_post = process.env.REACT_APP_APPROVE_POST;
export const create_post = process.env.REACT_APP_CREATE_POST;
export const alert_post = process.env.REACT_APP_ALERT_POST;
export const edit_user = process.env.REACT_APP_EDIT_USER;
export const user_mngt_users_list = process.env.REACT_APP_GET_USERS_LIST;
export const user_mngt_add_user = process.env.REACT_APP_USERMANAGEMENT_ADD_USER;
export const user_mngt_edit_user = process.env.REACT_APP_USERMANAGEMENT_EDIT_USER;
export const user_mngt_delete_user = process.env.REACT_APP_USERMANAGEMENT_DELETE_USER;
export const user_mngt_deactivate_user = process.env.REACT_APP_USERMANAGEMENT_DEACTIVATE_USER;
export const user_mngt_reset_user = process.env.REACT_APP_USERMANAGEMENT_RESET_USER;
export const get_user = process.env.REACT_APP_GET_USER;
export const get_profile = process.env.REACT_APP_GET_PROFILE;
export const cognito_email = process.env.REACT_APP_EMAILS_COGNITO;
export const delete_post = process.env.REACT_APP_DELETE_POST;
export const login_event = process.env.REACT_APP_LOGIN_EVENT;
export const transcoder = process.env.REACT_APP_TRANSCODER;
export const get_report = process.env.REACT_APP_ESG_GET_REPORT;
export const view_report = process.env.REACT_APP_VIEW_REPORT;
export const add_career = process.env.REACT_APP_ADD_CAREER;
export const get_careers = process.env.REACT_APP_GET_CAREERS;
export const delete_career = process.env.REACT_APP_DELETE_CAREER;
export const update_career = process.env.REACT_APP_UPDATE_CAREER;
export const pb_service = process.env.REACT_APP_PB_SERVICE;
export const cognito_userpool_id = process.env.REACT_APP_COGNITO_POOL_ID;
export const region = process.env.REACT_APP_REGION;