import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { addItemToBMList } from './BMCollectionSlice'; 
import './BrandedMerchandise.scss';

const AddToListButton = ({ itemId, onRemoveItem }) => {
  const dispatch = useDispatch();
  const bmList = useSelector((state) => state.bmCollection.bmList);
  const [quantity, setQuantity] = useState(bmList.find(item => item.id === itemId)?.count || 0);

  const handleAddToListClick = (event) => {
    event.stopPropagation(); 
    if (quantity === 0) {
      dispatch(addItemToBMList({ id: itemId, count: 1 }));
      setQuantity(1);
    }
  };

  const handleIncrement = (event) => {
    event.stopPropagation(); 
    const newQuantity = quantity + 1;
    dispatch(addItemToBMList({ id: itemId, count: newQuantity }));
    setQuantity(newQuantity);
  };

  const handleDecrement = (event) => {
    event.stopPropagation(); 
    const newQuantity = Math.max(0, quantity - 1);
    if (newQuantity === 0 && onRemoveItem) { 
      onRemoveItem(itemId); 
    } else {
      dispatch(addItemToBMList({ id: itemId, count: newQuantity }));
    }
    setQuantity(newQuantity);
  };

  const handleQuantityChange = (event) => {
    const value = Math.max(0, parseInt(event.target.value, 10) || 0);
    if (value === 0 && onRemoveItem) { 
      onRemoveItem(itemId); 
    } else {
      dispatch(addItemToBMList({ id: itemId, count: value }));
    }
    setQuantity(value);
  };

  return quantity === 0 ? (
    <Button
      className="add-to-list-button"
      onClick={handleAddToListClick}
    >
      ADD TO LIST
    </Button>
  ) : (
    <div className="quantity-controls">
      <Button
        variant="outlined"
        className="quantity-button"
        onClick={handleDecrement}
      >
        -
      </Button>
      <input
        type="text"
        value={quantity}
        onChange={handleQuantityChange}
        className="quantity-input"
        onClick={(e) => e.stopPropagation()}
        min="0"
      />
      <Button
        className="quantity-button"
        onClick={handleIncrement}
      >
        +
      </Button>
    </div>
  );
};

export default AddToListButton;
