import React from 'react';
import PropTypes from 'prop-types';
import './CommonSearchBar.scss';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = ({ placeholder, value, onChange }) => {
  return (
    <div className="search-bar-wrapper">
      <SearchIcon className="search-icon" />
      <input
        type="text"
        placeholder={placeholder}
        className="search-input"
        value={value} 
        onChange={onChange}
      />
    </div>
  );
};

SearchBar.propTypes = {
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired, 
};

export default SearchBar;
