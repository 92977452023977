import { useContext } from "react";
import { UserContext } from "../../App";
import "./CustomTable.css";
import BrandTable from "./CustomFolderTable";

function BrandFolders(props) {
  const { state, dispatch } = useContext(UserContext);
  var directoryArrFiles = [];
  var directoryArrFolders = [];

  if (!props.paths) {
    directoryArrFiles = [];
    directoryArrFolders = [];
  } else {
    props.paths.files.map((path) => {
      directoryArrFiles.push([
        path.File_Name,
        path.LastModified,
        path.Size,
        path.Key,
      ]);
    });

    let folderNames = Object.keys(props.paths);
    folderNames = folderNames.filter((data) => data !== "files");

    folderNames.map((name) => {
      directoryArrFolders.push([name, "-", "-", `${props.url}/${name}`]);
    });
  }

  let renderTable;

  if (state) {
    renderTable = (
      <BrandTable
        reloadPath={props.reloadPath}
        setReloadPath={props.setReloadPath}
        setCallFetchBucket={props.setCallFetchBucket}
        setIsLoading={props.setIsLoading}
        tableContentFiles={directoryArrFiles}
        tableContentFolders={directoryArrFolders}
        directoryNames={props.directoryNames}
        heading={props.heading}
        parentFolder={props.url}
      />
    );
  } else {
    renderTable = <h1 className="container1-h1">Access Denied</h1>;
  }

  return <>{renderTable}</>;
}

export default BrandFolders;
