const supportedLangs = [
  "en",
  "ja",
  "zh",
  "es",
  "hi",
  "ar",
  "fr",
  "pt",
  "id",
  "ko",
  "de",
];

const languages = [
  { code: "en", name: "English", countryCode: "us" },
  { code: "ja", name: "日本", countryCode: "jp" },
  { code: "zh", name: "普通话", countryCode: "cn" },
  { code: "es", name: "Español", countryCode: "es" },
  // { code: "hi", name: "हिन्दी", countryCode: "in" },
  // { code: "ar", name: "عربي", countryCode: "sa", dir: "rtl" },
  { code: "fr", name: "Français", countryCode: "fr" },
  // { code: "pt", name: "Português", countryCode: "pt" },
  // { code: "id", name: "Indonesia", countryCode: "id" },
  // { code: "ko", name: "한국어", countryCode: "kr" },
  { code: "de", name: "Deutsch", countryCode: "de" },
];

export { supportedLangs, languages };
