import React, { useState } from "react";
import "./GlobalPresenceChart.scss";
import { Chart } from "react-google-charts";
import { Button } from "@mui/material";
import CustomModal from "../CustomModal/CustomModal";
import { countryUrl } from "../../../../Constants/Constant";

const GlobalPresenceChart = ({ countryNames, urlList }) => {
  const [showViewPopup, setShowViewPopup] = useState(false);
  const handleClosePopup = () => {
    setShowViewPopup(false);
  };
  return (
    <>
      <Chart
        chartEvents={[
          {
            eventName: "select",
            callback: ({ chartWrapper }) => {
              const chart = chartWrapper.getChart();
              const selection = chart.getSelection();
              if (selection.length === 0) return;
              const region = countryNames[selection[0].row + 1];
              window.open(urlList[region], "_blank");
            },
          },
        ]}
        chartType="GeoChart"
        width="100%"
        height="80%"
        data={countryNames.map((item) => [item])}
        options={{
          colorAxis: { colors: ["#00853f", "black", "#e31b23"] },
          backgroundColor: "#20262E",
          datalessRegionColor: "#F7EFE5",
          defaultColor: "#147350",
          domain: "IN",
          animation: {
            startup: true,
            duration: 1500,
          },
        }}
      />
      <Button
        variant="contained"
        size="large"
        className="view-presence-list-btn"
        onClick={() => {
          setShowViewPopup(true);
        }}
      >
        View List of Existing Countries & Region
      </Button>
      <CustomModal
        show={showViewPopup}
        onClose={handleClosePopup}
        heading="Global Presence"
      >
        <div className="view-list-container">
          <div className="countries-popup-list">
            <div>
              <strong>Asia</strong>
              <li>
                <a
                  href={countryUrl["Cambodia"] || countryUrl["urlUnavailable"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Cambodia
                </a>
              </li>
              <li>China</li>
              <ul>
                <li>
                  <a
                    href={countryUrl["Hong Kong"] || countryUrl["urlUnavailable"]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Hong Kong/Macau
                  </a>
                </li>
                <li>
                  <a
                    href={countryUrl["Guangdong"] || countryUrl["urlUnavailable"]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Guangdong
                  </a>
                </li>
                <li>
                  <a
                    href={countryUrl["Taiwan"] || countryUrl["urlUnavailable"]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Taiwan
                  </a>
                </li>
                <li>
                  <a
                    href={countryUrl["urlUnavailable"]}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    All Other
                  </a>
                </li>
              </ul>
              <li>
                <a
                  href={countryUrl["Laos"] || countryUrl["urlUnavailable"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Laos
                </a>
              </li>
              <li>
                <a
                  href={countryUrl["India"] || countryUrl["urlUnavailable"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  India
                </a>
              </li>
              <li>
                <a
                  href={countryUrl["Japan"] || countryUrl["urlUnavailable"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Japan
                </a>
              </li>
              <li>
                <a
                  href={countryUrl["Malaysia"] || countryUrl["urlUnavailable"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Malaysia
                </a>
              </li>
            </div>
            <div>
              <strong>Asia cont.</strong>
              <li>
                <a
                  href={countryUrl["Singapore"] || countryUrl["urlUnavailable"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Singapore
                </a>
              </li>
              <li>
                <a
                  href={countryUrl["South Korea"] || countryUrl["urlUnavailable"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  South Korea
                </a>
              </li>
              <li>
                <a
                  href={countryUrl["Philippines"] || countryUrl["urlUnavailable"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Philippines
                </a>
              </li>
              <li>
                <a
                  href={countryUrl["Thailand"] || countryUrl["urlUnavailable"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Thailand
                </a>
              </li>
              <li>
                <a
                  href={countryUrl["Vietnam"] || countryUrl["urlUnavailable"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Vietnam
                </a>
              </li>
              <strong>Europe</strong>
              <li>
                <a
                  href={countryUrl["Denmark"] || countryUrl["urlUnavailable"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Denmark
                </a>
              </li>
              <li>
                <a
                  href={countryUrl["Norway"] || countryUrl["urlUnavailable"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Norway
                </a>
              </li>
              <li>
                <a
                  href={countryUrl["Sweden"] || countryUrl["urlUnavailable"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sweden
                </a>
              </li>
            </div>
            <div>
              <strong>Middle East/North Africa</strong>
              <li>
                <a
                  href={countryUrl["Israel"] || countryUrl["urlUnavailable"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Israel
                </a>
              </li>
              <strong>Middle East/North Africa</strong>
              <li>
                <a
                  href={countryUrl["United States"] || countryUrl["urlUnavailable"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  United States
                </a>
              </li>
              <li>
                <a
                  href={countryUrl["Canada"] || countryUrl["urlUnavailable"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Canada
                </a>
              </li>
              <li>
                <a
                  href={countryUrl["Mexico"] || countryUrl["urlUnavailable"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Mexico
                </a>
              </li>
              <strong>Oceania</strong>
              <li>
                <a
                  href={countryUrl["Australia"] || countryUrl["urlUnavailable"]}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Australia
                </a>
              </li>
            </div>
          </div>
          <div className="disclaimer-container">
            <p>
              Listed above offer individual store franchise opportunities:
              Australia, China (Beijing, Guangdong Province), Denmark, Hong
              Kong, Japan, Malaysia, Norway, Philippines, Singapore, South
              Korea, Sweden, Taiwan, and Thailand.
            </p>
            <p>
              <strong>ATTENTION:</strong> Links are intended to navigate you to
              the website of a 7‑Eleven Master Franchisee or Area Licensee. All
              7‑Eleven Master Franchisees or Area Licensees are independent
              franchisees or licensees who own and operate or franchise 7‑Eleven
              convenience stores, and 7‑Eleven has limited control over the
              content or privacy policies of any of the Master Franchisee or
              Area Licensee sites that you may link to from this site, or their
              advertisers. The terms and conditions, and privacy policies, of
              those websites (if any) may differ substantially from those on the
              official 7‑Eleven website. If you visit a Master Franchisee or an
              Area Licensee’s linked site, please be aware that the Area
              Licensee or third party operating any such site may have access to
              any information you submit via that site. 7‑Eleven is not
              responsible in any way if a Master Franchisee or Area Licensee or
              a third party does not establish or abide by its own privacy
              policy. We suggest always checking the privacy policy on each site
              that you visit before submitting any personal information to that
              site. Links to third-party sites do not imply that 7‑Eleven has
              reviewed or endorsed those sites or the content of those sites.
            </p>
            <p>
              For more information regarding franchising with one of our Master
              Franchisees or Area Licensees, please visit their website by using
              the appropriate links above.
            </p>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default GlobalPresenceChart;
