import DOMPurify from 'dompurify';
import validator from 'validator';
import { app_url } from '../config/environment';
import store from "../components/Assets/PrivateBrandCatalog/Store";
import { countryMapper } from '../Constants/Constant';

const axios = require("axios");
const qs = require("qs");
const resValidation = (res) => {
    if(validator.isJSON(JSON.stringify(res))) {
        return res;
    }
    else {
        return null;
    }
}

const sanitizeContentToRender = content => {
	// Configure DOMPurify to allow specific tags and attributes
	const config = {
		ADD_ATTR: ["target", "url"]
	};
	return DOMPurify.sanitize(content, config);
};

const encodeUtil = (item) => {
    const str= JSON.stringify(item);
    const encodedStr= encodeURIComponent(str);
    return encodedStr;
}

const checkFeatureAccess = (feature, access) => {
    const accessToken = localStorage.getItem("access_token");
    const checkExpiry = localStorage.getItem("expiry_time");

    if(!accessToken || !checkExpiry){
        return false;
    }

    const {userAccess, isAdmin} = getReduxStateBySlice('userManagement');

    return isAdmin || (userAccess && userAccess[feature] && userAccess[feature][access]);
};

const getCountryDataByProperty = (countryId, key) => {
  let data = ""
  countryMapper.map(cm => {
    if(cm.countryId === +countryId) data =  cm[key]
  })
  return data
}

const getReduxStateBySlice = (sliceName) => {
    const appState = store.getState();
    return appState[sliceName];
};

const checkExpiryAndRedirect = () => {
    let isTokenActive = true;
    const expTime = localStorage.getItem('expiry_time');
    if (Date.now() / 1000 >= expTime) {
        localStorage.removeItem('expiry_time');
        localStorage.removeItem('auth_time');
        localStorage.removeItem('username');
        window.location.replace(`https://${app_url}/logout`);
        isTokenActive = false;
    }
    return isTokenActive;
};


const trimFileName = (fileName) => {
  const index = fileName.lastIndexOf(".");
  return (
    fileName.substring(0, index).replace(/[^a-zA-Z0-9]/g, "_") +
    fileName.substring(index)
  );
};

const uploadImageToS3 = async (presignedPutObjectUrl, imageFileUploaded, fileCategory, fileName, onUploadProgress) => {
  // Get Presigned URL for S3 Bucket
  if (!imageFileUploaded) {
    return;
  }
  // get presigned url to put object in s3 bucket
  const fileNameArr = imageFileUploaded.name.split(".");
  const fileExt = fileNameArr.at(fileNameArr.length - 1);

  const presignedUrlResponse = await axios.request({
    method: "GET",
    url: presignedPutObjectUrl,
    headers: {
      Authorization: localStorage.getItem("access_token"),
    },
    params: {
      fileName,
      fileCategory: fileCategory,
      fileType: fileExt,
    },
    data: qs.stringify({
      accessToken: localStorage.getItem("access_token"),
    }),
  });
  const { url, fileName: fileNameInAws } =
    presignedUrlResponse?.data?.data?.url;
  await axios.request({
    method: "PUT",
    url,
    headers: {
      "Content-Type": imageFileUploaded.type,
    },
    data: imageFileUploaded,
    transformRequest: (data, headers) => {
      delete headers.common["Authorization"];
      return data;
    },
    onUploadProgress,
  });
  return fileNameInAws;
};

export { uploadImageToS3, trimFileName, resValidation, sanitizeContentToRender, encodeUtil, checkFeatureAccess, getReduxStateBySlice, checkExpiryAndRedirect, getCountryDataByProperty };