import { React, useState, useEffect } from "react";
import Modal from "react-modal";
import "./ContactUs.css";
import { useTranslation } from "react-i18next";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { ImBlocked } from "react-icons/im";
import { AiFillCloseCircle } from "react-icons/ai";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { countries } from "../../Constants/Constant";
import { submit_contact } from "../../config/environment";

var qs = require("qs");

function ContactUs(props) {
  const { t } = useTranslation();
  // const submit_contact = process.env.REACT_APP_SUBMIT_CONTACT;
  const [values, setValues] = useState({
    name: "",
    country: "",
    company: "",
    email: "",
    interest: "",
    msg: "",
  });
  const [nameFocused, setNameFocused] = useState(false);
  const [companyFocused, setCompanyFocused] = useState(false);
  const [otherFocused, setOtherFocused] = useState(false);
  const [numberFocused, setNumberFocused] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [msgFocused, setMsgFocused] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [resEmail, setResEmail] = useState("Processing...");
  const [isSending, setIsSending] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [otherSelected, setOtherSelected] = useState(false);
  const areaOfInterest = [
    "Food / Beverage",
    "Equipment",
    "Media",
    "Marketing",
    "Available Markets / New Market Entry",
    "7-Eleven Global Technology",
    "Investments",
    "Other",
  ];

  function SubmitButton() {
    if (isValid) {
      return (
        <button
          className="submitBtn"
          onClick={(e) => {
            e.preventDefault();
            submitContact();
          }}
        >
          {t("contactUs_submit")}
        </button>
      );
    } else {
      return (
        <button className="submitBtn-disabled">{t("contactUs_submit")}</button>
      );
    }
  }

  const openModal = () => {
    setValues({
      name: "",
      country: "",
      company: "",
      email: "",
      interest: "",
      msg: "",
    });
    setNameFocused(false);
    setCompanyFocused(false);
    setOtherFocused(false);
    setNumberFocused(false);
    setEmailFocused(false);
    setMsgFocused(false);
    props.contactOpen(true);
    if (props.hamClose) {
      props.hamClose(false);
    }
  };

  const Validate = () => {
    return (
      !values.name.replace(/\s/g, "").length ||
      !values.email.includes(".com") ||
      !values.email.includes("@") ||
      !(values.msg?.length > 1) ||
      !(values.company?.length >= 1) ||
      !(values.country?.length > 1) ||
      !(values.interest?.length > 1)
    );
  };

  useEffect(() => {
    var check = Validate();
    setIsValid(!check);
  }, [values]);

  const closeModal = () => {
    props.contactOpen(false);
    setSubmitted(false);
    setResEmail("Processing...");
    setValues({
      name: "",
      country: "",
      company: "",
      email: "",
      interest: "",
      msg: "",
    });
    setNameFocused(false);
    setCompanyFocused(false);
    setOtherFocused(false);
    setEmailFocused(false);
    setNumberFocused(false);
    setMsgFocused(false);
    if (props.hamClose) {
      props.hamClose(true);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleNameFocus = (e) => {
    setNameFocused(true);
  };
  const handleCompanyFocus = (e) => {
    setCompanyFocused(true);
  };
  const handleOtherFocus = (e) => {
    setOtherFocused(true);
  };
  const handleNumberFocus = (e) => {
    setNumberFocused(true);
  };
  const handleEmailFocus = (e) => {
    setEmailFocused(true);
  };
  const handleMsgFocus = (e) => {
    setMsgFocused(true);
  };

  const submitContact = async () => {
    setSubmitted(true);
    setIsSending(true);
    setIsValid(false);
    try {
      const res = await fetch(submit_contact, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify({
          userName: values.name,
          userEmail: values.email,
          userPhone: values.number,
          userMessage: values.msg,
        }),
      });
      const data = await res.json();
      const resStatus = await res.status;
      setResEmail(resStatus);
      setIsSending(false);
      setValues({
        name: "",
        country: "",
        company: "",
        email: "",
        interest: "",
        msg: "",
      });
      setNameFocused(false);
      setCompanyFocused(false);
      setOtherFocused(false);
      setNumberFocused(false);
      setEmailFocused(false);
      setMsgFocused(false);
    } catch (err) {}
  };

  let formRender;

  if (!submitted) {
    formRender = (
      <form onSubmit={handleSubmit}>
        <div className="contactFormInput">
          <input
            className="formInput"
            type="text"
            name="name"
            placeholder={t("contactUs_name_placeholder")}
            pattern="^(?! )[a-zA-Z_ ]*(?<! )$"
            maxLength="255"
            required
            onChange={onChange}
            onClick={handleNameFocus}
            focused={values.name && nameFocused.toString()}
            value={values.name}
          />
          <span className="formInputError">{t("contactUs_name_warning")}</span>
        </div>
        <div>
          <Autocomplete
            id="country-customized-option-demo"
            options={countries}
            disableCloseOnSelect
            getOptionLabel={(option) => `${option.label}`}
            onChange={(e, selectedOption) => {
              setValues({
                ...values,
                country: selectedOption ? selectedOption.label : "",
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Country/Region"
                InputLabelProps={{ shrink: false }}
                name="country"
              />
            )}
          />
        </div>
        <div className="contactFormInput">
          <input
            className="formInput"
            type="text"
            name="company"
            placeholder="Company"
            pattern="^(?! )[a-zA-Z_ ]*(?<! )$"
            maxLength="255"
            required
            onChange={onChange}
            onClick={handleCompanyFocus}
            focused={values.company && companyFocused.toString()}
            value={values.company}
          />
          <span className="formInputError">{t("contactUs_name_warning")}</span>
        </div>
        {/* <div className="contactFormInput">
          <input
            className="formInput"
            type="text"
            name="number"
            placeholder={t("contactUs_number_placeholder")}
            pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
            required
            onChange={onChange}
            onClick={handleNumberFocus}
            focused={values.number && numberFocused.toString()}
            value={values.number}
          />
          <span className="formInputError">
            {t("contactUs_number_warning")}
          </span>
        </div> */}
        <div className="contactFormInput">
          <input
            className="formInput"
            style={{ marginTop: "0" }}
            type="email"
            name="email"
            placeholder={t("contactUs_email_placeholder")}
            required
            onChange={onChange}
            onClick={handleEmailFocus}
            focused={values.email && emailFocused.toString()}
            value={values.email}
          />
          <span className="formInputError">{t("contactUs_email_warning")}</span>
        </div>
        <div>
          <Autocomplete
            id="country-customized"
            options={areaOfInterest}
            disableCloseOnSelect
            getOptionLabel={(option) => `${option}`}
            onChange={(e, selectedOption) => {
              if (selectedOption === "Other") {
                setOtherSelected(true);
              } else {
                setValues({
                  ...values,
                  interest: selectedOption ? selectedOption : "",
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Area of interest"
                InputLabelProps={{ shrink: false }}
                name="interest"
                onChange={onChange}
                required
              />
            )}
            required
          />
        </div>
        {otherSelected && (
          <div className="contactFormInput">
            <input
              className="formInput"
              type="text"
              name="other"
              placeholder="Interest"
              pattern="^(?! )[a-zA-Z_ ]*(?<! )$"
              maxLength="255"
              required
              onChange={(e) => {
                setValues({
                  ...values,
                  interest: e.target.value ? e.target.value : "",
                });
              }}
              onClick={handleOtherFocus}
              focused={values.other && otherFocused.toString()}
              value={values.other}
            />
            <span className="formInputError">
              {t("contactUs_name_warning")}
            </span>
          </div>
        )}
        <div className="contactFormInput">
          <input
            className="formInput"
            type="text"
            name="msg"
            style={{ marginTop: "0" }}
            placeholder={t("contactUs_msg_placeholder")}
            pattern="^[a-zA-Z0-9_][a-zA-Z0-9_ ]*[a-zA-Z0-9_]$"
            maxLength="1000"
            required
            onChange={onChange}
            onClick={handleMsgFocus}
            focused={values.msg && msgFocused.toString()}
            value={values.msg}
          />
          <span className="formInputError">{t("contactUs_msg_warning")}</span>
        </div>
        <SubmitButton />
      </form>
    );
  } else {
    formRender = (
      <div className="contactFormInput-Res">
        <h1
          className={
            isSending
              ? `contact-response-black processing-modal-black`
              : resEmail === 200
              ? `contact-response-green processing-modal`
              : `contact-response-red`
          }
        >
          {isSending ? (
            <>{t("processing_contact_res")}</>
          ) : resEmail === 403 ? (
            <>
              <ImBlocked
                style={{
                  textAlign: "center",
                  scale: "1",
                  paddingRight: "10px",
                  paddingTop: "4px",
                }}
              />
              {t("invalid_contact_res")}
            </>
          ) : resEmail === 500 ? (
            <>
              <ImBlocked
                style={{
                  textAlign: "center",
                  scale: "1",
                  paddingRight: "10px",
                  paddingTop: "4px",
                }}
              />
              {t("wrong_contact_res")}
            </>
          ) : (
            <>
              <MdOutlineMarkEmailRead
                style={{
                  textAlign: "center",
                  scale: "1.5",
                  paddingRight: "10px",
                  paddingTop: "4px",
                }}
              />
              {t("sent_contact_res")}
            </>
          )}
          !
        </h1>
      </div>
    );
  }

  return (
    <div>
      <button className="contactUsBtn" onClick={openModal}>
        {t("contact_us")}
      </button>
      <Modal
        className="contactModal"
        isOpen={props.checkContactOpen}
        onRequestClose={closeModal}
        appElement={document.getElementById("root")}
      >
        <div className="contact-block">
          <div className="contactUs-container">
            <div className="contactBtnContainer">
              <label className="formInputLabel">{t("contact_us")}</label>
              <AiFillCloseCircle
                style={{
                  height: "2rem",
                  width: "0.9rem",
                  position: "absolute",
                  top: "0",
                  right: "0",
                  background: "white",
                  color: "#dfdcdc",
                  marginTop: "-0.3rem",
                  marginRight: " 0.2rem",
                }}
                onClick={closeModal}
              />
            </div>
            <div className="contact-modal-body">{formRender}</div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
export default ContactUs;
