import { React, useContext } from "react";
import { BsFolderFill } from "react-icons/bs";
import { BsCollection } from "react-icons/bs";
import { ImLink } from "react-icons/im";
import { CgTemplate } from "react-icons/cg";
import { BsBriefcase } from "react-icons/bs";
import { BsTagsFill } from "react-icons/bs";
import "./First.css";
import { NavLink } from "react-router-dom";
import { UserContext } from "../../App";

function First() {
  const { state, dispatch } = useContext(UserContext);

  const checkAccess = localStorage.getItem("access_token");
  if (checkAccess) {
    dispatch({ type: "USER", payload: true });
  }

  return (
    <div className="container2">
      <h1 className="title">Assets</h1>
      <div className="navlinkFirst">
        <NavLink to="/digital-assets" className="asset-folder">
          <BsFolderFill className="icons" />
          <h4 className="heading">Files</h4>
        </NavLink>
        <div className="asset-folder">
          <BsCollection className="icons" />
          <h4 className="heading">Collection</h4>
        </div>
        <div className="asset-folder">
          <ImLink className="icons" />
          <h4 className="heading">Shared Links</h4>
        </div>
        <div className="asset-folder">
          <CgTemplate className="icons" />
          <h4 className="heading">Templates</h4>
        </div>
        <div className="asset-folder">
          <BsBriefcase className="icons" />
          <h4 className="heading">Jobs</h4>
        </div>
        <div className="asset-folder">
          <BsTagsFill className="icons" />
          <h4 className="heading">Smart Tag Training</h4>
        </div>
      </div>
    </div>
  );
}
export default First;
