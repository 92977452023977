import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "../Header/Header.css";
import "../Footer/Footer.css";
import "./Layout.css";
import { useLocation } from "react-router-dom";

const Layout = (props) => {
  const location = useLocation();
  const handleUserDetailUpdate = () => {
    props.handleUserDetailUpdate();
  };

  return (
    <div className="layout">
      <Header handleUserDetailUpdate={handleUserDetailUpdate} />
      {props.children}
      {location.pathname === "/assets/" ? <></> : <Footer />}
    </div>
  );
};
export default Layout;
