import React from "react";
import "./CustomCard.scss";

const CustomCard = ({ card }) => {
  return (
    <div key={card.id} id={card.id} className="card">
      <img src={card.img} alt="sustainability img" />
      <div className="card-details">
        <div className="card-heading-container">
          <h4>{card.heading}</h4>
        </div>
        <div className="card-subheading-container">
          <h5>{card.subHeading}</h5>
        </div>
        <div className="card-detail-container">
          <p>{card.details}</p>
        </div>
      </div>
    </div>
  );
};

export default CustomCard;
