import React from 'react';
import { useTranslation } from 'react-i18next';
import LayoutComponent from '../../Layout/LayoutComponent';
import './ContactUs.css'

const ContactUs = () => {
  const { t } = useTranslation();

  const contactData = [
    {
      heading: t('for_media'),
      link: 'https://corp.7-eleven.com/corp/in-the-news',
      email: 'GM-Media7IN@7-11.com',
    },
    {
      heading: t('for_marketing'),
      link: 'https://survey.medallia.com/?711-gr&channel=6&type=2',
      email: 'GM-Marketing7IN@7-11.com',
    },
    {
      heading: t('for_customers'),
      link: 'https://survey.medallia.com/?711-gr&channel=6&type=2',
      email: 'intl-license@7-11int.com',
    },
  ];

  return (
    <LayoutComponent>
      <h1 className="text-center contact-heading">{t('contact_us')}</h1>
      <div className="flex">
        {contactData.map((contact, index) => (
          <div className="section-content" key={index}>
            <div className="section-content-item">
              <h3>
                <a
                  href={contact.link}
                  className="contactCard"
                  target="_blank"
                  rel="noreferrer"
                >
                  {contact.heading} &rarr;
                </a>
              </h3>
              <p>
                {t('email_us')}: <br />
                <a className="email" href={`mailto:${contact.email}`}>
                  {contact.email}
                </a>
              </p>
            </div>
          </div>
        ))}
      </div>
    </LayoutComponent>
  );
};

export default ContactUs;
