import * as React from "react";
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
  
function LinearProgressBar(props) {
    return (

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
                <LinearProgress 
                    variant="determinate" 
                    sx={{
                        backgroundColor: '#e3e6e4',
                        '& .MuiLinearProgress-bar': {
                        backgroundColor: '#147350'
                        }
                    }}
                    {...props} 
                />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="text.secondary">
                    {`${Math.round(props.value,)}%`}
                </Typography>
            </Box>
      </Box>
    )
}

LinearProgressBar.propTypes = {
    value: PropTypes.number.isRequired,
  };

export default LinearProgressBar;