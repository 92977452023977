import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Paper } from '@mui/material';
import './FullPageCarousel.css';

const FullPageCarousel = ({ contents }) => {
    return (
        <div className="carousel-wrapper">
            <Carousel
                NextIcon={<></>}
                PrevIcon={<></>}
                indicators={true}
                indicatorIconButtonProps={{
                    style: {
                        padding: '2px',
                        color: '#ccc',
                    },
                }}
                activeIndicatorIconButtonProps={{
                    style: {
                        color: '#147350',
                    },
                }}
                indicatorContainerProps={{
                    className: 'carousel-indicators',
                }}
                sx={{
                    zIndex: '0',
                    width: '100%',
                    height: '100%',
                }}
            >
                {contents.map((content, i) => {
                    const isImage = /\.(jpg|png)$/i.test(content.content);

                    return (
                        <div key={i}>
                            {isImage ? (
                                <img
                                    src={content.content}
                                    alt={`carousel-${i}`}
                                    className="carousel-image"
                                    style={{
                                        filter: content.headings
                                            ? 'brightness(0.5)'
                                            : 'brightness(1)',
                                    }}
                                />
                            ) : (
                                <video
                                    src={content.content}
                                    loop
                                    autoPlay
                                    muted
                                    className="carousel-video"
                                    style={{
                                        filter: 'brightness(0.5)',
                                    }}
                                />
                            )}
                            <Paper className="carousel-overlay">
                                <h2>{content.headings}</h2>
                                {content.description?.map((desc, index) => (
                                    <p key={index}>{desc}</p>
                                ))}
                            </Paper>
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default FullPageCarousel;
