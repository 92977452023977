import React from "react";
import { useTranslation } from "react-i18next";
import "./Terms.css";
import LayoutComponent from "../../Layout/LayoutComponent";

const Terms = () => {
  const { t } = useTranslation();

  return (
    <div className="terms">
      <div className="termsBanner">
        <p>{t("term_and_conds")}</p>
      </div>
      <LayoutComponent>
        <div className="termsBody">
          <div className="termsTableOfContent">
            <h2>{t("term_table_contents")}</h2>
            <div className="privacyContentLink">
              <div className="contentLink1">
                <div className="privacyContentLinkDetails">
                  <div className="privacyContentLinkName">
                    <a href="#termsPart1">{t("term_for_all_users")}</a>
                  </div>
                </div>
              </div>
            </div>
            <p>{t("term_last_mdfd_28_oct")}</p>
            <p>
               {t("terms_top_para1_1")}{" "}
               <a href="https://www.7-eleven.com/">www.7-eleven.com</a> {t("terms_top_para1_2")}
             </p>
             <p>
               {t("terms_top_para2")}
             </p>
          </div>
          <hr></hr>
          <div id="termsPart1" className="termsPart1">
            <h1>{t("term_for_all_users")}</h1>
            <h2>{t("terms_acceptance_of_tou")}</h2>
            <p>
            {t("terms_acceptance_para1_1")}
            <a href="https://www.7-eleven.com/">www.7-eleven.com</a>, {t("terms_acceptance_para1_2")} <a href="https://www.7-eleven.com/">www.7-eleven.com</a> (the "Website").
            </p>
            <p>
            {t("terms_acceptance_para2")}
            </p>
            <h2>{t("terms_miscellaneous")}</h2>
            <p>
              {t("terms_miscellaneous_para1")}
            </p>
            <h2>{t("terms_changes_of_tou")}</h2>
            <p>
              {t("terms_changes_of_tou_para1")}
            </p>
            <p>
              {t("terms_changes_of_tou_para2")}
            </p>
            <h2>{t("terms_access_the_website")} </h2>
            <p>
            {t("terms_access_the_website_para1")}
            </p>
            <p>
            {t("terms_access_the_site_resp_for_both")}
            </p>
            <ol style={{listStyleType: 'inherit'}}>
              <li>
                {t("terms_access_the_site_resp_for_both_1")}
              </li>
              <li>
                {t("terms_access_the_site_resp_for_both_2")}
              </li>
            </ol>
            <h2>{t("terms_intellectual_property_rights")} </h2>
            <p>
            {t("terms_intellectual_property_rights_para1")}
            </p>
            <p>
            {t("terms_intellectual_property_rights_para2")}
            </p>
            <ol style={{listStyleType: 'inherit'}}>
              <li>
              {t("terms_intellectual_property_rights_para2_list1")}
              </li>
              <li>
              {t("terms_intellectual_property_rights_para2_list2")}
              </li>
              <li>
              {t("terms_intellectual_property_rights_para2_list3")}
              </li>
              <li>
              {t("terms_intellectual_property_rights_para2_list4")}
              </li>
            </ol>
            <p>
            {t("terms_intellectual_property_rights_you_must_not")}
            </p>
            <ol style={{listStyleType: 'inherit'}}>
              <li>
              {t("terms_intellectual_property_rights_you_must_not_list1")}
              </li>
              <li>
              {t("terms_intellectual_property_rights_you_must_not_list2")}
              </li>
              <li>
              {t("terms_intellectual_property_rights_you_must_not_list3")}
              </li>
            </ol>
            <p>
            {t("terms_intellectual_property_rights_para3")} 
            </p>
            <p>
            {t("terms_intellectual_property_rights_para4")} 
            </p>
            <p>
            {t("terms_intellectual_property_rights_para5")} 
            </p>
            <h2>{t("terms_trademarks")}</h2>
            <p>
            {t("terms_trademarks_para1")}
            </p>
            <h2>{t("terms_prohibited_uses")}</h2>
            <p>
            {t("terms_prohibited_uses_para1")}
            </p>
            <ol style={{listStyleType: 'inherit'}}>
              <li>
              {t("terms_prohibited_uses_para1_list1")} 
              </li>
              <li>
              {t("terms_prohibited_uses_para1_list2")}
              </li>
              <li>
              {t("terms_prohibited_uses_para1_list3")}
              </li>
              <li>
              {t("terms_prohibited_uses_para1_list4")}
              </li>
              <li>
              {t("terms_prohibited_uses_para1_list5")}
              </li>
            </ol>
            <p>
            {t("terms_prohibited_uses_additional")}
            </p>
            <ol style={{listStyleType: 'inherit'}}>
              <li>
              {t("terms_prohibited_uses_additional_list1")}
              </li>
              <li>
              {t("terms_prohibited_uses_additional_list2")}
              </li>
              <li>
              {t("terms_prohibited_uses_additional_list3")}
              </li>
              <li>
              {t("terms_prohibited_uses_additional_list4")}
              </li>
              <li>
              {t("terms_prohibited_uses_additional_list5")}
              </li>
              <li>
              {t("terms_prohibited_uses_additional_list6")} 
              </li>
              <li>
              {t("terms_prohibited_uses_additional_list7")}
              </li>
              <li>
              {t("terms_prohibited_uses_additional_list8")}
              </li>
            </ol>
            <h2>{t("terms_user_contributions")} </h2>
            <p>
            {t("terms_user_contributions_para1")}
            </p>
            <p>
            {t("terms_user_contributions_para2")}
            </p>
            <p>
            {t("terms_user_contributions_para3")} 
            </p>
            <p>
            {t("terms_user_contributions_para4")} 
            </p>
            <ol style={{listStyleType: 'inherit'}}>
              <li>
              {t("terms_user_contributions_para4_list1")}
              </li>
              <li>
              {t("terms_user_contributions_para4_list2")}
              </li>
            </ol>
            <p>
            {t("terms_user_contributions_para5")}
            </p>
            <p>
            {t("terms_user_contributions_para6")}
            </p>
            <h2>{t("terms_monitoring_and_enforcement")}</h2>
            <p>
            {t("terms_monitoring_and_enforcement_para1")}
            </p>
            <ol style={{listStyleType: 'inherit'}}>
              <li>
              {t("terms_monitoring_and_enforcement_para1_list1")}
              </li>
              <li>
              {t("terms_monitoring_and_enforcement_para1_list2")}
              </li>
              <li>
              {t("terms_monitoring_and_enforcement_para1_list3")}
              </li>
              <li>
              {t("terms_monitoring_and_enforcement_para1_list4")}
              </li>
              <li>
              {t("terms_monitoring_and_enforcement_para1_list5")}
              </li>
            </ol>
            <p>
            {t("terms_monitoring_and_enforcement_para2")}
            </p>
            <p>
            {t("terms_monitoring_and_enforcement_para3")} 
            </p>
            <h2>10. Content Standards</h2>
            <p>
            {t("terms_content_standards_para1")}
            </p>
            <ol style={{listStyleType: 'inherit'}}>
              <li>
                {t("terms_content_standards_para1_list1")}
              </li>
              <li>
                {t("terms_content_standards_para1_list2")}
              </li>
              <li>
                {t("terms_content_standards_para1_list3")}
              </li>
              <li>
                {t("terms_content_standards_para1_list4")}
              </li>
              <li>
                {t("terms_content_standards_para1_list5")}
              </li>
              <li>
                {t("terms_content_standards_para1_list6")}
              </li>
              <li>
                {t("terms_content_standards_para1_list7")}
              </li>
              <li>
                {t("terms_content_standards_para1_list8")}
              </li>
              <li>
                {t("terms_content_standards_para1_list9")}
              </li>
              <li>
                {t("terms_content_standards_para1_list10")}
              </li>
            </ol>
            <h2>{t("terms_copyright_infringement")}</h2>
            <p>
            {t("terms_copyright_infringement_para1")}
            </p>
            <h2>{t("terms_reliance_on_info_posted")}</h2>
            <p>
            {t("terms_reliance_on_info_posted_para1")}
            </p>
            <p>
            {t("terms_reliance_on_info_posted_para2")}
            </p>
            <h2>{t("terms_changes_to_website")}</h2>
            <p>
            {t("terms_changes_to_website_para1")} 
            </p>
            <h2>{t("terms_info_abt_you")}</h2>
            <p>
            {t("terms_info_abt_you_para1")} 
            </p>
            <h2>{t("terms_linking_website_and_social_medias")}</h2>
            <p>
            {t("terms_linking_website_and_social_medias_para1")} 
            </p>
            <p>
            {t("terms_linking_website_and_social_medias_para2")}
            </p>
            <ol style={{listStyleType: 'inherit'}}>
              <li>
              {t("terms_linking_website_and_social_medias_para2_list1")}
              </li>
              <li>
              {t("terms_linking_website_and_social_medias_para2_list2")}
              </li>
              <li>
              {t("terms_linking_website_and_social_medias_para2_list3")}
              </li>
            </ol>
            <p>
            {t("terms_linking_website_and_social_medias_para3")}
            </p>
            <ol style={{listStyleType: 'inherit'}}>
              <li>
              {t("terms_linking_website_and_social_medias_para3_list1")}
              </li>
              <li>
              {t("terms_linking_website_and_social_medias_para3_list2")}
              </li>
              <li>
              {t("terms_linking_website_and_social_medias_para3_list3")}
              </li>
              <li>
              {t("terms_linking_website_and_social_medias_para3_list4")}
              </li>
              <li>
              {t("terms_linking_website_and_social_medias_para3_list5")}
              </li>
              <li>
              {t("terms_linking_website_and_social_medias_para3_list6")}
              </li>
            </ol>
            <h2>{t("terms_link_from_website")}</h2>
            <p>
            {t("terms_link_from_website_para1")}
            </p>
            <h2>{t("terms_geo_restrictions")}</h2>
            <p>
            {t("terms_geo_restrictions_para1")}
            </p>
            <h2>{t("terms_disclaimer_warranties")}</h2>
            <p>
            {t("terms_disclaimer_warranties_para1")}
            </p>
            <p>
            {t("terms_disclaimer_warranties_para2")}
            </p>
            <p>
            {t("terms_disclaimer_warranties_para3")}
            </p>
            <p>
            {t("terms_disclaimer_warranties_para4")}
            </p>
            <h2>{t("terms_limit_liability")}</h2>
            <p>
            {t("terms_limit_liability_para1")}
            </p>
            <p>
            {t("terms_limit_liability_para2")}
            </p>
            <h2>{t("terms_indemnification")}</h2>
            <p>
            {t("terms_indemnification_para1")}
            </p>
            <h2>{t("terms_govt_law_jurisdiction")}</h2>
            <p>
            {t("terms_govt_law_jurisdiction_para1")}
            </p>
            <p>
            {t("terms_govt_law_jurisdiction_para2")}
            </p>
            <h2>{t("terms_waiver_severability")}</h2>
            <p>
            {t("terms_waiver_severability_para1")}
            </p>
            <p>
            {t("terms_waiver_severability_para2")}
            </p>
            <h2>{t("terms_entire_agreement")}</h2>
            <p>
            {t("terms_entire_agreement_para1")}
            </p>
            <h2>{t("terms_ur_comments_concerns")}</h2>
            <p>
            {t("terms_ur_comments_concerns_para1")}
            </p>
            <p>
            {t("terms_ur_comments_concerns_para2")}
            </p>
          </div>
          <hr></hr>
        </div>
      </LayoutComponent>
    </div>
  );
};

export default Terms;
