import React, { useState } from "react";
import "./HorizontalSectionMenu.scss";

const HorizontalSectionMenu = ({ sections }) => {
  const [activeSection, setActiveSection] = useState(sections[0].id);
  return (
    <>
      {sections.map((section) => (
        <a
          key={section.id}
          href={`#${section.id}`}
          className={`header-link ${
            activeSection === section.id ? "active" : ""
          }`}
          onClick={() => {
            setActiveSection(section.id);
          }}
        >
          {section.label}
        </a>
      ))}
    </>
  );
};

export default HorizontalSectionMenu;
