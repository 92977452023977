import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";

const DateSelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 1px;
  /* border: 2px solid black; */
  border-radius: 1.2rem;
  background-color: white;

  .react-datepicker-ignore-onclickoutside {
    border: none;

    &:focus-visible {
      border: none;
    }
  }

  input {
    width: 100%;
    border: none;
    text-align: center;
    &:focus-visible {
      border: none;
    }
  }
`;

const DateSelector = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  return (
    <DateSelectorContainer>
      <AiFillCloseCircle
        style={{
          color: "#8F8F8F",
          // position: "absolute",
          // right: "1.6rem",
          // top: "0.5rem",
          cursor: "pointer",
          width: "0.7rem",
          marginRight: "0.5rem",
        }}
        onClick={() => {}}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 13 13"
        fill="none"
      >
        <g clip-path="url(#clip0_1525_458)">
          <path
            d="M10.832 1.6224H10.2904V1.08073C10.2904 0.782812 10.0466 0.539062 9.7487 0.539062C9.45078 0.539062 9.20703 0.782812 9.20703 1.08073V1.6224H3.79036V1.08073C3.79036 0.782812 3.54661 0.539062 3.2487 0.539062C2.95078 0.539062 2.70703 0.782812 2.70703 1.08073V1.6224H2.16536C1.56953 1.6224 1.08203 2.1099 1.08203 2.70573V11.3724C1.08203 11.9682 1.56953 12.4557 2.16536 12.4557H10.832C11.4279 12.4557 11.9154 11.9682 11.9154 11.3724V2.70573C11.9154 2.1099 11.4279 1.6224 10.832 1.6224ZM10.2904 11.3724H2.70703C2.40911 11.3724 2.16536 11.1286 2.16536 10.8307V4.33073H10.832V10.8307C10.832 11.1286 10.5883 11.3724 10.2904 11.3724Z"
            fill="#1E795E"
          />
        </g>
        <defs>
          <clipPath id="clip0_1525_458">
            <rect width="13" height="13" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        placeholderText="Select Start Date"
        style={{ width: "6rem" }}
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="9"
        viewBox="0 0 20 9"
        fill="none"
        style={{ color: "#8F8F8F" }}
      >
        <path
          d="M19.5 4.4675C19.5 4.2675 19.42 4.0775 19.28 3.9375L15.56 0.2175C15.27 -0.0725 14.79 -0.0725 14.5 0.2175C14.21 0.5075 14.21 0.9875 14.5 1.2775L16.95 3.7275H0.75C0.34 3.7275 0 4.0675 0 4.4775C0 4.8875 0.34 5.2175 0.75 5.2175H16.94L14.5 7.6575C14.21 7.9475 14.21 8.4275 14.5 8.7175C14.65 8.8675 14.84 8.9375 15.03 8.9375C15.22 8.9375 15.41 8.8675 15.56 8.7175L19.27 5.0075C19.34 4.9375 19.4 4.8475 19.44 4.7475C19.44 4.7375 19.44 4.7275 19.45 4.7175C19.48 4.6375 19.5 4.5575 19.5 4.4675Z"
          fill="#292D32"
          style={{ color: "#8F8F8F" }}
        />
      </svg>
      <DatePicker
        selected={endDate}
        onChange={handleEndDateChange}
        placeholderText="Select End Date"
        minDate={startDate}
        style={{ width: "6rem" }}
      />
    </DateSelectorContainer>
  );
};

export default DateSelector;
