import React from "react";

const getHierarchyDetail = (itemHierarchies, id) => {
    let hierarchyDetail;
    itemHierarchies.forEach(item => {
      if(item.itemHierarchyCode.itemHierarchyTypeCodeId === id){
        hierarchyDetail= {name: item.itemHierarchyCode.description, id: item.itemHierarchyCode.id};
      }
    })
    return hierarchyDetail;
}

export default getHierarchyDetail;