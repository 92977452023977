import { React, useEffect, useState, useRef } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  max-height: 10vh;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: auto;
`;
const TextContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 930px) {
    align-items: flex-start;
  }
`;
const SupportText = styled.p`
  color: rgb(93, 93, 93);
  font-weight: 500;
  font-size: 0.9rem;
`;

const ResolutionDisclaimer = (props) => {
    return (
        <Wrapper>
            <TextContainer>
                {/* <HeadingText>No data found</HeadingText> */}
                <SupportText>Note: This website supports tablet and desktop screen resolution.</SupportText>
            </TextContainer>
        </Wrapper>
    );
 }
 
 export default ResolutionDisclaimer;