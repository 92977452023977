import { React, useEffect, useState } from "react";
import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import Chip from '@mui/material/Chip';
import { FormControlLabel, FormGroup } from "@mui/material";
import { shelfLife, shelfLife2 } from "../../../Constants/Constant";

const FilterWrapper = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
`;
const FilterHeading = styled.p`
  margin-bottom: 0.8rem;
  margin-top: 0.6rem;
  font-size: 1rem;
  font-weight: 600;
`;
const FilterListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .Mui-checked {
    color: #147350 !important;
  }
  .MuiFormControlLabel-root {
	margin: 0;
	&.Mui-disabled {
		opacity: 0.38;
		pointer-events: none;
	}
  }
  .MuiAccordion-root {
	width: 100%;
	&.Mui-expanded {
		margin: .5rem 0;
	}
  }
  .Mui-expanded > .MuiAccordionSummary-root {
	border-bottom: solid #e1e1e1 1px;
  }
  .MuiAccordionSummary-root, .MuiAccordionDetails-root {
	padding: 0 1rem;
  }
  .shelf-life-accordion .MuiAccordion-root .Mui-expanded {
	margin: 1rem 0 0;
  }
  .MuiAccordionDetails-root{
	max-height: 16rem;
	overflow-y: auto;
  }
`;
const SelectedFilterContainer = styled.div`
  height: 100%;
  width: 100%;
  padding: 0;
  max-height: 9rem;
  overflow-y: auto;
  /* margin-bottom: .5rem; */
  margin: 1rem 0 0.5rem;
  .MuiChip-root.MuiChip-outlined {
	margin: .1rem;
	color: #147350;
	border-color: #147350;
	background-color: transparent;
	.MuiChip-deleteIcon {
		color: #147350;
	}
  }
`;

const FilterLayout = (props) => {
  let categoryDetail = props.categoryDetail || [],
  categoryList = props.categoryList || [],
  selectedCategoryName = props.categoryFilters || [],
  selectedShelfLife = props.shelfLifeFilters || [];
	if(categoryDetail.length === 3) {
		categoryList = [categoryDetail[2]];
		selectedCategoryName = [categoryDetail[2]];
	}

  const handleToggle = (value, filterType) => {
	if(filterType === 'categoryName') {
		let newArr = [...selectedCategoryName, value];
		if (selectedCategoryName.includes(value)) {
			newArr = newArr.filter(categoryName => categoryName !== value);
		}
		selectedCategoryName = newArr;
		props.onFilterClick(newArr, selectedShelfLife);
	}
	else if(filterType === 'shelfLife') {
		/* let newArr = [...selectedShelfLife, value];
		if (selectedShelfLife.includes(value)) {
			newArr = newArr.filter(shelfLife => shelfLife !== value);
		} */
		let newArr = [value];
		if(selectedShelfLife.includes(value)) {
			newArr = [];
		}
		selectedShelfLife = [...newArr];
		props.onFilterClick(selectedCategoryName, [...newArr]);
	}
  };

  const handleDelete = (chipToDelete, filterType) => {
	handleToggle(chipToDelete, filterType);
  };

  return (
    <FilterWrapper>
      <Typography fontSize={'1.5rem'} mb={2}>Filter By</Typography>
      <FilterListWrapper>
		{(selectedCategoryName.length > 0 || selectedShelfLife.length > 0) && 
		<SelectedFilterContainer>
			{selectedCategoryName.map((data, i) => {
			return (
				<Chip 
				variant="outlined"
				label={<Typography fontSize={".8rem"}>{data}</Typography>} 
				onDelete={() => handleDelete(data, 'categoryName')}
				disabled={categoryDetail.length === 3}
				/>
			);
			})}
			{selectedShelfLife.map((data, i) => {
			return (
				<Chip 
				variant="outlined"
				label={<Typography fontSize={".8rem"}>{data}</Typography>} 
				onDelete={() => handleDelete(data, 'shelfLife')}
				/>
			);
			})}
		</SelectedFilterContainer>}
        <Accordion
          key="Filter1"
		  defaultExpanded
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography fontWeight={"500"} fontSize={"1.1rem"}>Category</Typography>
          </AccordionSummary>
          <AccordionDetails>
		  <FormGroup>
              {categoryList?.map((categoryName) => {
                const labelId = `checkbox-list-label-${categoryName}`;

                return (
                  <FormControlLabel key={categoryName} disablePadding
                       control = {<Checkbox
                          edge="start"
                          checked={selectedCategoryName.indexOf(categoryName) !== -1}
						  disabled={categoryDetail.length === 3}
						  value={categoryName}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": labelId }}
						/>}
						label={<Typography fontSize={'.9rem'}>{categoryName}</Typography>}
						onChange={() => handleToggle(categoryName, 'categoryName')}
                  />
                );
              })}
			</FormGroup>
          </AccordionDetails>
        </Accordion>
		<Accordion
          key="Filter3"
		  className="shelf-life-accordion"
		  defaultExpanded={selectedShelfLife.length}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
          <Typography fontWeight={"500"} fontSize={"1.1rem"}>Shelf Life</Typography>
          </AccordionSummary>

          <AccordionDetails>
			<FormGroup>
              {shelfLife2.map((shelfLife) => {
                const labelId = `checkbox-list-label-${shelfLife.label}`;

                return (
                  <FormControlLabel key={shelfLife.label} disablePadding
                       control = 
						{<Checkbox
							edge="start"
							checked={selectedShelfLife.indexOf(shelfLife.label) !== -1}
							value={shelfLife.label}
							tabIndex={-1}
							disableRipple
							inputProps={{ "aria-labelledby": labelId }}
						/>}
						label={<Typography fontSize={'.9rem'}>{shelfLife.label}</Typography>}
						onChange={() => handleToggle(shelfLife.label, 'shelfLife')}
                  />
                );
              })}
			</FormGroup>
          </AccordionDetails>
        </Accordion>
      </FilterListWrapper>
    </FilterWrapper>
  );
};
export default FilterLayout;
