import { React, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";
import NoData from "../asset-images/NoData.svg"

const Wrapper = styled.div`
  display: flex;
  height: 50vh;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const ImageContainer = styled.div`
  display: flex;
`;
const TextContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const HeadingText = styled.p`
  display: flex;
  font-weight: 500;
  font-size: 1.5rem;
`;
const SupportText = styled.p`
  color: #A0A0A0;
  font-weight: 500;
  font-size: 1rem;
`;

const NoDataFallback = (props) => {
    return (
        <Wrapper>
            <ImageContainer><img src={NoData}></img></ImageContainer>
            <TextContainer>
                {/* <HeadingText>No data found</HeadingText> */}
                {props.msg?
                <SupportText>{props.msg}</SupportText>:
                <SupportText>This information is not available for a moment</SupportText>}
            </TextContainer>
        </Wrapper>
    );
 }
 
 export default NoDataFallback;