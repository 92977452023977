import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./IntPartnerships.scss";
import Planet from "../../../assets/images/PlannetOutline.png";
import HorizontalSectionMenu from "../../../components/Assets/Common/HorizontalSectionMenu/HorizontalSectionMenu";
import { countryNames, countryUrl } from "../../../Constants/Constant";
import GlobalPresenceChart from "../../../components/Assets/Common/GlobalPresenceChart/GlobalPresenceChart";
import Why_7Eleven from "../../../assets/images/Why_7Eleven.png";
import How_We_Help from "../../../assets/images/How_We_Help.png";
import Critical_Qualification from "../../../assets/images/Critical_Qualifications.png";
import International_Partnership from "../../../assets/images/International_Partnership.png";

const IntPartnerships = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/contact-us");
  };

  useEffect(() => {
    const hash = window.location.hash;
    if(!hash)
      return;
    console.log('hash', hash);
    const elementId = hash.substring(1);
    const element = document.getElementById(elementId);
    if(!element)
      return;
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }, []);


  const sections = [
    { id: "why", label: "WHY 7-ELEVEN" },
    { id: "how", label: "HOW WE HELP" },
    { id: "qualifications", label: "QUALIFICATIONS" },
    { id: "countries", label: "COUNTRIES & REGION" },
  ];

  const contactUsBtn = () => {
    return (
      <div className="contact-us-container" onClick={handleClick}>
        <h5>CONTACT&nbsp;&nbsp;US</h5>
      </div>
    );
  };

  return (
    <div className="partnership-page-wrapper">
      <div className="wallpaper-img-container">
        <img src={International_Partnership} alt="Business Hero" />

        <div className="overlay-text">
          <div>
            <h1>7-Eleven International</h1>
            <h1>Partnerships</h1>
          </div>
          <h2>
            Welcome to the 7-Eleven International Partnerships.  See the
            benefits of investing in the the world’s largest retailer and
            leading convenience store chain. 
          </h2>
          {contactUsBtn()}
          <p id='partnership-info-text'>
            For information on US franchising opportunities, visit <a href="https://franchise.7-eleven.com/franchise/home" target="_blank" style={{ color: 'white', fontFamily: 'inherit'}}>
            US franchsing
            </a>.
          </p>
        </div>
      </div>
      <div className="page-section-header">
        <HorizontalSectionMenu sections={sections} />
      </div>
      <div id="why" className="section-content-why  partnership-layout">
        <h3 className="section-heading">Why 7-Eleven</h3>
        <div className="content-block-wrapper">
          <div className="content-block">
            <p style={{fontFamily: 'Roboto'}}>
              Since 1927, 7-Eleven has made the customer the center of their
              business model.
            </p>
            <ul class="customer-focus-list">
              <li>
                <strong>Brand Strength</strong>: World's largest retailer with
                85,000+ stores worldwide
              </li>
              <li>
                <strong>
                  High quality differentiated fresh foods & private brands
                </strong>
              </li>
              <li>
                <strong>Consumer</strong>: Focused Product Assortment
              </li>
              <li>
                <strong>Close & Convenient</strong>
              </li>
              <li>
                <strong>Extended Hours Of Operation</strong>
              </li>
              <li>
                <strong>Fast, Friendly & Reliable</strong>
              </li>
            </ul>
          </div>
          <div className="content-block">
            <img src={Why_7Eleven} alt="7-eleven img" />
          </div>
        </div>
      </div>
      <div id="how" className="section-content-how partnership-layout">
        <h3 className="section-heading">How We Help Your Business Grow</h3>
        
          <div className="content-block-wrapper">
            <div className="content-block">
              <img src={How_We_Help} alt="7-eleven img" />
            </div>
            <div className="content-block">
              <div>
                <h5>International Development</h5>
                <p>
                  Provides support, processes, tools, and information. We are a
                  true International Licensor.
                </p>
              </div>
              <div>
                <h5>Operations</h5>
                <p>Helps you develop store teams that sell more.</p>
              </div>
              <div>
                <h5>Merchandising</h5>
                <p>Helps you develop products that sell more.</p>
              </div>
              <div>
                <h5>Store Development</h5>
                <p>Helps you develop stores that sell more.</p>
              </div>
              <div>
                <h5>Success Driven</h5>
                <p>
                  Strive to continuously increase Master Franchisee, Licensees,
                  and JV Partner Sales and Profits.
                </p>
              </div>
            </div>
          </div>
          <div className="section-content">
            <h4>How International  Partnerships work with 7-Eleven</h4>
            <div className="content-block-wrapper">
              <div className="content-block">
                <div className="card-content">
                  <h6>
                    The candidate’s capital investment depends on several
                    factors, which include, but are not limited to:
                  </h6>
                  <ul class="customer-focus-list">
                    <li>
                      The candidate’s current business portfolio and what can be
                      leveraged
                    </li>
                    <li>The number of stores to be developed and operated</li>
                    <li>Other market conditions</li>
                  </ul>
                </div>
              </div>
              <div className="content-block">
                <div className="card-content">
                  <h6>
                    Candidates must have the ability and available resources to
                    set up an organization that supports:
                  </h6>
                  <ul class="customer-focus-list">
                    <li>Human Resources/Training</li>
                    <li>Merchandising</li>
                    <li>Operations</li>
                    <li>Logistics</li>
                    <li>Store Development</li>
                    <li>Marketing</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        
      </div>
      <div
        id="qualifications"
        className="section-content-qualifications  partnership-layout"
      >
        <h3 className="section-heading">Critical Qualifications</h3>
        <div className="content-block-wrapper">
          <div className="content-block">
            <img src={Critical_Qualification} alt="7-eleven img" />
          </div>
          <div className="content-block">
            <h5>People and Management Capabilities</h5>
            <h5>Local Real Estate Knowledge</h5>
            <h5>Long-Term Growth Mindset</h5>
            <h5>Local Retail Knowledge and Experience</h5>
            <h5>Good Government Relations</h5>
            <h5>Financial Strength and Stability</h5>
            <h5>Commitment to 7‑Eleven Business Model</h5>
            <h5>Quality Focus</h5>
            <h5>Customer-Obsessed Mindset</h5>
          </div>
        </div>
      </div>
      <div
        id="countries"
        className="section-content-countries partnership-layout"
      >
        <div className="heading-img-wrapper">
          <img src={Planet} alt="planet" />
          <h3 className="section-heading">Franchise INTERNATIONALLY</h3>
        </div>

        <GlobalPresenceChart
          countryNames={countryNames}
          urlList={countryUrl}
        />
      </div>
      <div className="section-content-contactus  partnership-layout">
        <h6>
          Explore new markets and select your preferred country in the contact
          form for master franchising.
        </h6>
        {contactUsBtn()}
      </div>
    </div>
  );
};

export default IntPartnerships;
