import { React, useState } from "react";
import "./Faqs.css";
import LayoutComponent from "../Layout/LayoutComponent";
import { useTranslation } from "react-i18next";

function Faqs() {
  const { t } = useTranslation();
  const [clickFlag, setClickFlag] = useState(null);
  const headingClickHandler = (i) => {
    if (clickFlag === i) {
      return setClickFlag(null);
    }
    setClickFlag(i);
  };
  return (
    <LayoutComponent>
      <div className="faqsContainer">
        <h1>{t("FAQ_About")}</h1>
        <hr></hr>
        <div className="faqsHeading"></div>
        <div className="faqsContent">
          <div className="faqsContentTab">
            <div
              className="faqsTabHeading"
              onClick={() => headingClickHandler(1)}
            >
              <h3>{t("FAQ_Q1")}</h3>
              <span>{clickFlag === 1 ? "-" : "+"}</span>
            </div>
            <div
              className={
                clickFlag === 1 ? "faqsTabContent show" : "faqsTabContent"
              }
            >
              {t("FAQ_A1")}
            </div>
          </div>

          <div className="faqsContentTab">
            <div
              className="faqsTabHeading"
              onClick={() => headingClickHandler(2)}
            >
              <h3>{t("FAQ_Q2")}</h3>
              <span>{clickFlag === 2 ? "-" : "+"}</span>
            </div>
            <div
              className={
                clickFlag === 2 ? "faqsTabContent show" : "faqsTabContent"
              }
            >
              {t("FAQ_A2")}
            </div>
          </div>

          <div className="faqsContentTab">
            <div
              className="faqsTabHeading"
              onClick={() => headingClickHandler(3)}
            >
              <h3>{t("FAQ_Q3")}</h3>
              <span>{clickFlag === 3 ? "-" : "+"}</span>
            </div>
            <div
              className={
                clickFlag === 3 ? "faqsTabContent show" : "faqsTabContent"
              }
            >
              {t("FAQ_A3")}
            </div>
          </div>

          <div className="faqsContentTab">
            <div
              className="faqsTabHeading"
              onClick={() => headingClickHandler(4)}
            >
              <h3>{t("FAQ_Q4")}</h3>
              <span>{clickFlag === 4 ? "-" : "+"}</span>
            </div>
            <div
              className={
                clickFlag === 4 ? "faqsTabContent show" : "faqsTabContent"
              }
            >
              {t("FAQ_A4")}
            </div>
          </div>

          <div className="faqsContentTab">
            <div
              className="faqsTabHeading"
              onClick={() => headingClickHandler(5)}
            >
              <h3>{t("FAQ_Q5")}</h3>
              <span>{clickFlag === 5 ? "-" : "+"}</span>
            </div>
            <div
              className={
                clickFlag === 5 ? "faqsTabContent show" : "faqsTabContent"
              }
            >
              {t("FAQ_A5")}
            </div>
          </div>

          <div className="faqsContentTab">
            <div
              className="faqsTabHeading"
              onClick={() => headingClickHandler(6)}
            >
              <h3>{t("FAQ_Q6")}</h3>
              <span>{clickFlag === 6 ? "-" : "+"}</span>
            </div>
            <div
              className={
                clickFlag === 6 ? "faqsTabContent show" : "faqsTabContent"
              }
            >
              {t("FAQ_A6")}
            </div>
          </div>

          <div className="faqsContentTab">
            <div
              className="faqsTabHeading"
              onClick={() => headingClickHandler(7)}
            >
              <h3>{t("FAQ_Q7")}</h3>
              <span>{clickFlag === 7 ? "-" : "+"}</span>
            </div>
            <div
              className={
                clickFlag === 7 ? "faqsTabContent show" : "faqsTabContent"
              }
            >
              {t("FAQ_A7")}
            </div>
          </div>

          <div className="faqsContentTab">
            <div
              className="faqsTabHeading"
              onClick={() => headingClickHandler(8)}
            >
              <h3>{t("FAQ_Q8")}</h3>
              <span>{clickFlag === 8 ? "-" : "+"}</span>
            </div>
            <div
              className={
                clickFlag === 8 ? "faqsTabContent show" : "faqsTabContent"
              }
            >
              {t("FAQ_A8")}
            </div>
          </div>

          {/* <div className="faqsContentTab">
            <div
              className="faqsTabHeading"
              onClick={() => headingClickHandler(9)}
            >
              <h3>{t("FAQ_Q9")}</h3>
              <span>{clickFlag === 9 ? "-" : "+"}</span>
            </div>
            <div
              className={
                clickFlag === 9 ? "faqsTabContent show" : "faqsTabContent"
              }
            >
              {t("FAQ_A9")}
            </div>
          </div> */}

          <div className="faqsContentTab">
            <div
              className="faqsTabHeading"
              onClick={() => headingClickHandler(10)}
            >
              <h3>{t("FAQ_Q10")}</h3>
              <span>{clickFlag === 10 ? "-" : "+"}</span>
            </div>
            <div
              className={
                clickFlag === 10 ? "faqsTabContent show" : "faqsTabContent"
              }
            >
              {t("FAQ_A10")}
            </div>
          </div>

          <div className="faqsContentTab">
            <div
              className="faqsTabHeading"
              onClick={() => headingClickHandler(11)}
            >
              <h3>{t("FAQ_Q11")}</h3>
              <span>{clickFlag === 11 ? "-" : "+"}</span>
            </div>
            <div
              className={
                clickFlag === 11 ? "faqsTabContent show" : "faqsTabContent"
              }
            >
              {t("FAQ_A11")}
            </div>
          </div>
          <div className="faqsContentTab">
            <div
              className="faqsTabHeading"
              onClick={() => headingClickHandler(12)}
            >
              <h3>{t("FAQ_Q12")}</h3>
              <span>{clickFlag === 12 ? "-" : "+"}</span>
            </div>
            <div
              className={
                clickFlag === 12 ? "faqsTabContent show" : "faqsTabContent"
              }
            >
              {t("FAQ_A12")}{" "}
              <a
                href="https://careers.7-eleven.com/careers/home"
                target="_blank"
                rel="noopener noreferrer"
                className="faqsLink"
              >
                https://careers.7-eleven.com/careers/home
              </a>
              .
            </div>
          </div>
          <div className="faqsContentTab">
            <div
              className="faqsTabHeading"
              onClick={() => headingClickHandler(13)}
            >
              <h3>{t("FAQ_Q13")}</h3>
              <span>{clickFlag === 13 ? "-" : "+"}</span>
            </div>
            <div
              className={
                clickFlag === 13 ? "faqsTabContent show" : "faqsTabContent"
              }
            >
              {t("FAQ_A13a")}{" "}
              <a
                href="https://franchise.7-eleven.com/franchise/home"
                target="_blank"
                rel="noopener noreferrer"
                className="faqsLink"
              >
                https://franchise.7-eleven.com/franchise/home
              </a>{" "}
              {t("FAQ_A13b")}{" "}
              <a
                href="https://7-eleven-intl.com/int-countries"
                target="_blank"
                rel="noopener noreferrer"
                className="faqsLink"
              >
                https://7-eleven-intl.com/int-countries
              </a>{" "}
              {t("FAQ_A13c")}
            </div>
          </div>
        </div>
      </div>
    </LayoutComponent>
  );
}

export default Faqs;
