import { React, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import PBCHeader from "./PBCHeader";
import FilterLayout from "./FilterLayout";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import RemoveIcon from '@mui/icons-material/Remove';
import { addItemToList, addItemsDataToList } from './PBCatalogSlice';
import getItemHierarchy from "../../../Utils/GetItemHierarchy";
import { pb_service } from "../../../config/environment";
import { resValidation } from "../../../Utils/CommonUtils";
import NoDataFallback from "../Common/NoDataFallback";
import CommonErrorFallback from "../Common/CommonErrorFallback";
import axios from "axios";
import DisclaimerUtil from "../Common/DisclaimerUtil";
import LoadingIndicator from "../Common/LoadingIndicator";
import NoImage from "../PrivateBrandCatalog/assets/NoImage.png";
import ResolutionDisclaimer from "../Common/ResolutionDisclaimer";

const Wrapper = styled.div`
  padding: 10rem 5rem 3rem 5rem;
  /* margin-bottom: 2rem; */
  min-height: 80vh;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f7fafc;
  position: relative;

  @media (min-width: 1521px) {
    padding-left: 10vw;
    padding-right: 10vw;
  }
  @media print {
    margin-top: -8rem;
    margin-left: -5rem;
    margin-bottom: 50rem;
  }
`;
const FilterSubCatWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 10rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;
const SubCatLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;
const SubCatDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 3rem;
  height: 100%;
  width: 99%;
`;
const SubCatHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 0.7rem;
`;
const SubCatHeading = styled.div`
  display: flex;
  /* flex-direction: column;
  justify-content: flex-start; */
  align-items: center;
  padding-bottom: 0.5rem;
  margin-left: 0rem;
  font-weight: 500;
  font-size: 1.55rem;
`;
const SeeAllContainer = styled.p`
  color: #147350;
  float: right;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
`;
const SubCatContent = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const FilteredSubCat = styled(SubCatContent)`
  /* flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-start;
  scroll-behavior: smooth;
  gap: 8rem;
  width: 100%; */
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* leaving some space for gap */
  row-gap: 2rem;
  padding-bottom: 2rem;
`;
const ItemDetailContainer = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  .MuiButton-outlined{
    margin-top: 0.5rem;
    color: #34a07c;  
  }
  .add-btn{
    color: #34a07c;
    border: 1px solid #34a07c;
    :hover{
      border: 1px solid #34a07c;
    }
  }
  .rmv-btn{
    color: #EF5350;
    border: 1px solid #EF5350;
    :hover{
      border: 1px solid #EF5350;
    }
    @media print {
    display: none; 
    }
  }

  @media print {
    page-break-inside: avoid;
    break-inside: avoid;
  }
`;
const ItemCard = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  /* -webkit-box-pack: start; */
  justify-content: center;
  /* -webkit-box-align: center; */
  align-items: center;
  /* padding: 0.5rem 1rem; */
  background-color: white;
  /* border: 0.3px solid rgba(144, 144, 144, 0.3); */
  border-radius: 0.25rem;
  /* box-shadow: rgba(135, 134, 134, 0.35) 0px 3px 3px; */
  /* box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px; */
  width: 100%;
  cursor: pointer;
`;
const SubCatCardImg = styled.img`
  max-width: 90%;
  width: auto;
  height: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10% 5%;
`;
const ItemNameContainer = styled.div`
  margin-top: 0.2rem;
  width: 100%;
  min-height: 3rem;
  text-align: left;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
`;
const PdtDetailContainer = styled.div`
  margin-left: 0.75rem;
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
const ItemDetail = styled.div`
  margin: 1rem 0;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
`;
const DetailHeading = styled.span`
  font-weight: 600;
`;
const CardIndexContainer = styled.div`
width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const IndexContainer = styled.div`
  display: none;
  margin-top: 0.5rem;

  @media print {
    display: block; 
    }
`;
const PriceContainer = styled.div`
  display: block;
  @media print {
    display: none; 
    }
`;
const DisclaimerContainer = styled.div`
  width: 100%;
  display: block;
  @media print {
    display: none; 
    }
`;

const pageType = "MyList";

const MyListLayout = (props) => {
  const navigate = useNavigate();
  const [header, setHeader] = useState("");
  const [categoryDetail, setCategoryDetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiFailReported, setApiFailReported] = useState(false);

  const dispatch = useDispatch();
  const { myList = [], myListData = [] } = useSelector(state => state.pbCatalog);

  useEffect(() => {
    setCategoryDetail(["My List"]);
  }, []);

  useEffect(() => {
    fetchListItems();
  }, []);
  
  const fetchListItems = () => {
    let myListValue = myList;
    if (!myListValue.length) {
      let myListFromLocale = localStorage.getItem("pb_my_list");
      if (myListFromLocale) {
        myListValue = JSON.parse(myListFromLocale);
      }
    }
    const encodedFilter= encodeURI(`[{"field": "itemOriginID", "value": [${myListValue}],"operator":"in"}]`);
    setIsLoading(true);
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${pb_service}/api/itemmaster/search?noOfRecords=${myListValue.length}&pageNo=1&status=both&sortBy=itemOriginID&sortDirection=DESC&filters=${encodedFilter}`,
    };
    
    axios.request(config)
    .then((response) => {
      let validatedRes = resValidation(response);
      dispatch(addItemsDataToList(validatedRes.data.data.rows));
      // setMyListProduct(validatedRes.data.data.rows);
      setIsLoading(false);
    })
    .catch((error) => {
      console.log(error);
      setIsLoading(false);
      setApiFailReported(true);
    });

  };

  const handleCardClick = (item) => {
    const [encodedPSA,encodedSubcat,encodedCountry]= getItemHierarchy(item);
    navigate(`/privateBrandCatalog/${encodedCountry}/${encodedPSA}/${encodedSubcat}/${item.itemOriginID}`);
  };

  const handleRemoveClick = (item) => {
    let updatedList = myList.filter(listItem => listItem !== item.itemOriginID),
      updatedDataList = myListData.filter(listItem => listItem.itemOriginID !== item.itemOriginID)
    dispatch(addItemToList(updatedList));
    dispatch(addItemsDataToList(updatedDataList));

  };
  
  return (
    <Wrapper>
		{isLoading && <LoadingIndicator />}
      <PBCHeader
        categoryDetail={categoryDetail}
        header={header}
        pageType={pageType}
        myListProduct={myList.length && !apiFailReported }
      />
      <DisclaimerContainer><DisclaimerUtil></DisclaimerUtil></DisclaimerContainer>
      {apiFailReported ?
        <CommonErrorFallback/> :
        <FilterSubCatWrapper>
          <SubCatLayout>
          {myList.length ?
            <FilteredSubCat>
              {myListData.map((item, index) => {
                return (
                  <ItemDetailContainer
                    key={item.itemOriginID}
                  >
                    <CardIndexContainer>
                      <ItemCard 
                      onClick={() => {
                        handleCardClick(item);
                      }}
                      >
                        <SubCatCardImg
                          alt="img"
                          src={item.imageUrl ? item.imageUrl[0] : NoImage}
                        ></SubCatCardImg>
                      </ItemCard>
                      <IndexContainer>{`${index+1}.`}</IndexContainer>
                    </CardIndexContainer>
                    <PdtDetailContainer>
                      <ItemNameContainer 
                        onClick={() => {
                        handleCardClick(item);
                        }}
                      >{item.shortDescription}</ItemNameContainer>
                      <ItemDetail>
                      <div><span>SLIN: </span>{" "}<span>{item.itemOriginID}</span></div>
                      <PriceContainer><span>Price: </span>{" "}<span>{item.minSrp===item.maxSrp ? `$${item.minSrp?.toFixed(2)}` : `$${item.minSrp?.toFixed(2)} - ${item.maxSrp?.toFixed(2)}`}</span></PriceContainer>
                      </ItemDetail>
                      <Button 
                      variant="outlined" startIcon={<RemoveIcon/>} size="small" className="rmv-btn" onClick={()=>{handleRemoveClick(item)}}
                      >
                        Remove
                      </Button>
                    </PdtDetailContainer>
                  </ItemDetailContainer>
                );
              })}
            </FilteredSubCat>
            : <div style={{display: "flex", justifyContent: "center", width: "100%"}}><NoDataFallback msg={"Nothing to see here. The list is empty."}/></div>
          }
          </SubCatLayout>
        </FilterSubCatWrapper>
      }
      <DisclaimerContainer><ResolutionDisclaimer /></DisclaimerContainer>
    </Wrapper>
  );
};
export default MyListLayout;
