import React from "react";
import "./CustomModal.scss";
import { AiFillCloseCircle } from "react-icons/ai";

const CustomModal = ({show, onClose, heading, children }) => {

  return (
    <div className={`custom-modal-wrapper ${show ? "show-modal" : ""}`}>
      <div className="custom-modal-body">
        <div className="custom-modal-header">
          <h2>{heading}</h2>
          <AiFillCloseCircle
            className="close-icon"
            onClick={() => onClose()}
          />
        </div>
        <div className="custom-modal-form">
          {children}
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
