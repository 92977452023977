import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bmList: JSON.parse(localStorage.getItem('bm_my_list')) || [], 
  bmListData: [],
};

const BMCollectionSlice = createSlice({
  name: 'bmCollection',
  initialState,
  reducers: {
    addItemToBMList(state, action) {
      const { id, count } = action.payload;
      const existingItemIndex = state.bmList.findIndex(item => item.id === id);

      if (existingItemIndex !== -1) {
        if (count === 0) {
          state.bmList.splice(existingItemIndex, 1);
        } else {
          state.bmList[existingItemIndex].count = count;
        }
      } else if (count > 0) {
        state.bmList.push({ id, count });
      }
      localStorage.setItem('bm_my_list', JSON.stringify(state.bmList));
    },
    addItemsDataToBMList(state, action) {
      state.bmListData = action.payload;
    },
  },
});

export const { addItemToBMList, addItemsDataToBMList } = BMCollectionSlice.actions;
export default BMCollectionSlice.reducer;
