import React, { useState, useCallback, useEffect } from 'react';
import './UserManagement.scss';
import styled from 'styled-components';
import CustomConfirmationPopup from '../Common/CustomConfirmationPopup';
import { getUserList, deleteUser } from '../../../service/userManagementService';
import { useNavigate, useLocation } from 'react-router-dom';
import {
	DataGrid,
	GridActionsCellItem,
} from '@mui/x-data-grid';
import {
	Add,
	Edit,
	Delete,
	Search
} from '@mui/icons-material';
import LoadingIndicator from '../Common/LoadingIndicator';
import { 
	InputAdornment,
	MenuItem,
	Pagination,
	Select,
	Stack,
	TextField,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { setUserList } from './UserManagementSlice';
import { useSnackbar } from 'notistack';
import { userManagementMsgs } from '../../../Constants/Constant';
import { checkExpiryAndRedirect } from '../../../Utils/CommonUtils';
import { getGridStringOperators } from '@mui/x-data-grid';
import debounce from 'lodash/debounce';


const Wrapper = styled.div`
	padding: 11rem 5rem 6rem 5rem;
	//min-height: 80vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background-color: #f7fafc;
	position: relative;

	/* @media (min-width: 1521px) {
		padding-left: 10vw;
		padding-right: 10vw;
	}
	@media (max-width: 770px) {
		padding: 10rem 2rem 4rem 2rem;
	} */
`;
// const Placeholder = styled.div`
// 	height: 600px;
// 	display: flex;
// 	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
// 	justify-content: center;
// 	align-items: center;
// 	font-size: 30px;
// 	color: #666;
// `;
const rowsPerPageCount = [10, 25, 50, 75, 100],
	globalSearchColumns = ["fullName", "userName", "country", "company", "userAbout", "userEmail", "accessControl"],
	globalSearchOperator = "contains",
	globalSearchPrimaryOperator = "or";
let filterObj = null;

const UserManagement = () => {
	const [rowData, setRowData] = useState([]);
	const [totalUsers, setTotalUsers] = useState(0); 
	const [page, setPage] = useState(1);
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [userToDelete, setUserToDelete] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageCount[0]);
	const [searchQuery, setSearchQuery] = useState('');
	const [filterModel, setFilterModel] = useState({ items: [] });
	const [sortModel, setSortModel] = useState({ items: [] });
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {state = {}} = useLocation();
	
	const { enqueueSnackbar} = useSnackbar();
	

	useEffect(() => {
		filterObj ? fetchUserList(rowsPerPage, page, filterObj) : fetchUserList(rowsPerPage, page);	
	}, [page, rowsPerPage,state,filterObj]);

	useEffect(() => {
		filterObj = state?.filterObj || {};
		if (state) {
			const { rowsPerPage,page,filterObj = {}} = state;
			setPage(page);
			setRowsPerPage(rowsPerPage);
			if (filterObj) {
				if(filterObj.filter){
				const filterColumns = filterObj.filter.filterArray;
				const filterOption = filterColumns[0];
				const searchTerm = filterOption.value;
	
				if (filterColumns.length === 1) {
					const keyName = filterOption.keyName,
						operator = filterOption.operator;
						
	
					setFilterModel({
						items: [
							{ field: keyName, operator: operator, value: searchTerm },
						],
						logicOperator: "and",
						quickFilterLogicOperator: "and",
						quickFilterValues: []
					});
					
				
				} else {
					setSearchQuery(searchTerm);
				}
			}
			if(filterObj.sort){
				const sort = filterObj.sort.sort,
					  keyName= filterObj.sort.field;
				setSortModel(
					{
						items: [
							{ field: keyName, sort: sort },
						]
					}
					
				);
			}	
			
		} 
			fetchUserList(rowsPerPage, page, filterObj);
			
		}
		window.history.replaceState({}, '');
		return () => {
			filterObj = null;
		};
	},[]);


	const debounced = useCallback (
		debounce((rowsPerPage, page,obj) => {
			fetchUserList(rowsPerPage, page,obj);
		}, 400),
	[]);

	const fetchUserList = async (newRowsPerPage, newPage, obj) => {
		let response;
		try {
			const isTokenActive = checkExpiryAndRedirect();
			if (!isTokenActive) {
				return;
			}
			setIsLoading(true);
			if (obj) {
				response = await getUserList({ rowsPerPage: newRowsPerPage, page:newPage, obj });
			} else{
				response = await getUserList({ rowsPerPage:newRowsPerPage, page:newPage });
			}
			response.users.forEach(row => {
				const viewKeys = Object.keys(row.accessControl).filter(key => row.accessControl[key].view) || [];
				row.accessString = viewKeys;
			});
			setRowData(response.users);
			dispatch(setUserList(response.users));
			setTotalUsers(response.totalUsersCount);
			setIsLoading(false);
		} catch (error) {
			console.error('Error fetching user list:', error);
			if (error.code !== "ERR_CANCELED") {
				setIsLoading(false);
			}
		}
	};
	
	const handlePageChange = (e, newPage) => {
		setPage(newPage);
		// localStorage.setItem('page', newPage);
	};
	
	const handleEditClick = (userId) => () => {
		const userObj = rowData.filter(user => user.userEmail === userId)[0];
		navigate(`/userManagement/add-edit-user`,{state:{userObj, rowsPerPage, page, filterObj}});
	};

	const handleDeleteClick = (id) => () => {
		const userToBeDeleted = rowData.filter(user => user.userEmail === id)[0];
		setUserToDelete(userToBeDeleted);
		setDeleteDialogOpen(true);
	};

	const navigateAddEditUser = () => {
		navigate('/userManagement/add-edit-user',{ state: { rowsPerPage, page, filterObj} });
	};

	const displayToastMsg = (toastObj) => {
		enqueueSnackbar(toastObj.message, {
			variant: toastObj.variant,
			preventDuplicate: true,
			anchorOrigin: {
				vertical: "top",
				horizontal: "right",
			},
		});
	};

	const handleConfirmDelete = async () => {
		try {
			setIsLoading(true);
			setDeleteDialogOpen(false);
			deleteUser({ emailId: userToDelete.userEmail, userName: userToDelete.cognitoUserName }).
				then(() => {
					displayToastMsg({ message: userManagementMsgs.deleteUserSuccess, variant: "success" });
					 fetchUserList( rowsPerPage, page, filterObj);
				})
				.catch(err => {
					displayToastMsg({ message: userManagementMsgs.deleteUserFailure, variant: "error" });
					setIsLoading(false);
				});
		} catch (error) {
			console.error('Error deleting user:', error);
		};
	};

	const handleCancelDelete = () => {
		setDeleteDialogOpen(false);
		setUserToDelete(null);
	};

	const statusCellRenderer = useCallback(props => {
		const userState = props.value ? 'Active' : 'Inactive';
		return (
			<span className={`user-status ${userState}`}>
				{ userState }
			</span>
		);
	}, []);	

	const handleChange = (event) => {
		const newRowsPerPage = event.target.value;
		setRowsPerPage(newRowsPerPage);
		setPage(1);
		// localStorage.setItem('rowsPerPage', newRowsPerPage);
	};

	const handleFilterChange = async (filModel, details) => {
		setFilterModel(filModel);
		if (details.reason === undefined) {
			return;
		}
		setSearchQuery("");
		setPage(1)
		const filterArray = filModel.items
			.filter(item => item.value) 
			.map((item) => ({
				keyName: item.field,
				value: item.value,
				operator: item.operator 
			}));

			if (filterArray.length === 0) {
				filterObj = null;
				fetchUserList(rowsPerPage,page);
				return; 
			}

		filterObj = {
			filter: {
				//primaryOperator: 'and',
				filterArray 
			}
		};

		// debounced( rowsPerPage,1,filterObj ); 
	};

	const handleSortChange = async (sortModel) => {
		const sortOptions = sortModel[0];
		let obj = filterObj ? filterObj : {};
		obj.sort = sortOptions;
		filterObj = obj;
		fetchUserList(rowsPerPage, page,filterObj );
		setSortModel({
			items: sortModel
		});
		/* if (page === 1) {
			fetchUserList(filterObj, rowsPerPage, page)
		} else {
			setPage(1);
		} */
	};

	const filterOperators = getGridStringOperators().filter(
		(operator) => operator.value === 'contains' || operator.value === 'equals'
	);

	const colDef = [
		{ headerName: 'FULL NAME (ENGLISH NAME)', field: 'fullName', filter: true, flex: 0.5, minWidth: 200, hideable: false, filterOperators},
		{ headerName: 'EMAIL ID', field: 'userEmail', filter: true, flex: 1, minWidth: 200, hideable: false, filterOperators },
		{ headerName: 'COUNTRY / REGION', field: 'country', filter: true, flex: 0.5, minWidth: 100, hideable: false, filterOperators},
		{ headerName: 'COMPANY', field: 'company', filter: true, flex: 0.5, minWidth: 100, hideable: false, filterOperators},
		{ headerName: 'TITLE', field: 'userAbout', filter: true, flex: 0.5, minWidth: 100, hideable: false, filterOperators},
		{ headerName: 'ACCESS CONTROLS', field: 'accessString', flex: 1, minWidth: 200, disableColumnMenu: true, sortable: false },
		{ headerName: 'STATUS', field: 'enabled', renderCell: statusCellRenderer, flex: 0.5, minWidth: 100, disableColumnMenu: true, sortable: false},
		{
			field: 'actions',
			headerName: 'ACTIONS',
			width: 100,
			type: 'actions',
			align: 'left',
			hideable:false,
			disableColumnMenu: true,
			sortable: false,
			getActions: ({ id, row = {} }) => {
				const actionsCell = [
					<GridActionsCellItem
						icon={<Edit/>}
						label="Edit"
						onClick={handleEditClick(id)}
						color="inherit"
					/>
				];
				if (!row.isAdmin) {
					actionsCell.push(<GridActionsCellItem
						icon={<Delete />}
						label="Delete"
						onClick={handleDeleteClick(id)}
						color="inherit"
					/>);
				}
				return actionsCell;
			}
		}
	];

	const getRowId = (row) => row.userEmail;

	const handleGlobalSearch = (e) => {
		const searchTerm = e.target.value;
		setSearchQuery(searchTerm);
		setPage(1);
		setFilterModel({ items: [] });
		setSortModel({ items: [] });

		if (!searchTerm) {
			filterObj = null;
			fetchUserList(rowsPerPage,page,filterObj);
			return;
		}
		filterObj = {
			primaryOperator: globalSearchPrimaryOperator,
			filterArray: []
		};

		/* filterObj = filterObj ? filterObj : {};
		filterObj.primaryOperator = globalSearchPrimaryOperator;
		filterObj.filterArray = []; */

		globalSearchColumns.map(column => {
			filterObj.filterArray.push({
				keyName: column,
				value: searchTerm,
				operator: globalSearchOperator
			})
		});

		filterObj = {
			filter: filterObj	
		};

		debounced( rowsPerPage,page ,filterObj );
	};

	return (
		<>
			<Wrapper className='user-management-container'>
				<div className='user-management-header'>
					<div>
						<h2>User Management</h2>
					</div>
					<div className='btns-container'>
						<div className='usermanagement-search'>
							<Search style={{color: "#5a5a5a"}} />
							<input
								type="search"
								placeholder='Search'
								value={searchQuery}
								onChange={handleGlobalSearch}
							/>
						</div>
						<button
							className='add-user-btn'
							color="primary"
							startIcon={<Add />}
							onClick={navigateAddEditUser}
						>
							+ ADD USER
						</button>
					{/* <TextField
						label="Search"
						variant="standard"
						// value={quickFilterText}
						// onChange={handleSearchInputChange}
						size='small'
						InputProps={{
							endAdornment: (
							<InputAdornment position="end">
								<SearchIcon />
							</InputAdornment>
							),
						}}
						//style={{ width: '0%', height: '20px', marginBottom: '40px'}}
					/> */}
					</div>
				</div>
				{isLoading && <LoadingIndicator enableBackdrop={true}/>}
				<div className="ag-theme-quartz" id="myGrid">
					<DataGrid
						rows={rowData}
						columns={colDef}
						getRowId={getRowId}
						hideFooter={true}
						localeText={
							{
								columnMenuSortAsc: 'Sort By A-Z',
								columnMenuSortDesc: 'Sort By Z-A',
								filterPanelInputLabel: 'Search',
								filterPanelInputPlaceholder: '',
								filterPanelOperator: '',
								filterPanelColumns: '' 
							}
						}
						style={{ height: '95%', width: '100%' }}
						onFilterModelChange={handleFilterChange}
						onSortModelChange={handleSortChange}
						disableColumnSelector
						filterModel={filterModel}
						sortModel={sortModel.items}
						filterMode='server'
						sortingMode='server'
					/>
					<Stack spacing={2} direction="row" justifyContent={"space-between"} alignItems={"center"}>
						<div className='users-count'>
							Total number of users: {totalUsers}
						</div>
						<div className='pagination-container'>
							<div>
								<span>Rows per page </span>
								<Select
									id="rows-per-page"
									value={rowsPerPage}
									onChange={handleChange}
									size='small'
									variant='standard'
									color= "success" 
								>
									{rowsPerPageCount.map((count, index) => (
										<MenuItem key={index} value={count}>
											{count}
										</MenuItem>
									))}
								</Select>
							</div>
							<Pagination
								sx={{ justifyContent: "flex-end", marginTop: '1rem', alignItems: 'center' }}
								count={Math.ceil(totalUsers/rowsPerPage)}
								page={page}
								onChange={handlePageChange}
								size="small"
							/>
						</div>
					</Stack>
				</div>
			</Wrapper>
			{
				deleteDialogOpen && 
					<CustomConfirmationPopup
						isVisible={deleteDialogOpen}
						content = {{
							title: `Are you sure ?`,
							message: `Do you really want to delete ${userToDelete?.fullName} ?`,
							additionalMsg: `This Process cannot be undone.`,
						}}
						closeDialog={handleCancelDelete}
						buttons={[
							{ text: "Cancel", onClick: handleCancelDelete, variant: 'secondary'},
							{ text: "Delete", onClick: handleConfirmDelete, variant: 'primary'},
						]}
					/>
				}

		</>

	);
};

export default UserManagement;