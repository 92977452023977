import React from "react";
import getCountryName from "./GetCountryName";

const getItemHierarchy = (item) => {
    let country = getCountryName(item.country);
    let psa = null,
    subCat = null;

    /* psa: item.itemHierarchies[0].itemHierarchyCode.description,
    subCat: item.itemHierarchies[1].itemHierarchyCode.description, */
    let itemHierarchies = item.itemHierarchies;
    itemHierarchies.forEach(hierarchy => {
      const hierarchyCodeType = hierarchy.itemHierarchyCode.itemHierarchyTypeCodeId;
      if (hierarchyCodeType === 1) {
        psa = hierarchy.itemHierarchyCode.description;
      } else if (hierarchyCodeType === 2) {
        subCat = hierarchy.itemHierarchyCode.description;
      }
    });
    const encodedPSA = encodeURIComponent(psa);
    const encodedSubcat = encodeURIComponent(subCat);
    const encodedCountry = encodeURIComponent(country);
    return [encodedPSA,encodedSubcat,encodedCountry];
}

export default getItemHierarchy;