import { React, useEffect, useState } from "react";
import styled from "styled-components";
import PendingItem from "./PendingItem";
import "./PendingPosts.css";
import {
  CircularRotatingLines,
  SkeletonPendingLoader,
} from "../../../Utils/CircularLoader";
import { BsArrowBarLeft, BsArrowBarRight } from "react-icons/bs";
import { fetch_post } from "../../../config/environment";

var qs = require("qs");
var axios = require("axios");

const ArrowDiv = styled.div`
  scale: 1.5;
  padding: 3px 0 0 0;
  cursor: pointer;
  @media (min-width: 1100px) {
    display: none !important;
  }
`;
const PendingPostDiv = styled.div`
  @media (max-width: 1100px) {
    position: relative;
    left: 6.5rem;
    padding: 2rem;
    background-color: white;
    border: 1px solid #e0dede;
    border-radius: 10px;
  }
  @media (max-width: 770px) {
    padding: 2rem 3rem 2rem 1rem;
  }
  @media (max-width: 545px) {
    padding: 2rem 4rem 2rem 1rem;
  }
`;

const Wrapper = styled.div`
  margin-bottom: 1rem;
  margin-right: 1rem;
  padding: 0rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 20rem;
  max-height: 30rem;
  background: #ffffff;
  border: 0.5px solid #bebebe;
  border-radius: 0.5rem;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.08);
  @media (max-width: 1350px) {
    width: 17rem;
  }
  @media (max-width: 1200px) {
    width: 14rem;
  }
  @media (max-width: 1100px) {
    display: ${(props) => (props.postBarOpen ? "" : "none !important")};
    margin-right: ${(props) => (props.postBarOpen ? "-13rem" : "1rem")};
  }
`;
const HeadingContainer = styled.div`
  background: #f2f2f2;
  box-shadow: 0px 2px 2px rgba(135, 134, 134, 0.25);
  border-radius: 10px 10px 0px 0px;
  width: 100%;
`;
const Heading = styled.h5`
  font-size: inherit;
  padding: 0.5rem 1rem;
  width: 100%;
`;
const PendingPostContainer = styled.div`
  overflow-y: scroll;
  width: 100%;
`;

const PendingPost = (props) => {
  const checkAccessToken = localStorage.getItem("access_token");
  // const fetch_post = process.env.REACT_APP_FETCH_POST;
  const [posts, setPosts] = useState(props.posts);
  const [isfetching, setIsFetching] = useState(false);
  const [postBarOpen, setPostBarOpen] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [approveAction, setApproveAction] = useState(false);

  const permission = localStorage.getItem("userpermission");

  const togglePostBar = () => {
    setPostBarOpen(!postBarOpen);
  };

  const handleApproveActionReload = () => {
    props.handleActionReload();
    setApproveAction(!approveAction);
  };

  var data = qs.stringify({
    accessToken: checkAccessToken,
  });

  var config = {
    method: "post",
    url: fetch_post,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  useEffect(() => {
    if(checkAccessToken) {
      approvePost();
    }
  }, [props.reloadComponent, approveAction]);

  useEffect(() => {
    setPosts(props.posts);
  }, [props.posts]);

  const approvePost = () => {
    setIsFetching(true);
    setShowLoading(true);
    axios(config)
      .then(function (response) {
        const res = response.data.body.message.adminPosts;
        return res;
      })
      .then(function (res) {
        setPosts(res);
        setIsFetching(false);
      })
      .catch(function (error) {});
    setShowLoading(false);
  };

  return (
    <PendingPostDiv>
      <Wrapper postBarOpen={postBarOpen}>
        <HeadingContainer>
          <Heading>Pending Approvals</Heading>
        </HeadingContainer>
        <PendingPostContainer>
          {(props.gettingBucket && !props.refresh) ||
          props.isLoading ||
          showLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <SkeletonPendingLoader />
            </div>
          ) : !isfetching && posts && posts.length === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "1rem 0 1rem 0",
              }}
            >
              - No Posts -
            </div>
          ) : (
            posts.map((item, i) => {
              return (
                <PendingItem
                  setCallFetch={props.setCallFetch}
                  item={item}
                  handleApproveActionReload={handleApproveActionReload}
                  key={`dPendingPosts_${item._id}`}
                  setProfileClicked={props.setProfileClicked}
                  setUserProfile={props.setUserProfile}
                />
              );
            })
          )}
        </PendingPostContainer>
      </Wrapper>
      <ArrowDiv onClick={togglePostBar}>
        <BsArrowBarLeft />
      </ArrowDiv>
      <div className={`sidebar ${postBarOpen ? "active" : ""}`}>
        <div className="sd-header">
          <h4 className="mb-0"></h4>
          <div
            className="btn btn-primary"
            onClick={togglePostBar}
            style={{
              background: "#f2f2f2",
              padding: "0.5rem 1rem 0.5rem 1rem",
              borderRadius: "5px",
            }}
          >
            <BsArrowBarRight />
          </div>
        </div>
        <div className="sd-body">
          <Wrapper postBarOpen={postBarOpen}>
            <HeadingContainer>
              <Heading>Pending Approvals</Heading>
            </HeadingContainer>
            <PendingPostContainer>
              {(props.gettingBucket && !props.refresh) ||
              props.isLoading ||
              showLoading ? (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <SkeletonPendingLoader />
                </div>
              ) : !isfetching && posts && posts.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "1rem 0 1rem 0",
                  }}
                >
                  - No Posts -
                </div>
              ) : (
                posts.map((item, i) => {
                  return (
                    <PendingItem
                      setCallFetch={props.setCallFetch}
                      item={item}
                      handleApproveActionReload={handleApproveActionReload}
                      key={`mPendingPosts_${item._id}`}
                      setProfileClicked={props.setProfileClicked}
                      setUserProfile={props.setUserProfile}
                    />
                  );
                })
              )}
            </PendingPostContainer>
          </Wrapper>
        </div>
      </div>
      <div
        className={`sidebar-overlay ${postBarOpen ? "active" : ""}`}
        onClick={togglePostBar}
      ></div>
    </PendingPostDiv>
  );
};

export default PendingPost;
