import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Avatar } from "@mui/material";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import FileViewer from "react-file-viewer";
import { formatDistanceToNowStrict } from "date-fns";
import CircularRotatingLines from "../../../Utils/CircularLoader";
import { GoKebabHorizontal } from "react-icons/go";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { MdFullscreen, MdFullscreenExit } from "react-icons/md";
import { delete_post } from "../../../config/environment";
import { useSelector } from "react-redux";

const BlogDiv = styled.div`
  color: black;
  background-color: white;
  margin-bottom: 1.8rem;
  padding: 2rem 2rem 2rem 2rem;
  border-radius: 0.5rem;
  border: 1px solid #e0dede;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.05);
`;
const AvatarPostWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const ModalAvatarPostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const PostDetailComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem;
  width: 90%;
  height: auto;
`;
const UserDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.4rem;
`;
const PostContentComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;
const PostTextComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1rem;
  padding-right: 0.7rem;
  margin-bottom: 1rem;
  word-break: break-word;
  white-space: pre-line;
`;
const PostImageComponent = styled.div`
  width: 100%;
  padding-right: 0.7rem;
  max-height: 28rem;
  @media (max-width: 1000px) {
    width: 90%;
  }
`;
const PostFileComponent = styled(PostImageComponent)`
  width: 100.5%;
  margin-bottom: 0.2rem;

  ${FileViewer}.pg-viewer-wrapper {
    width: 95%;
    height: 95%;
    border: 1px solid rgb(241, 238, 238);
    border-radius: 0.4rem;
    padding: 0.6rem;
    display: flex;
    justify-content: center;
  }
  ${FileViewer}.pg-viewer {
    width: 100%;
  }
  ${FileViewer}.document-container {
    width: 95%;
    height: 95%;
  }
  ${FileViewer}.pdf-canvas {
    margin-top: 0.2rem;
    width: 95%;
    height: 95%;
    display: flex;
    justify-content: center;
    top: 2rem;
    position: relative;
  }
  ${FileViewer}.pdf-viewer {
    align-items: center;
  }
  ${FileViewer}.rdg-selected {
    display: ${(props) => (props.fileType === "xlsx" ? "none" : "auto")};
  }
`;
const ReadMoreSpan = styled.div`
  display: inline-block;
  color: #215bbe;
  cursor: pointer;
`;
const NameContainer = styled.div`
  height: 25%;
  cursor: pointer;
`;
const UserName = styled.p`
  font-size: 1.3rem;
  letter-spacing: 0px;
  font-weight: bold;
  opacity: 0.75;
  margin-top: -2px;
`;
const CompanyName = styled.p`
  font-size: 1rem;
  letter-spacing: 0px;
  opacity: 0.75;
  margin-top: -7px;
  margin-bottom: 5px;
`;
const DateContainer = styled.div`
  height: 20%;
`;
const PostDate = styled.p`
  font-size: 0.9rem;
  color: #6f6f6f;
`;
const PostPreview1stRow = styled.div`
  width: 100%;
  height: 17rem;
  cursor: pointer;
`;
const PostPreview2ndRow = styled.div`
  width: 100%;
  height: 10rem;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;
const PostPreview2ndRowEl = styled.div`
  width: 49.5%;
  height: 9.2rem;
  position: relative;
`;
const PostFile2ndRowEl = styled(PostPreview2ndRowEl)`
  width: 48%;
  margin-bottom: 1.7rem;

  ${FileViewer}.pg-viewer-wrapper {
    width: 86%;
    height: 95%;
    border: 1px solid rgb(241, 238, 238);
    border-radius: 0.4rem;
    padding: 1rem;
    padding-bottom: 0rem;
  }
  ${FileViewer}.document-container {
    width: 95%;
    height: 95%;
  }
  ${FileViewer}.pdf-canvas {
    width: 95%;
    height: 95%;
  }
`;
const ExtraPreviewFile = styled(PostFileComponent)`
  margin-bottom: -0.15rem;
  max-height: 35rem;
  ${FileViewer}.pg-viewer-wrapper {
    border: none;
  }
  ${FileViewer}.pdf-viewer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  ${FileViewer}.pdf-canvas {
    width: 100%;
  }
`;
const PostNumBackdrop = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
  color: white;
  font-size: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const PreviewVid = styled.video`
  width: 100%;
  height: inherit;
`;
const PreviewVidLast = styled(PreviewVid)`
  position: absolute;
`;
const UserDetailsWrapper = styled.div`
  margin-top: 0.2rem;
`;
const PostOptionMenu = styled.div`
  margin-top: 0.2rem;
  cursor: pointer;
`;
const HeadingContainer = styled.div`
  margin-bottom: 1rem;
  font-weight: bold;
  color: #595555;
`;
const ModalTextInput = styled.textarea`
  width: 95%;
  border: ${(props) =>
    props.wrongInputMsg ? "1px solid #ea1b2c" : "1px solid #b5b5b5"};
  border-radius: 8px;
  padding: 0.8rem;
  resize: none;
  max-height: 100%;
  min-height: 7rem;
  color: grey;
  height: 7rem;
  &: focus-visible {
    outline-width: 0;
  }
`;
const ConfirmMsgBtn = styled.button`
  width: 20%;
  padding: 0.2rem;
  margin-right: 0.3rem;
  height: 1.9rem;
  border: none;
  border-radius: 2rem;
  font-size: small;
  background-color: ${(props) =>
    props.disabled ? "rgb(220 217 217)" : "#147350"};
  color: ${(props) => (props.disabled ? "grey" : "white")};
  cursor: pointer;
  margin: 0.5rem 1rem;
  margin-right: 0.2rem;
  position: relative;
  text-align: center;
  float: right;
`;
const ModalWrapper = styled.div`
  position: fixed;
  top: 0rem;
  left: 0rem;
  right: 0;
  bottom: 0;
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
`;
const ModalBody = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 0px 16px;
  padding-top: 2px;
  width: 40rem;
  max-height: 30rem;
  height: ${(props) => (props.imageAvailable ? "30rem" : "auto")};
  overflow-y: scroll;
  top: 20rem;
  position: absolute;
`;
const RejectModalBody = styled(ModalBody)`
  width: 38rem;
  top: 20rem;
`;
const DeleteModalBody = styled(ModalBody)`
  max-width: 30rem;
  width: 80%;
  overflow-x: hidden;
  top: 50%;
  transform: translateY(-50%);
`;
const ModalWrapperEP = styled.div`
  position: fixed;
  top: 0rem;
  left: 0rem;
  right: 0;
  bottom: 0;
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
`;
const ModalBodyEP = styled(RejectModalBody)`
  background-color: rgb(1, 1, 1);
  border-radius: 0px;
  padding: 0px;
  width: 70%;
  max-width: 42rem;
  max-height: 35rem;
  height: auto;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  overflow-y: hidden;
`;
const CloseBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const ModalContainerEP = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
  background-color: black;
  overflow-y: scroll;
  height: auto;
  max-height: 30rem;
`;
const ExtraPreviewContainer = styled(PostPreview1stRow)`
  width: 80%;
  height: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  position: relative;
  padding: 0 16px;
`;
const ArrowContainer = styled.div`
  position: absolute;
  top: 48%;
  display: flex;
  justify-content: space-between;
  width: 93%;
  z-index: 1;
`;
const ArrowIcon = styled.div`
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: space-between;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
`;
const PreviewImg = styled.img`
  width: 100%;
  height: inherit;
  object-fit: cover;
`;
const CustomBtn = styled.button`
  width: 2.8rem;
  padding: 0.1rem 0rem;
  height: 1.2rem;
  border-radius: 2rem;
  font-size: 0.6rem;
  cursor: pointer;
  margin: 1.7rem 0.5rem;
  margin-right: 0;
  position: relative;
  text-align: center;
`;
const LogoutBtn = styled(CustomBtn)`
  font-size: 15px;
  width: 6rem;
  height: 2.5rem;
  border: 1px solid #d2d2d2;
  background-color: #f6f6f6;
  color: black;
  box-shadow: 0 5px 5px -5px rgba(33, 33, 33, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
`;
const CustomFSBtn = styled.button`
  position: sticky;
  float: right;
  bottom: 0;
  background-color: rgb(83 82 82 / 34%);
  color: white;
  z-index: 2;
  border-radius: 50%;
  border-style: none;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
    scale: 1.2;
  }
`;

var axios = require("axios");
var qs = require("qs");

const NewsFeedItem = ({
  postItem,
  setProfileClicked = () => {},
  setUserProfile = () => {},
  handleDeleteReload = () => {},
}) => {
  const [readMore, setReadMore] = useState(true);
  const [showPost, setShowPost] = useState(false);
  const [ExtraPreviewModal, setExtraPreviewModal] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(2);
  const [apiCalled, setApiCalled] = useState(false);
  const [postOption, setPostOption] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [deleteSure, setDeleteSure] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const videoRef = useRef(null);
  // const delete_post = process.env.REACT_APP_DELETE_POST;
  const { isAdmin=false } = useSelector(state => state.userManagement);
  const filePreviewRef = useRef(null);

  const DialogPopup = (props) => {
    return (
      <Dialog
        open={deleteSure}
        onClose={() => {
          setDeleteSure(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Are you sure?</DialogTitle>
        <DialogContent
          sx={{
            padding: "0px 24px",
          }}
        >
          <DialogContentText id="alert-dialog-description">
            Do you really want to delete this post? This Process cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "0px 8px 8px 8px",
          }}
        >
          <LogoutBtn
            style={{
              backgroundColor: "#b6b4b4",
              color: "white",
            }}
            onClick={() => {
              setDeleteSure(false);
            }}
          >
            Cancel
          </LogoutBtn>
          <LogoutBtn
            style={{
              backgroundColor: "#147350",
              color: "white",
            }}
            onClick={() => {
              setDeleteModal(true);
              setDeleteSure(false);
            }}
          >
            Delete
          </LogoutBtn>
        </DialogActions>
      </Dialog>
    );
  };

  const recordButtonPosition = (event) => {
    setMenuOpen(event.currentTarget);
    setPostOption(!postOption);
  };

  const readMoreHandler = () => {
    setReadMore((readMore) => !readMore);
  };

  const togglePostOptions = () => {
    setPostOption(!postOption);
  };

  const deletePost = (deletedPost) => {
    setApiCalled(true);
    var data = qs.stringify(deletedPost);
    var config = {
      method: "post",
      url: delete_post,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        // setTimeout(() => {
        //   document.location.reload();
        // }, 500);
        handleDeleteReload();
        setApiCalled(false);
      })
      .catch(function (error) {
        if (error?.response?.status === 505) {
          handleDeleteReload();
        }
        setApiCalled(false);
      });
  };

  const options = ["Delete Post"];

  const UserNameProcessing = (username) => {
    username = username.trim();
    let wordsArray;
    if (username.includes(" ")) {
      wordsArray = username?.split(" ");
    } else {
      wordsArray = [username];
    }
    let outputString = "";
    for (let i = 0; i < wordsArray.length; i++) {
      let userNamePart =
        wordsArray[i][0].toUpperCase() + wordsArray[i].slice(1).toLowerCase();
      outputString = outputString + " " + userNamePart;
    }
    return outputString;
  };

  const goToNextFile = () => {
    setCurrentFileIndex(
      (prevIndex) => (prevIndex + 1) % postItem.postFileKeys.length
    );
  };

  const goToPrevFile = () => {
    setCurrentFileIndex(
      (prevIndex) =>
        (prevIndex - 1 + postItem.postFileKeys.length) %
        postItem.postFileKeys.length
    );
  };

  useEffect(() => {
    if (!ExtraPreviewModal) return;

    const videoElement = videoRef.current;
    if (
      postItem.postFileKeys[currentFileIndex]?.s3FileType?.substr(0, 5) ===
      "video"
    ) {
      videoElement.load();
      videoElement.play();
    }
  }, [currentFileIndex, postItem.postFileKeys]);

  function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string?.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${
        name?.includes(" ")
          ? name?.split(" ")[0][0] + "" + name?.split(" ")[1][0]
          : name?.[0][0]
      }`,
    };
  }

  document.addEventListener("click", function (e) {
    if (
      document.getElementById("extraPreviewModal") &&
      !document.getElementById("extraPreviewModal").contains(e.target)
    ) {
      setExtraPreviewModal(false);
      setCurrentFileIndex(2);
    }
  });

  const handleFullScreenClick = () => {
    const fileViewerElement = filePreviewRef.current;
    if (fileViewerElement) {
      if (!isFullscreen) {
        if (fileViewerElement.requestFullscreen) {
          fileViewerElement.requestFullscreen();
        } else if (fileViewerElement.mozRequestFullScreen) {
          fileViewerElement.mozRequestFullScreen();
        } else if (fileViewerElement.webkitRequestFullscreen) {
          fileViewerElement.webkitRequestFullscreen();
        } else if (fileViewerElement.msRequestFullscreen) {
          fileViewerElement.msRequestFullscreen();
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("msfullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "msfullscreenchange",
        handleFullscreenChange
      );
    };
  }, []);

  const fullScrnBtn = (
    <CustomFSBtn onClick={handleFullScreenClick} isFullscreen={isFullscreen}>
      {isFullscreen ? (
        <MdFullscreenExit style={{ height: "1.5rem", width: "auto" }} />
      ) : (
        <MdFullscreen style={{ height: "1.5rem", width: "auto" }} />
      )}
    </CustomFSBtn>
  );

  let checkAccessToken = localStorage.getItem("access_token");
  //.split("//").length <= 2

  let counter = 0;

  return (
    <BlogDiv>
      <AvatarPostWrapper>
        {postItem.userProfileDetails.profilePicture ? (
          postItem.userProfileDetails.profilePicture?.split("//").length <=
          2 ? (
            <Avatar
              alt="Profile Picture"
              src={postItem.userProfileDetails?.profilePicture}
              style={{
                padding: "0rem",
                margin: "0rem 0.5rem 0.5rem 0rem",
                top: "0px",
                width: "3.3rem",
                height: "3.3rem",
                cursor: "pointer",
                border: "1px solid #dedede",
              }}
              onClick={() => {
                setUserProfile(postItem._id);
                setProfileClicked(true);
              }}
            />
          ) : (
            <Avatar
              {...stringAvatar(
                String(
                  postItem.userProfileDetails?.fullName
                    ? postItem.userProfileDetails?.fullName.trim()
                    : postItem.postedByName.trim()
                ).toUpperCase()
              )}
              style={{
                padding: ".1rem",
                margin: "0 0.5rem 0 .5rem",
                top: "5px",
                scale: "1",
                cursor: "pointer",
              }}
              onClick={() => {
                setUserProfile(postItem._id);
                setProfileClicked(true);
              }}
            />
          )
        ) : (
          <Avatar
            {...stringAvatar(
              String(
                postItem.userProfileDetails?.fullName
                  ? postItem.userProfileDetails?.fullName.trim()
                  : postItem.postedByName.trim()
              ).toUpperCase()
            )}
            style={{
              padding: ".1rem",
              margin: "0 0.5rem 0 .5rem",
              top: "5px",
              scale: "1",
              cursor: "pointer",
            }}
            onClick={() => {
              setUserProfile(postItem._id);
              setProfileClicked(true);
            }}
          />
        )}
        <PostDetailComponent>
          <UserDetails>
            <UserDetailsWrapper>
              <NameContainer
                onClick={() => {
                  setUserProfile(postItem._id);
                  setProfileClicked(true);
                }}
              >
                <UserName>
                  {String(
                    postItem.userProfileDetails?.fullName
                      ? UserNameProcessing(postItem.userProfileDetails.fullName)
                      : UserNameProcessing(postItem.postedByName)
                  )}
                </UserName>
                <CompanyName>
                  {postItem.userProfileDetails?.company}
                </CompanyName>
              </NameContainer>
              <DateContainer>
                <PostDate>
                  {new Date(Date.now()) - new Date(postItem.checkedDate) <
                  2505600000
                    ? formatDistanceToNowStrict(
                        new Date(postItem.checkedDate)
                      ) + " ago"
                    : new Date(postItem.checkedDate).getDate() +
                      " " +
                      [
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December",
                      ][new Date(postItem.checkedDate).getMonth()] +
                      " " +
                      new Date(postItem.checkedDate).getFullYear()}
                </PostDate>
              </DateContainer>
            </UserDetailsWrapper>
            { isAdmin ? (
              <div>
                <PostOptionMenu onClick={recordButtonPosition}>
                  <GoKebabHorizontal />
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={menuOpen}
                    open={postOption}
                    onClose={togglePostOptions}
                    PaperProps={{
                      style: {
                        left: "50%",
                        transform: "translateX(-40%) translateY(40%)",
                      },
                    }}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    {options.map((option) => (
                      <MenuItem
                        key={option}
                        selected={false}
                        onClick={() => {
                          setDeleteSure(true);
                        }}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                </PostOptionMenu>
                <DialogPopup />
                <ModalWrapper show={deleteModal}>
                  <DeleteModalBody
                    id="showRejectModal"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <CloseBtnContainer
                      onClick={() => {
                        setDeleteMsg("");
                        setDeleteModal(false);
                        document.getElementById("inputMsg").value = "";
                      }}
                    >
                      <svg
                        width="50"
                        height="15"
                        viewBox="0 0 18 18"
                        fill="none"
                        style={{
                          color: "#ffffff",
                          marginRight: "-1.5rem",
                          marginTop: "0.5rem",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="9"
                          cy="9"
                          r="8.75"
                          fill="white"
                          stroke="#D1D1D1"
                          strokeWidth="0.5"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {}}
                        />
                        <path
                          d="M6.68372 5L8.96279 8.12891H9.03721L11.3163 5H12.9814L10.014 9L13 13H11.3256L9.03721 9.91406H8.96279L6.67442 13H5L8.03721 9L5.0186 5H6.68372Z"
                          fill="#FF0000"
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => {}}
                        />
                      </svg>
                    </CloseBtnContainer>
                    <HeadingContainer>Reason to delete</HeadingContainer>
                    <ModalTextInput
                      placeholder="Type here..."
                      id="inputMsg"
                      value={deleteMsg}
                      onChange={(e) => {
                        if (e.target.value.trim().length === 0) {
                          setDeleteMsg("");
                        } else {
                          setDeleteMsg(e.target.value);
                        }
                      }}
                    />
                    <div>
                      <ConfirmMsgBtn
                        disabled={!deleteMsg}
                        onClick={() => {
                          setDeleteModal(false);
                          deletePost({
                            accessToken: checkAccessToken,
                            postedByName: postItem.postedByName,
                            postedByEmail: postItem.postedByEmail,
                            postFileKeys: postItem.postFileKeys,
                            postText: postItem.postText,
                            status: "Deleted",
                            statusMessage: deleteMsg,
                            date: postItem.checkedDate,
                            objectId: postItem._id,
                          });
                        }}
                      >
                        Confirm Delete
                      </ConfirmMsgBtn>
                    </div>
                  </DeleteModalBody>
                </ModalWrapper>
              </div>
            ) : (
              <></>
            )}
          </UserDetails>
          <PostContentComponent>
            {postItem.postText?.length > 250 &&
            postItem.postFileKeys.length > 0 ? (
              readMore ? (
                <PostTextComponent>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: postItem.postText.substr(0, 250),
                    }}
                  />
                  <ReadMoreSpan onClick={readMoreHandler}>
                    See more.
                  </ReadMoreSpan>
                </PostTextComponent>
              ) : (
                <PostTextComponent>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: postItem.postText,
                    }}
                  />
                  <ReadMoreSpan onClick={readMoreHandler}>
                    See less.
                  </ReadMoreSpan>
                </PostTextComponent>
              )
            ) : (
              <PostTextComponent>
                <div dangerouslySetInnerHTML={{ __html: postItem.postText }} />
              </PostTextComponent>
            )}
            {postItem.postFileKeys.length > 0 && (
              <PostImageComponent>
                {postItem.postFileKeys.map((fileData) => {
                  counter++;
                  return (
                    <div key={`newsFeedItem_${fileData._id}`}>
                      {(fileData?.s3FileType?.substr(0, 5) === "video" ||
                        fileData?.s3FileType?.substr(0, 5) === "image") && (
                        <>
                          {postItem.postFileKeys.indexOf(fileData) === 0 && (
                            <PostPreview1stRow
                              style={{ marginBottom: "0.3rem" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setCurrentFileIndex(0);
                                setExtraPreviewModal(true);
                              }}
                            >
                              {fileData?.s3FileType?.substr(0, 5) ===
                                "video" && (
                                <PreviewVid controls>
                                  <source
                                    src={fileData.s3FileSignedUrl}
                                    type="video/mp4"
                                  />
                                </PreviewVid>
                              )}
                              {fileData?.s3FileType?.substr(0, 5) ===
                                "image" && (
                                <PreviewImg
                                  src={fileData.s3FileSignedUrl}
                                  alt="image"
                                />
                              )}
                            </PostPreview1stRow>
                          )}
                          {postItem.postFileKeys.length === 2 &&
                            postItem.postFileKeys.indexOf(fileData) === 1 && (
                              <PostPreview2ndRow
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setCurrentFileIndex(1);
                                  setExtraPreviewModal(true);
                                }}
                              >
                                {fileData?.s3FileType?.substr(0, 5) ===
                                  "video" && (
                                  <PreviewVid controls>
                                    <source
                                      src={fileData.s3FileSignedUrl}
                                      type="video/mp4"
                                    />
                                  </PreviewVid>
                                )}
                                {fileData?.s3FileType?.substr(0, 5) ===
                                  "image" && (
                                  <PreviewImg
                                    src={fileData.s3FileSignedUrl}
                                    alt="image"
                                  />
                                )}
                              </PostPreview2ndRow>
                            )}

                          {postItem.postFileKeys.length >= 3 &&
                            counter === 3 && (
                              <PostPreview2ndRow>
                                <PostPreview2ndRowEl
                                  style={{ marginRight: "0.3rem" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCurrentFileIndex(1);
                                    setExtraPreviewModal(true);
                                  }}
                                >
                                  {postItem.postFileKeys[1]?.s3FileType?.substr(
                                    0,
                                    5
                                  ) === "video" && (
                                    <PreviewVid
                                      controls
                                      style={{ width: "100%" }}
                                    >
                                      <source
                                        src={
                                          postItem.postFileKeys[1]
                                            .s3FileSignedUrl
                                        }
                                        type="video/mp4"
                                      />
                                    </PreviewVid>
                                  )}
                                  {postItem.postFileKeys[1]?.s3FileType?.substr(
                                    0,
                                    5
                                  ) === "image" && (
                                    <PreviewImg
                                      src={
                                        postItem.postFileKeys[1].s3FileSignedUrl
                                      }
                                      alt="image"
                                    />
                                  )}
                                </PostPreview2ndRowEl>
                                <PostPreview2ndRowEl
                                  style={{ position: "relative", zIndex: "0" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCurrentFileIndex(2);
                                    setExtraPreviewModal(true);
                                  }}
                                >
                                  {postItem.postFileKeys[2]?.s3FileType?.substr(
                                    0,
                                    5
                                  ) === "video" && (
                                    <PreviewVidLast
                                      controls
                                      style={{ width: "100%" }}
                                    >
                                      <source
                                        src={
                                          postItem.postFileKeys[2]
                                            ?.s3FileSignedUrl
                                        }
                                        type="video/mp4"
                                      />
                                    </PreviewVidLast>
                                  )}
                                  {postItem.postFileKeys[2]?.s3FileType?.substr(
                                    0,
                                    5
                                  ) === "image" && (
                                    <PreviewImg
                                      src={
                                        postItem.postFileKeys[2].s3FileSignedUrl
                                      }
                                      alt="image"
                                    />
                                  )}

                                  {postItem.postFileKeys.length > 3 && (
                                    <PostNumBackdrop
                                      id="extraPreviewModal"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setShowPost(false);
                                        setExtraPreviewModal(true);
                                      }}
                                    >
                                      <p>+{postItem.postFileKeys.length - 3}</p>
                                    </PostNumBackdrop>
                                  )}
                                </PostPreview2ndRowEl>
                              </PostPreview2ndRow>
                            )}
                        </>
                      )}
                      {fileData?.s3FileType?.substr(0, 5) === "appli" && (
                        <PostFileComponent
                          style={{
                            marginRight: "0rem",
                            paddingRight: "0rem",
                            marginBottom: "0.8rem",
                          }}
                          fileType={fileData?.s3FileKey.substr(
                            fileData?.s3FileKey.lastIndexOf(".") + 1,
                            fileData?.s3FileKey.length
                          )}
                        >
                          {postItem.postFileKeys.indexOf(fileData) === 0 && (
                            <PostPreview1stRow
                              style={{
                                marginBottom: "0.3rem",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setCurrentFileIndex(0);
                                setExtraPreviewModal(true);
                              }}
                            >
                              <FileViewer
                                key={Math.random()}
                                fileType={fileData?.s3FileKey.substr(
                                  fileData?.s3FileKey.lastIndexOf(".") + 1,
                                  fileData?.s3FileKey.length
                                )}
                                filePath={fileData?.s3FileSignedUrl}
                                style={{
                                  width: "95%",
                                  border: "2px solid black",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                              />
                            </PostPreview1stRow>
                          )}
                          {postItem.postFileKeys.length === 2 &&
                            postItem.postFileKeys.indexOf(fileData) === 1 && (
                              <PostPreview2ndRow
                                style={{
                                  marginBottom: "0.5rem",
                                  maxHeight: "38%",
                                  width: "102%",
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setCurrentFileIndex(1);
                                  setExtraPreviewModal(true);
                                }}
                              >
                                <FileViewer
                                  key={Math.random()}
                                  fileType={fileData?.s3FileKey.substr(
                                    fileData?.s3FileKey.lastIndexOf(".") + 1,
                                    fileData?.s3FileKey.length
                                  )}
                                  filePath={fileData?.s3FileSignedUrl}
                                  style={{
                                    width: "95%",
                                    border: "2px solid black",
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                  }}
                                />
                              </PostPreview2ndRow>
                            )}
                          {postItem.postFileKeys.length >= 3 &&
                            counter === 3 && (
                              <PostPreview2ndRow
                                style={{
                                  width: "105%",
                                  marginBottom: "0.6rem",
                                  marginRight: "0rem",
                                  paddingRight: "0rem",
                                }}
                              >
                                <PostFile2ndRowEl
                                  style={{ marginRight: "0.3rem" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCurrentFileIndex(1);
                                    setExtraPreviewModal(true);
                                  }}
                                >
                                  <FileViewer
                                    key={Math.random()}
                                    fileType={postItem.postFileKeys[1]?.s3FileKey.substr(
                                      postItem.postFileKeys[1]?.s3FileKey.lastIndexOf(
                                        "."
                                      ) + 1,
                                      postItem.postFileKeys[1]?.s3FileKey.length
                                    )}
                                    filePath={
                                      postItem.postFileKeys[1]?.s3FileSignedUrl
                                    }
                                    style={{
                                      width: "95%",
                                      border: "2px solid black",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  />
                                </PostFile2ndRowEl>
                                <PostFile2ndRowEl
                                  style={{ position: "relative", zIndex: "0" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setCurrentFileIndex(2);
                                    setExtraPreviewModal(true);
                                  }}
                                >
                                  <FileViewer
                                    key={Math.random()}
                                    fileType={postItem.postFileKeys[2]?.s3FileKey.substr(
                                      postItem.postFileKeys[2]?.s3FileKey.lastIndexOf(
                                        "."
                                      ) + 1,
                                      postItem.postFileKeys[2]?.s3FileKey.length
                                    )}
                                    filePath={
                                      postItem.postFileKeys[2]?.s3FileSignedUrl
                                    }
                                    style={{
                                      width: "95%",
                                      border: "2px solid black",
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  />

                                  {postItem.postFileKeys.length > 3 && (
                                    <PostNumBackdrop
                                      id="extraPreviewModal"
                                      style={{ height: "108%" }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setShowPost(false);
                                        setExtraPreviewModal(true);
                                      }}
                                    >
                                      <p>+{postItem.postFileKeys.length - 3}</p>
                                    </PostNumBackdrop>
                                  )}
                                </PostFile2ndRowEl>
                              </PostPreview2ndRow>
                            )}
                        </PostFileComponent>
                      )}
                    </div>
                  );
                })}
              </PostImageComponent>
            )}
          </PostContentComponent>
        </PostDetailComponent>
      </AvatarPostWrapper>
      <ModalWrapperEP show={ExtraPreviewModal}>
        <ModalBodyEP
          id="extraPreviewModal"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ModalContainerEP>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              {postItem.postFileKeys.length > 1 ? (
                <ArrowContainer>
                  <ArrowIcon
                    onClick={() => {
                      goToPrevFile();
                    }}
                  >
                    <FaArrowLeft />
                  </ArrowIcon>
                  <ArrowIcon
                    onClick={() => {
                      goToNextFile();
                    }}
                  >
                    <FaArrowRight />
                  </ArrowIcon>
                </ArrowContainer>
              ) : (
                <></>
              )}
              <ExtraPreviewContainer>
                {currentFileIndex >= 0 && (
                  <div
                    style={{
                      width: "100%",
                      height: "auto",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {postItem.postFileKeys[
                      currentFileIndex
                    ]?.s3FileType?.substr(0, 5) === "video" ? (
                      <PreviewVid ref={videoRef} controls>
                        <source
                          id="source_video"
                          src={
                            postItem.postFileKeys[currentFileIndex]
                              ?.s3FileSignedUrl
                          }
                          type="video/mp4"
                        />
                      </PreviewVid>
                    ) : postItem.postFileKeys[
                        currentFileIndex
                      ]?.s3FileType?.substr(0, 5) === "image" ? (
                      <div
                        ref={filePreviewRef}
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "flex-end",
                        }}
                      >
                        <PreviewImg
                          src={
                            postItem.postFileKeys[currentFileIndex]
                              ?.s3FileSignedUrl
                          }
                          alt="image"
                        />
                        {fullScrnBtn}
                      </div>
                    ) : (
                      postItem.postFileKeys[
                        currentFileIndex
                      ]?.s3FileType?.substr(0, 5) === "appli" && (
                        <ExtraPreviewFile>
                          <div
                            ref={filePreviewRef}
                            style={{
                              position: "relative",
                              display: "flex",
                              alignItems: "flex-end",
                            }}
                          >
                            <FileViewer
                              key={Math.random()}
                              fileType={postItem.postFileKeys[
                                currentFileIndex
                              ]?.s3FileKey.substr(
                                postItem.postFileKeys[
                                  currentFileIndex
                                ]?.s3FileKey.lastIndexOf(".") + 1,
                                postItem.postFileKeys[currentFileIndex]
                                  ?.s3FileKey.length
                              )}
                              filePath={
                                postItem.postFileKeys[currentFileIndex]
                                  ?.s3FileSignedUrl
                              }
                              style={{
                                width: "95%",
                                border: "2px solid black",
                                position: "relative",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            />
                            {fullScrnBtn}
                          </div>
                        </ExtraPreviewFile>
                      )
                    )}
                  </div>
                )}
              </ExtraPreviewContainer>
            </div>
          </ModalContainerEP>
        </ModalBodyEP>
      </ModalWrapperEP>
      <ModalWrapper show={apiCalled}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularRotatingLines />
        </div>
      </ModalWrapper>
    </BlogDiv>
  );
};

export default NewsFeedItem;
