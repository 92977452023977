import React from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';
import CommonButton from '../Common/CommonButton';
import CommonListButton from '../Common/CommonMyListButton';
import { useSelector } from 'react-redux';
import PrintListButton from '../PrivateBrandCatalog/PrintListButton';
import './BrandedMerchandise.scss';
import EmailListButton from './EmailListButton';

const BMHeader = ({ productName, collectionName, collectionId, headerText}) => {
  const { bmList = [] } = useSelector((state) => state.bmCollection);
  const { isAdmin = false } = useSelector((state) => state.userManagement);

  return (
    <div className="bm-header-wrapper">
      <div className="route-wrapper">
        <p className="route-name">
          <NavLink to="/">Home</NavLink>
          <FiChevronRight />
        </p>
        <p className="route-name">
          <NavLink to='/brandedMerchandise'>
            Branded Merchandise
          </NavLink>
          <FiChevronRight />
        </p>
        {collectionName && (
          <p className="route-name">
            <NavLink
              to={`/brandedMerchandise/collections/${collectionId}`}
              state={{ collectionName, collectionId }}  
            >
              <span className="collection-name-responsive">{collectionName}</span>
            </NavLink>
            <FiChevronRight />
          </p>
        )}
        {productName && (
          <p className="route-name">
            <span>{productName}</span>
            <FiChevronRight />
          </p>
        )}
        {headerText && (
          <p className="route-name">
            <NavLink
              to={`/brandedMerchandise/my-list`}
            >
              <span className="collection-name-responsive">{headerText}</span>
            </NavLink>
            <FiChevronRight />
          </p>
        )}
      </div>
      <div className="heading-button-wrapper">
        <div>
          <h2>{productName || collectionName || headerText || 'Collections' }</h2>

        </div>
        <div className="button-wrapper">
          {isAdmin && !collectionName && !headerText &&(
            <CommonButton
              buttonText="Manage Collections"
              tooltipText="Manage your collections"
              routePath="/manageCollections"
            />
          )}
          {!headerText &&(
          <CommonListButton
            list={bmList}
            navigateTo="/brandedMerchandise/my-list"
          />
          )}
          {headerText == "My List" &&(
          <>
              <PrintListButton myListProduct={bmList} />
              <EmailListButton emailList={bmList} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BMHeader;
