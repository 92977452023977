import React, { useState } from 'react';
import ManagementHeader from './ManagementHeader';
import LoadingIndicator from '../../Common/LoadingIndicator';
import NoDataFallback from '../../Common/NoDataFallback';
import DataTable from './DataTable';

const ManageCollection = () => {
    const [pageType, setPageType] = useState('collections');
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className='layout-wrapper'>
            <div className="wrapper">
                <ManagementHeader pageType={pageType} setPageType={setPageType} />
                {isLoading ? (
                    <LoadingIndicator />
                ) : (
                    <div className="grid-wrapper">
                        {pageType === 'collections' ? (
                            <DataTable type="collections" />
                        ) : (
                            <DataTable type="products" />
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ManageCollection;

 