import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	userFullName: "User",
	userAccess: "",
	userList: [],
	isAdmin: false,
};

const UserManagementSlice = createSlice({
	name: 'userManagement',
	initialState,
	reducers: {
		setUserAccess(state, action) {
			state.userAccess = action.payload;
		},
		clearUserAccess(state) { 
			state.userAccess = "";
		},
		setUserList(state, action) {
			state.userList = action.payload;
		},
		setUserAsAdmin (state, action) {
			state.isAdmin = action.payload;
		},
		setUserFullName (state, action) {
			state.userFullName = action.payload;
		}
	},
});

export const { setUserAccess, clearUserAccess, setUserList, setUserAsAdmin, setUserFullName } = UserManagementSlice.actions;

export default UserManagementSlice.reducer;