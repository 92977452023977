import { React, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { AiFillCloseCircle } from "react-icons/ai";

const UnpublishModalWrapper = styled.div`
  position: fixed;
  top: 0rem;
  left: 0rem;
  right: 0;
  bottom: 0;
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
`;
const UnpublishModalBody = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 0rem;
  padding-top: 2px;
  width: 50%;
  max-width: 32rem;
  /* max-height: 85vh; */
  /* overflow-y: scroll; */
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background: #f2f2f2;
`;
const UnpublishModalHeader = styled.div`
  padding: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* overflow-y: scroll; */
`;
const UnpublishModalForm = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  margin-top: 0;
  padding: 1rem;
  border-radius: 0.5rem;
  max-width: 97%;
  width: auto;
  background: white;
  /* overflow-y: scroll; */
  input {
    padding: 0.6rem;
  }
  .MuiFilledInput-input {
    padding: 0.6rem;
  }
  .MuiInputBase-root {
    border-radius: 0.3rem;
  }
`;
const ConfirmTextWrapper = styled.div`
width: auto;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  font-size: 1rem;
  border-radius: 8px;
  padding: 0.5rem;
  color: rgba(0, 0, 0, 0.8);
`;
const FormBtnWrapper = styled.div`
  width: 100%;
  margin-bottom: 0;
  margin-top: 0.5rem;
  float: right;
  display: flex;
  justify-content: flex-end;
`;
const SubmitBtn = styled.button`
  padding: 0.4rem 1rem;
  height: auto;
  background-color: #147350;
  color: white;
  border-style: none;
  border-radius: 0.3rem;
  margin-left: 0.5rem;
  cursor: pointer;
  font-weight: 600;
`;
const CancelBtn = styled.button`
  padding: 0.4rem 1rem;
  height: auto;
  font-weight: 500;
  text-align: center;
  border: 1px solid #d2d2d2;
  background-color: #f6f6f6;
  color: black;
  border-radius: 5px;
  cursor: pointer;
`;

const UnpublishConfirmation = (props) => {
    const {show, handleUnpublishConfirmation} = props;
    
    return <UnpublishModalWrapper show={show}>
    <UnpublishModalBody>
      <UnpublishModalHeader>
        <p style={{ fontWeight: "500" }}>Unpublish Product?</p>
        <AiFillCloseCircle
          style={{
            color: "#8F8F8F",
            position: "absolute",
            right: "1.6rem",
            top: "0.5rem",
            cursor: "pointer",
          }}
          onClick={() => {
            handleUnpublishConfirmation("cancel");
          }}
        />
      </UnpublishModalHeader>
      <UnpublishModalForm>
        <ConfirmTextWrapper>
          <p>
            Once unpublished, the product will not be available on this
            platform.
          </p>
        </ConfirmTextWrapper>
        <FormBtnWrapper>
          <CancelBtn
            onClick={() => {
                handleUnpublishConfirmation("cancel");
            }}
          >
            Cancel
          </CancelBtn>
          <SubmitBtn
            onClick={() => {
                handleUnpublishConfirmation("confirm");
            }}
          >
            Okay
          </SubmitBtn>
        </FormBtnWrapper>
      </UnpublishModalForm>
    </UnpublishModalBody>
  </UnpublishModalWrapper>
 }
 
 export default UnpublishConfirmation;