import { useSelector } from 'react-redux';
import { checkFeatureAccess } from './CommonUtils';
import RingLoader from '../components/Layout/RingLoader';
import { Navigate, useLocation } from 'react-router-dom';
import { useSnackbar } from "notistack";
import { logoDASubFolder } from '../Constants/Constant';

const ProtectedRoute = ({ element, feature, access, allowAdmin }) => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const { userAccess } = useSelector(state => state.userManagement);
  const accessToken = localStorage.getItem("access_token");

  const match = location.pathname.match(/Digital-assets\/([^\/]+)/);
  feature = match ? decodeURIComponent(match[1]) : feature;

  const navigateToRoot = () => {
    let message = "You do not have access to this feature";
      if (!accessToken) {
        message = "Please login to access";
      }

      enqueueSnackbar(message, {
        variant: "info",
        preventDuplicate: true,
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
      });
      return <Navigate to="/" replace= {true} />
  };

  const isValidUser = () => {
    if (!accessToken || !userAccess) {
      return false;
    }
    if (!match && feature === "Digital assets") {
      return true;
    }
    if (match && !logoDASubFolder.includes(feature)) {
      return true;
    }
    return allowAdmin || checkFeatureAccess(feature, access);
  };

  if (!isValidUser()) {
    if (accessToken && !userAccess) {
      return <RingLoader />;
    }
    return navigateToRoot();
  }

  return element;
};

export default ProtectedRoute;
