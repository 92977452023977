import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import BMHeader from './BMHeader';
import NoImage from '../PrivateBrandCatalog/assets/NoImage.png';
import LoadingIndicator from '../Common/LoadingIndicator';
import { getBMProductDetails } from './BMService';
import { addItemsDataToBMList, addItemToBMList } from './BMCollectionSlice';
import './ProductDetailsPage.scss';
import AddToListButton from './AddToListButton';
import NoDataFallback from '../Common/NoDataFallback';
import CommonErrorFallback from '../Common/CommonErrorFallback';

const BMListLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bmList, bmListData } = useSelector(state => state.bmCollection);
  const [isLoading, setIsLoading] = useState(false);
  const [apiFailReported, setApiFailReported] = useState(false);

  useEffect(() => {
    fetchBMListItems();
  }, []);

  const fetchBMListItems = async () => {
    let bmListValue = bmList;
    
    if (!bmListValue.length) {
      const bmListFromLocal = localStorage.getItem('bm_my_list');
      if (bmListFromLocal) {
        bmListValue = JSON.parse(bmListFromLocal);
      }
    }

    if (bmListValue && bmListValue.length > 0) {
      const productIds = bmListValue.map(item => item.id);
      
      if (productIds.length > 0) {
        setIsLoading(true);
        
        try {
          const productDetailsResponse = await getBMProductDetails({
            rowsPerPage: bmListValue.length,
            page: 1,
            productIds: productIds
          });
          
          if (productDetailsResponse && productDetailsResponse.data) {
            const validatedRes = productDetailsResponse.data.data.rows;
            dispatch(addItemsDataToBMList(validatedRes));
          }
          
        } catch (error) {
          console.error('Failed to fetch product details:', error);
          setApiFailReported(true);
        } finally {
          setIsLoading(false);
        }
      } else {
        console.error('No valid product IDs found');
        setApiFailReported(true); 
      }
    } else {
      setApiFailReported(true); 
    }
  };

  const handleCardClick = (item) => {
    const collectionId = item?.itemHierarchies[0]?.itemHierarchyCode?.id;
    const collectionName = item?.itemHierarchies[0]?.itemHierarchyCode?.description;
    
    navigate(`/brandedMerchandise/collections/${collectionId}/${item.itemMasterID}`, {
      state: { collectionName, collectionId }
    });
  };

  const handleRemoveItem = (itemId) => {
    const updatedList = bmList.filter(item => item.id !== itemId);
    const updatedDataList = bmListData.filter(item => item.itemMasterID !== itemId);
    
    dispatch(addItemToBMList({ id: itemId, count: 0 }));
    dispatch(addItemsDataToBMList(updatedDataList));
    localStorage.setItem('bm_my_list', JSON.stringify(updatedList));
  };
  const getItemQuantity = (id) => {
    const quantity= bmList.map((item)=>{
      if(item.id===id){
        return item.count
      }
    })
    return quantity
  }
  return (
    <div className="bm-list-wrapper">
      {isLoading && <LoadingIndicator />}
      <BMHeader headerText="My List" />
      <div className="item-container-wrapper">
        {apiFailReported ? (
          <CommonErrorFallback />
        ) : !isLoading && bmListData.length === 0 ? (
          <div className="no-data-container">
            <NoDataFallback msg="Nothing to see here. The list is empty." />
          </div>
        ) : (
          <div className="item-container">
            {bmListData.map((item, index) => (
              <div
                key={item.itemMasterID}
                className="item-card"
                onClick={() => handleCardClick(item)}
              >
                <div className="item-image-container-wrapper">
                  <div className="item-image-container">
                    <img className="item-image" src={item.imageUrl.length? item.imageUrl : NoImage}/>
                  </div>
                  <div className='item-index-container'>{`${index+1}.`}</div>
                </div>
                <div className="item-details">
                  <div className="item-name">{item.shortDescription}</div>
                  <div className="price">Price USD: ${item.brandedMerchandisePrice.toFixed(2)}</div>
                  <div className="price">SRP USD: ${item.minSrp.toFixed(2)}</div>
                  <div className='print-quantity'> Quantity: {getItemQuantity(item.itemMasterID)}</div>
                  <div>
                    <div className="add-to-list-wrapper">
                      <AddToListButton 
                        itemId={item.itemMasterID} 
                        onRemoveItem={handleRemoveItem} 
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default BMListLayout;