import axios from "axios";
import {
	user_mngt_users_list,
	user_mngt_add_user,
	user_mngt_delete_user,
	user_mngt_edit_user,
	user_mngt_deactivate_user,
	user_mngt_reset_user
} from "../config/environment";

let cancelTokenSource = null;

export const getUserList = async ({ rowsPerPage, page, obj = {}}) => {
	try {
		let data = {
			accessToken:localStorage.getItem("access_token"),
			recordsPerPage: rowsPerPage,
			pageNo: page
		};

		if (obj.filter) {
			data.filterObj = obj.filter;
		}

		if (obj.sort) {
			data.sortBy = obj.sort.field;
			data.sortOrder = obj.sort.sort.toUpperCase();
		}

		data = JSON.stringify(data);

		if (cancelTokenSource !== null) {
			cancelTokenSource.cancel();
		}
		cancelTokenSource = axios.CancelToken.source();
		var config = {
			method: "post",
			url: user_mngt_users_list,
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
			cancelToken: cancelTokenSource.token,
		};
		const response = await axios(config);
		return response.data.body?.message || { users: [] };
	} catch (error) {
		if (axios.isCancel(error)) {
			console.log("Request canceled", error.message);
		} else {
			console.error("Error fetching user list", error);
		}

		throw error;
	} finally {
		cancelTokenSource = null;
	}
};

export const addUser = async (user) => {
	var data = JSON.stringify({
		accessToken: localStorage.getItem("access_token"),
		...user
	});

	var config = {
		method: "post",
		url: user_mngt_add_user,
		headers: {
			"Content-Type": "application/json",
		},
		data: data,
	};

	return await axios(config);
};

export const editUser = async (user) => {
	const data = JSON.stringify({
		accessToken: localStorage.getItem("access_token"),
		userData: [
			{...user}
		]
	});

	const config = {
		method: "post",
		url: user_mngt_edit_user,
		headers: {
			"Content-Type": "application/json",
		},
		data: data,
	};

	return await axios(config);
};

export const deleteUser = async (user) => {
	var data = JSON.stringify({
		accessToken:localStorage.getItem("access_token"),
		...user
	});

	var config = {
		method: "post",
		url: user_mngt_delete_user,
		headers: {
			"Content-Type": "application/json",
		},
		data: data,
	};

	return await axios(config);
};

export const updateUserStatus = async (user) => {
	var data = JSON.stringify({
		accessToken:localStorage.getItem("access_token"),
		...user
	});

	var config = {
		method: "post",
		url: user_mngt_deactivate_user,
		headers: {
			"Content-Type": "application/json",
		},
		data: data,
	};

	return await axios(config);
};

export const resetUser = async (user) => {
	var data = JSON.stringify({
		accessToken:localStorage.getItem("access_token"),
		...user
	});

	var config = {
		method: "post",
		url: user_mngt_reset_user,
		headers: {
			"Content-Type": "application/json",
		},
		data: data,
	};

	return await axios(config);
};