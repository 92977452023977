import React, { useState, useEffect } from "react";
import LayoutComponent from "../Layout/LayoutComponent";
import logo from "../../assets/images/7Eleven-header.png";
import ContactPage from "../Footer/ContactUsPage";
import { BsLinkedin } from "react-icons/bs";
import "./AdsLandingPage.scss";

const AdsLandingPage = () => {
  return (
    <LayoutComponent>
      <div className="landing-page-wrapper">
        <div className="intro-wrapper">
          {/* <div className="logo-container">
            <img className="logo" src={logo} alt="Logo" height="auto" />
          </div> */}

          <div className="intro-text-container">
            <h1>STAY CONNECTED</h1>
            <h3>Thanks for your interest in 7-Eleven International.</h3>
            <h3>
              Please provide your information below to stay up to date on our
              growing global business.
            </h3>
          </div>
        </div>
        <div className="contact-form-container">
          <ContactPage />
        </div>
        <div className="linkedin-container">
          <a
            className="custom-link"
            target="_blank"
            href="https://www.linkedin.com/company/7-eleven-international/"
          >
            <h3>Connect with us on Linkedin!</h3>
          </a>
          <a
            className="custom-link linkedin-logo"
            target="_blank"
            href="https://www.linkedin.com/company/7-eleven-international/"
          >
            {<BsLinkedin />}
          </a>
        </div>
      </div>
    </LayoutComponent>
  );
};

export default AdsLandingPage;
