import { React, useEffect, useState } from "react";
import styled from "styled-components";
import Checkbox from "@mui/material/Checkbox";
import { AiFillCloseCircle } from "react-icons/ai";
import ProductTable from "./ProductTable";

const Wrapper = styled.div`
  width: 93%;
  max-height: 8rem;
  padding: 2rem 1rem;
  margin: 0.5rem;
  margin-bottom: 0.7rem;
  border: 1px solid white;
  border-radius: 1rem;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .Mui-checked {
    color: #147350 !important;
  }
`;
const NotifHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
const DetailBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  position: relative;
`;
const DetailContainer = styled.div`
  width: 85%;
  margin-right: 1rem;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
`;
const ProductDetails = styled.div`
  max-width: 15rem;
  margin: 1rem;
  margin-left: 0;
  overflow: hidden;
  height: auto;
  -webkit-line-clamp: 2;
  word-break: break-word;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;
const ViewBtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
`;
const ImgContainer = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  margin: 0.5rem 2rem;
  max-width: 10rem;
  height: auto;
`;
const NotifCardImg = styled.img`
  width: 100%;
  height: auto;
`;
const ViewProduct = styled.button`
  width: 3.7rem;
  height: auto;
  padding: 0.3rem 0rem;
  margin-top: 0.7rem;
  border-radius: 0.5rem;
  border: 1px solid #9c9c9c;
  font-size: 0.9rem;
  font-weight: 600;
  background-color: white;
  color: #575757;
  cursor: pointer;
  text-align: center;

  :hover {
    color: white;
    background-color: #147350;
    border: none;
  }
`;
const ViewModalWrapper = styled.div`
  position: fixed;
  top: 0rem;
  left: 0rem;
  right: 0;
  bottom: 0;
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
`;
const ViewModalBody = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 0rem;
  padding-top: 2px;
  width: 80vw;
  max-width: 80vw;
  max-height: 75vh;
  /* overflow-y: scroll; */
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  background: #f2f2f2;
`;
const ViewModalHeader = styled.div`
  padding: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* overflow-y: scroll; */
`;
const ViewModalForm = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem;
  margin-top: 0;
  padding: 1rem;
  border-radius: 0.5rem;
  max-width: 97%;
  width: auto;
  background: white;
  /* overflow-y: scroll; */
  input {
    padding: 0.6rem;
  }
  .MuiFilledInput-input {
    padding: 0.6rem;
  }
  .MuiInputBase-root {
    border-radius: 0.3rem;
  }
`;
const ItemDetailImg = styled.img`
  width: auto;
  max-width: 40%;
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
const TableContainer = styled.div`
  width: 75%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const NotifItem = ({ notifData, handleSelectUnselect }) => {
  const [selected, setSelected] = useState([]);
  const [viewModal, setViewModal] = useState(false);

  const handleselect = (pdtName) => {
    // check if its a request to publish or unpublish
    //execute the respective function
    //the below code is implemented there to create an array to be sent on click of main button.
    const currentIndex = selected.indexOf(pdtName);
    const newSelected = [...selected];

    if (currentIndex === -1) {
      newSelected.push(pdtName);
    } else {
      newSelected.splice(currentIndex, 1);
    }

    if (selected.includes(pdtName)) {
      setSelected(selected.filter((selected) => selected !== pdtName));
    } else {
      setSelected([...selected, pdtName]);
    }
  };

  return (
    <Wrapper>
      <div>
        <Checkbox
          onClick={() => {
            handleSelectUnselect(notifData.name);
          }}
        />
      </div>
      <ImgContainer>
        <NotifCardImg src={`${notifData.image}`} alt="product"></NotifCardImg>
      </ImgContainer>
      <div style={{ width: "80%" }}>
        <NotifHeader>
          <h2 style={{ fontSize: "2rem", fontWeight: "500" }}>
            {notifData.name}
          </h2>
        </NotifHeader>
        <DetailBtnWrapper>
          <DetailContainer>
            <ProductDetails>
              <h4 style={{ color: "black", fontWeight: "600" }}>
                Product Name
              </h4>
              <p>{notifData.name}</p>
            </ProductDetails>
            <ProductDetails>
              <h4 style={{ color: "black", fontWeight: "600" }}>
                Product Description
              </h4>
              <p>{notifData.description}</p>
            </ProductDetails>
            <ProductDetails>
              <h4 style={{ color: "black", fontWeight: "600" }}>Category</h4>
              <p>{notifData.category}</p>
            </ProductDetails>
            <ProductDetails>
              <h4 style={{ color: "black", fontWeight: "600" }}>
                Country of Origin
              </h4>
              <p>{notifData.country}</p>
            </ProductDetails>
          </DetailContainer>
          <ViewBtnContainer>
            <ViewProduct
              onClick={() => {
                setViewModal(true);
              }}
            >
              View
            </ViewProduct>
          </ViewBtnContainer>
        </DetailBtnWrapper>
      </div>
      <ViewModalWrapper
        show={viewModal}
        onClick={() => {
          setViewModal(false);
        }}
      >
        <ViewModalBody
          style={{ width: "auto" }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <ViewModalHeader>
            <p style={{ fontWeight: "500" }}>{notifData.name}</p>
            <AiFillCloseCircle
              style={{
                color: "#8F8F8F",
                position: "absolute",
                right: "1.6rem",
                top: "0.5rem",
                cursor: "pointer",
              }}
              onClick={() => {
                setViewModal(false);
              }}
            />
          </ViewModalHeader>
          <ViewModalForm>
            <ItemDetailImg
              alt="image"
              src={`${notifData.image}`}
            ></ItemDetailImg>
            <TableContainer>
              <ProductTable data={notifData} />
            </TableContainer>
            {/* <FormBtnWrapper>
              <SubmitBtn
                onClick={() => {
                  //   handleUnpublishConfirmation();
                  
                }}
              >
                Continue
              </SubmitBtn>
            </FormBtnWrapper> */}
          </ViewModalForm>
        </ViewModalBody>
      </ViewModalWrapper>
    </Wrapper>
  );
};
export default NotifItem;
