import { React, useEffect, useState, useRef } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  max-height: 10vh;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const TextContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media (max-width: 930px) {
    align-items: flex-start;
  }
`;
const SupportText = styled.p`
  color: black;
  font-weight: 500;
  font-size: 1rem;
  background-color: rgb(255, 238, 191);
`;

const DisclaimerUtil = (props) => {
    return (
        <Wrapper>
            <TextContainer>
                {/* <HeadingText>No data found</HeadingText> */}
                <SupportText>Disclaimer: The products listed on website are for information only. Requesting information does not confirm an order. Prices and availability can change without notice.</SupportText>
                <SupportText>Note: Please talk to 7IN Regional Director or Merchandise Support for any question or request.</SupportText>
            </TextContainer>
        </Wrapper>
    );
 }
 
 export default DisclaimerUtil;