import React from 'react';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';
import './ProductDetailsPage.scss';
import './BrandedMerchandise.scss';
import NoImage from '../PrivateBrandCatalog/assets/NoImage.png';
import AddToListButton from './AddToListButton';

const ProductInfoTable = ({ productData }) => {
  const { isAdmin = false } = useSelector((state) => state.userManagement);
  if (!productData) return null;
  const [ imageUrl = NoImage ] = productData?.imageUrl || [];
  return (
    <div className="product-info-container">
      <div className="product-image">
      <img src={imageUrl}/>
      </div>
      <div className="product-details">
        <TableContainer component={Paper} className="product-details-table">
          <Table>
            <TableBody>
              {[
                { label: 'Style', value: productData.brandedMerchandiseStyle },
                { label: 'Product Name', value: productData.shortDescription },
                { label: 'Sizing', value: productData.brandedMerchandiseSize },
                { label: 'Port', value: productData.brandedMerchandisePort },
                { label: 'Description', value: productData.longDescription },
                { label: 'Pricing USD ', value: `$${productData.brandedMerchandisePrice.toFixed(2)}` },
                { label: 'SRP USD ', value: `$${productData.minSrp.toFixed(2)}` },
                ...(isAdmin ? [{ label: 'Supplier', value: productData.brandedMerchandiseSupplier }] : []),
                // { label: 'Collection', value: productData.itemHierarchies[0]?.itemHierarchyCode?.description }
              ].map((item, index) => (
                <TableRow key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
                  <TableCell className="attribute-cell">{item.label}</TableCell>
                  <TableCell className="value-cell"><pre>{item.value}</pre></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <AddToListButton itemId={productData.itemMasterID} />
      </div>
    </div>
  );
};

export default ProductInfoTable;
