import React, { useEffect, useRef, useState, useContext } from "react";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import "./CountryDropdown.css"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 125,
    },
  },
};

const CountryDropdown = (props) => {
    return (
        <FormControl sx={{ minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label" sx={{ top: '-5px' }}>Country</InputLabel>
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={props.newCountry}
                label="Country"
                onChange={(e) => {
                    props.setNewCountry(e.target.value);
                }}
                MenuProps={MenuProps}
                sx={{
                  height:'2rem'
                }}
              >
                {props.countryList.map((country) => {
                  return (
                  <MenuItem value={country.value}>{country.label}</MenuItem>
                  )
                })}
              </Select>
        </FormControl>
    )
}

export default CountryDropdown;
