import { React, useState, useEffect } from "react";
import "./ContactUsPage.css";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import { countries } from "../../Constants/Constant";
import { submit_contact } from "../../config/environment";
import { MenuItem } from "@mui/material";
var qs = require("qs");
const useValidation = () => {
  const [errors, setErrors] = useState({});

  const validateInput = (
    name,
    value,
    {
      pattern = null,
      required = false,
      maxLength = null,
      additionalValidation = null,
    }
  ) => {
    let error = false;
    if (required && !value.trim()) {
      error = true;
    }
    if (pattern && value && !new RegExp(pattern).test(value)) {
      error = true;
    }
    if (maxLength && value.length > maxLength) {
      error = true;
    }
    if (additionalValidation && !additionalValidation(value)) {
      error = true;
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  return [errors, validateInput];
};

function ContactForm(props) {
  const { t } = useTranslation();
  const [additionalInfoTextFieldLabel, setAdditionalInfoTextFieldLabel] = useState('');

  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    country: "",
    company: "",
    email: "",
    interest: "",
    organisationWebsite: "",
    msg: "",
  });
  const [errors, validateInput] = useValidation();
  const [apiResponseStatus, setApiResponseStatus] = useState("Processing...");
  const [isSending, setIsSending] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [otherSelected, setOtherSelected] = useState(false);

  const IsNotValid = () => {
    return (
      !values.firstName.replace(/\s/g, "").length ||
      !values.lastName.replace(/\s/g, "").length ||
      !values.email.includes(".com") ||
      !values.email.includes("@") ||
      !(values.company?.length >= 1) ||
      !(values.country?.length > 1) ||
      !(values.interest?.length > 1) ||
      (otherSelected && !(values.other?.length > 1)) ||
      !(values.msg?.length >= 1)
    );
  };

  const { onSubmit, onSending, onApiResponseStatus } = props;

  useEffect(() => {
    onSending && onSending(isSending);
  }, [isSending]);

  useEffect(() => {
    onApiResponseStatus(apiResponseStatus);
  }, [apiResponseStatus]);

  useEffect(() => {
    setOtherSelected(values.interest === "Other");
    var check = IsNotValid();
    setIsValid(!check && Object.values(errors).every(errorState => errorState === false));
  }, [values, errors]);

  useEffect(() => {
    var check = IsNotValid();
    setIsValid(!check);
  }, [otherSelected]);

  const inputValidationRules = {
    firstName: {
      required: true,
      pattern: "^(?! )[a-zA-Z_ ]*(?<! )$",
      additionalValidation: (val) => val.replace(/\s/g, "").length,
      maxLength: 255,
    },
    lastName: {
      required: true,
      pattern: "^(?! )[a-zA-Z_ ]*(?<! )$",
      additionalValidation: (val) => val.replace(/\s/g, "").length,
      maxLength: 255,
    },
    email: {
      required: true,
      pattern: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$",
      additionalValidation: (val) => val.includes(".com") && val.includes("@"),
      maxLength: 255,
    },
    company: {
      required: true,
      pattern: "^[a-zA-Z0-9\\s!@#$%^&*()_+{}\\[\\]:;<>,.?~\\/-]+$",
      maxLength: 255,
    },
    organisationWebsite: {
      required: false,
      maxLength: 255,
    },
    country: {
      required: true,
    },
    interest: {
      required: true,
    },
    other: {
      required: true,
      pattern: "^(?! )[a-zA-Z_ ]*(?<! )$",
      maxLength: 255,
    },
    msg: {
      required: true,
      maxLength: 1000,
    },
  };
  const areaOfInterest = [
    "Food / Beverage",
    "Equipment",
    "Media",
    "Marketing",
    "Available Markets / New Market Entry",
    "7-Eleven Global Technology",
    "Investments",
    "Other",
  ];

  const submitContact = async () => {
    onSubmit && onSubmit();
    setIsSending(true);
    setIsValid(false);
    try {
      const res = await fetch(submit_contact, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: qs.stringify({
          userName: values.firstName + " " + values.lastName,
          userEmail: values.email,
          userCountry: values.country,
          userCompany: values.company,
          userInterest: values.interest === 'Other' ? values.other : values.interest,
          userMessage: values.msg,
          userOrganisationWebsite: values.organisationWebsite,
        }),
      });
      const data = await res.json();
      const resStatus = await res.status;
      setApiResponseStatus(resStatus);
      setIsSending(false);
      setValues({
        firstName: "",
        lastName: "",
        country: "",
        company: "",
        email: "",
        interest: "",
        msg: "",
        organisationWebsite: "",
      });
    } catch (err) {
      console.error("err ", err);
      setApiResponseStatus(err?.code || 500);
    }
  };

  function SubmitButton() {
    if (isValid) {
      return (
        <button
          className="submitBtn"
          onClick={(e) => {
            e.preventDefault();
            submitContact();
          }}
        >
          {t("contactUs_submit")}
        </button>
      );
    } else {
      return (
        <button className="submitBtn-disabled">{t("contactUs_submit")}</button>
      );
    }
  }
  const onChange = (e) => {
    const { name, value } = e.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    const fieldRules = inputValidationRules[name];
    if (fieldRules) {
      validateInput(name, value || "", fieldRules);
    }
  };

  return props.isVisible ? (
    <div className="contactUsFormWrapper">
      <div>
        <div className="twoFieldRow">
          <div className="inputLabelWrapper">
            <div className="contactFormInput">
              <TextField
                size="small"
                variant="outlined"
                type="text"
                error={errors.firstName}
                helperText={errors.firstName ? t("contactUs_name_warning") : ""}
                name="firstName"
                label="First Name"
                inputProps={{
                  pattern: inputValidationRules.firstName.pattern,
                  maxLength: inputValidationRules.firstName.maxLength,
                  className: "formInput",
                }}
                placeholder="First Name"
                required
                onChange={onChange}
                FormHelperTextProps={{
                  style: {
                    textAlign: "left",
                    padding: 0,
                    marginTop: "0.5rem",
                    marginLeft: "10px",
                  },
                }}
              />
            </div>
          </div>
          <div className="inputLabelWrapper">
            <div className="contactFormInput">
              <TextField
                size="small"
                variant="outlined"
                type="text"
                error={errors.lastName}
                helperText={errors.lastName ? t("contactUs_name_warning") : ""}
                FormHelperTextProps={{
                  style: {
                    textAlign: "left",
                    padding: 0,
                    marginTop: "0.5rem",
                    marginLeft: "10px",
                  },
                }}
                name="lastName"
                label="Last Name"
                placeholder="Last Name"
                inputProps={{
                  pattern: inputValidationRules.lastName.pattern,
                  maxLength: inputValidationRules.lastName.maxLength,
                  className: "formInput",
                }}
                required
                onChange={onChange}
              />
            </div>
          </div>
        </div>
        <div className="twoFieldRow">
          <div className="inputLabelWrapper">
            <div className="contactFormInput">
              <TextField
                size="small"
                inputProps={{
                  className: "formInput",
                }}
                variant="outlined"
                type="email"
                name="email"
                error={errors.email}
                helperText={errors.email ? t("contactUs_email_warning") : ""}
                FormHelperTextProps={{
                  style: {
                    textAlign: "left",
                    padding: 0,
                    marginTop: "0.5rem",
                    marginLeft: "10px",
                  },
                }}
                label="Email"
                placeholder="Email"
                required
                onChange={onChange}
              />
            </div>
          </div>
          <div
            className="inputLabelWrapper"
            style={{ justifyContent: "space-around" }}
          >
            <div className="contactFormInput">
              <TextField
                size="small"
                inputProps={{
                  className: "formInput",
                }}
                select
                id="country-customized-option-demo"
                variant="outlined"
                name="country"
                label="Country"
                placeholder="Country"
                required
                onChange={onChange}
              >
                {countries.map((country) => (
                  <MenuItem key={country.code} value={country.label}>
                    {country.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </div>
        <div className="twoFieldRow">
          <div className="inputLabelWrapper">
            <div className="contactFormInput">
              <TextField
                size="small"
                variant="outlined"
                type="text"
                name="company"
                label="Company"
                className="formInput"
                inputProps={{
                  pattern: inputValidationRules.company.pattern,
                  maxLength: 255,
                  className: "formInput",
                }}
                error={errors.company}
                helperText={errors.company ? t("contactUs_name_warning") : ""}
                FormHelperTextProps={{
                  style: {
                    textAlign: "left",
                    padding: 0,
                    marginTop: "0.5rem",
                    marginLeft: "10px",
                  },
                }}
                required
                onChange={onChange}
              />
            </div>
          </div>
          <div className="inputLabelWrapper">
            <div className="contactFormInput">
              <TextField
                size="small"
                variant="outlined"
                inputProps={{
                  className: "formInput",
                }}
                label="Organization Website"
                name="organisationWebsite"
                onChange={onChange}
              />
            </div>
          </div>
        </div>
        <div className="twoFieldRow">
          <div className="inputLabelWrapper">
            <div className="contactFormInput">
              <TextField
                size="small"
                select
                inputProps={{
                  className: "formInput",
                }}
                variant="outlined"
                name="interest"
                label="Area of Interest"
                onChange={onChange}
                required
              >
                {areaOfInterest.map((aoi) => {
                  return (
                    <MenuItem key={aoi} value={aoi}>
                      {aoi}
                    </MenuItem>
                  );
                })}
              </TextField>
            </div>
          </div>
        </div>
        {otherSelected && (
          <div className="twoFieldRow">
            <div className="inputLabelWrapper">
              <div className="contactFormInput otherInput">
                <TextField
                  size="small"
                  variant="outlined"
                  type="text"
                  error={errors.other}
                  helperText={errors.other ? t("contactUs_other_warning") : ""}
                  FormHelperTextProps={{
                    style: {
                      textAlign: "left",
                      padding: 0,
                      marginTop: "0.5rem",
                      marginLeft: "10px",
                    },
                  }}
                  name="other"
                  label="Other"
                  inputProps={{
                    pattern: inputValidationRules.other.pattern,
                    maxLength: 255,
                  }}
                  required
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <div className="twoFieldRow">
          <div className="inputLabelWrapper">
            <div className="contactFormInput">
              <TextField
                size="small"
                InputProps={{
                  rows: 6,
                  multiline: true,
                  inputComponent: "textarea",
                  className: "formInput msgInput",
                }}
                variant="outlined"
                label={additionalInfoTextFieldLabel}
                type="text"
                error={errors.msg}
                helperText={errors.msg ? t("contactUs_msg_warning") : ""}
                FormHelperTextProps={{
                  style: {
                    textAlign: "left",
                    padding: 0,
                    marginTop: "0.5rem",
                    marginLeft: "10px",
                  },
                }}
                sx={{
                  "& .MuiInputLabel-root": {
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    lineHeight: "1.5",
                    margin: 0,
                  },
                }}
                name="msg"
                placeholder="Please share any additional details about your interest in partnering with 7-Eleven International or bringing 7-Eleven to your market"
                pattern="^[a-zA-Z0-9_][a-zA-Z0-9_ ]*[a-zA-Z0-9_]$"
                maxLength="1000"
                onFocus={() =>
                  setAdditionalInfoTextFieldLabel("Additional Information")
                }
                onBlur={() => {
                  values.msg
                    ? setAdditionalInfoTextFieldLabel("Additional Information")
                    : setAdditionalInfoTextFieldLabel(
                        "Please share any additional details about your interest in partnering with 7-Eleven International or bringing 7-Eleven to your market"
                      );
                }}
                required
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="twoFieldRow">
        <div className="inputLabelWrapper">
          <SubmitButton />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default ContactForm;
