import { React, useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { LuSearch } from "react-icons/lu";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 0.7rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #f7fafc;
  position: relative;
  border: 1px solid ${props => (props.hasFocus ? '#999' : '#ccc')};
  border-radius: 4px;
  width: 12rem;
  height: 1rem;
  font-size: 0.8rem;
  color: #bebebe;
  cursor: pointer;

  @media (max-width: 770px) {
    width: 9rem;
    padding: 0.4rem;
  }
`;

const TextInput =styled.input`
  margin-left: 0.5rem;
  border-style: hidden;
  background-color: inherit;
  outline: none;
  width: 100%;
`;

const SearchBar = () => {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState([]);
  const [hasFocus, setHasFocus] = useState(false);
  const { countryName, psaName, subCat } = useParams();
  const header = countryName? countryName: (
        ""
      );
  const countryId = localStorage.getItem("country_id");
  const searchRef = useRef(null);
  
  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  function isStringAllNumbers(str) {
    return /^\d+$/.test(str);
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (query.trim() !== '') {
        let searchAttribute = {"field": `shortDescription`,"value": `${query}`,"operator": "iLike"};
        if (isStringAllNumbers(query))
          searchAttribute = {"field": `itemOriginID`,"value": `${query}`,"operator": "equal"};

        const filters = [
          searchAttribute,
          {"field": "itemHierarchyCodeId","value": `${countryId}`,"operator": "equal"}
        ];

        const filtersJson = JSON.stringify(filters);
        const encodedFilters = encodeURIComponent(filtersJson);
        const encodedCountry = encodeURIComponent(countryName);
        setQuery("");
        searchRef.current.blur();
        navigate(`/privateBrandCatalog/${encodedCountry}/search?header=${header}&filters=${encodedFilters}`);
      }
    }
  };
  
  return (
    <Wrapper hasFocus={hasFocus}>
      <TextInput
        type="text"
        value={query}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        ref={searchRef} 
        placeholder="Search Products..." />
      <LuSearch size={20}/>
    </Wrapper>
  );
};
export default SearchBar;
