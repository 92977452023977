import CircularProgress from "@mui/material/CircularProgress";
import styled from "styled-components";

const BackDrop = styled.div`
  z-index: 99999;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #565656;
  opacity: 0.1;
`;

const SpinnerComponent = () => {
	return (
		<CircularProgress color="success" 
			style={{ 
			color: "#147350", 
			left: "50%", 
			top: "45%", 
			position: "fixed",
			zIndex: "99999"
		}}/>
	);
}
const LoadingIndicator = ({ enableBackdrop = false}) => {
	return (
		<>
			{enableBackdrop && <BackDrop/>}
			<SpinnerComponent />
		</>
	);
}

export default LoadingIndicator;