import React, { useState, useEffect, useCallback } from "react";
import debounce from 'lodash.debounce';
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Grid,
  TextField,
  InputAdornment,
  Typography,
  FormControl,
  IconButton,
  Button,
  Autocomplete,
} from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import CustomFileUpload from "../../Common/CustomFileUpload/CustomFileUpload";
import LoadingIndicator from "../../Common/LoadingIndicator";
import { useSnackbar } from "notistack";
import { addProduct, editProduct, getCollectionTableData, getCollectionsData } from "../BMService";
import { brandedMerchandiseMsgs } from "../../../../Constants/Constant";
import { checkExpiryAndRedirect, uploadImageToS3 } from "../../../../Utils/CommonUtils";
import BMDeletePopup from './BMDeletePopup'
import Popper from '@mui/material/Popper';
import "../BrandedMerchandise.scss";
import { pb_service } from "../../../../config/environment";

const MAX_CHAR_LIMIT = 50;
let isDataUpdated = false;

const AddProduct = () => {
  const { state = {} } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const editProductObj = state?.editData || {};
  const isEditProduct = !!Object.keys(editProductObj).length;

  const [formData, setFormData] = useState({
    shortDescription: isEditProduct ? editProductObj.shortDescription || "" : "",
    productStyle: isEditProduct ? editProductObj.brandedMerchandiseStyle || "" : "",
    productSizing: isEditProduct ? editProductObj.brandedMerchandiseSize || "" : "",
    productPort: isEditProduct ? editProductObj.brandedMerchandisePort || "" : "",
    longDescription: isEditProduct ? editProductObj.longDescription || "" : "",
    productImageUrl: isEditProduct ? editProductObj.imageUrl?.[0] || "" : "",
    productPrice: isEditProduct ? editProductObj.brandedMerchandisePrice || "" : "",
    productSRP: isEditProduct ? editProductObj.minSrp || "" : "",
    supplierName: isEditProduct ? editProductObj.brandedMerchandiseSupplier || "" : "",
    collectionList: isEditProduct ? editProductObj.itemHierarchies.map((hierarchy) => hierarchy.itemHierarchyCode) || [] : [],
    uploadedImageData: null,
    resourceUuid: isEditProduct ? editProductObj.resourceUuid : null
  });
  const [imageError, setImageError] = useState("");
  const [isFormValid, setIsFormValid] = useState(true);
  const [requiredFieldError, setRequiredFieldError] = useState({});
  const [collectionOptions, setCollectionOptions] = useState([]);
  const [showDeleteUtil, setShowDeleteUtil] = useState(false);
  const [deletePdt, setDeletePdt] = useState(null);

  useEffect(() => {
    const {
      productStyle,
      shortDescription,
      productPrice,
      productSRP,
      productImageUrl,
      uploadedImageData,
    } = formData;
    const isValid =
      productStyle.trim() !== "" &&
      shortDescription.trim() !== "" &&
      productPrice > 0 &&
      productSRP > 0 &&
      (productImageUrl !== "" || uploadedImageData);
  
    setIsFormValid(!isValid);
  }, [formData]);

  const fetchSearchCollections = async (searchQuery) => {
    setLoading(true);
    try {
      const res = await getCollectionsData({ rowsPerPage:500, page:1,searchFilter: searchQuery });
      setCollectionOptions(res?.data?.rows || []);
    } catch (err) {
      console.error('Error fetching collections:', err);
    } finally {
      setLoading(false);
    }
  };

  const getFilteredCollectionOptions = () => {
    return collectionOptions.filter(
      (option) => !formData.collectionList.some((collection) => collection.id === option.id)
    );
  };

  const debouncedFetchProducts = useCallback(
    debounce((query) => {
      fetchSearchCollections(query);
    }, 500), 
    []
  );

  const handleSearchChange = (event) => {
    const searchQuery = event.target.value;
    if (searchQuery) {
      debouncedFetchProducts(searchQuery); 
    }
  };

  useEffect(() => {
    fetchSearchCollections();
    return () => {
      isDataUpdated= false
    }
  }, []);
  

  const constructPayload = () => {
		const payload = {
			shortDescription: formData.shortDescription,
      longDescription: formData.longDescription || '',
      hierarchyCodeIDs: formData.collectionList.map(item => item.id) || [],
      minSrp: parseFloat(formData.productSRP) || 0,
      brandedMerchandisePrice: parseFloat(formData.productPrice) || 0,
      brandedMerchandiseSize: formData.productSizing || '',
      brandedMerchandiseSupplier: formData.supplierName || '',
      brandedMerchandiseStyle: formData.productStyle || '',
      brandedMerchandisePort: formData.productPort || '',
      imageUrl: formData.productImageUrl ? [formData.productImageUrl] : []
		};
    if (isEditProduct) {
      payload.itemMasterID= editProductObj.itemMasterID
    }
		return payload;
	};

  const isSaveBtnDisabled = () => {
		if (isEditProduct && !isDataUpdated) {
			return true;
		}
		return isFormValid
	}

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    isDataUpdated = isEditProduct;
  
    if (name === "productPrice" || name === "productSRP") {
      if (value === "" || /^\d*\.?\d{0,2}$/.test(value)) {
        newValue = value;
      } else {
        return;
      }
    }
  
    setFormData((prev) => ({
      ...prev,
      [name]: newValue,
    }));
  
    const errors = { ...requiredFieldError };
  
    if (
      ((name === "productStyle" || name === "shortDescription") && value.trim() === "") ||
      ((name === "productPrice" || name === "productSRP") && parseFloat(newValue) === 0)
    ) {
      errors[name] = "This field is required";
    } else if (name === "productPrice" || name === "productSRP") {
      if (isNaN(parseFloat(newValue)) || parseFloat(newValue) < 0) {
        errors[name] = "Invalid price. Must be a positive number.";
      } else {
        errors[name] = "";
      }
    } else {
      errors[name] = "";
    }
  
    setRequiredFieldError(errors);
  };
  
  const handleBlur = (e) => {
    const { name, value } = e.target;
    if ((name === "productPrice" || name === "productSRP") && value) {
      const formattedValue = parseFloat(value).toFixed(2);
      setFormData((prev) => ({
        ...prev,
        [name]: formattedValue,
      }));
    }
  };
  
  const handleFileUpload = (fileObject) => {
    // this block is true for case when uploaded image is cancelled by user and no image is selected
    if (!fileObject) {
      isDataUpdated = false;
      setFormData((prev) => ({
        ...prev,
        uploadedImageData: null,
      }))
      setImageError("Please upload a valid image.");
    } else {
      isDataUpdated = isEditProduct;
      setFormData((prev) => ({
        ...prev,
        uploadedImageData: fileObject.file,
      }));
      setImageError("");
    }
  };

  const displayToastMsg = (toastObj) => {
		enqueueSnackbar(toastObj.message, {
			variant: toastObj.variant,
			preventDuplicate: true,
			anchorOrigin: {
				vertical: "top",
				horizontal: "right",
			},
		});
	};

  const handleSaveProduct = async () => {
    const isTokenActive = checkExpiryAndRedirect();
    if (!isTokenActive) return;
    setLoading(true);
    const payload = constructPayload();
    try {
      // if new image selected, upload it to S3 bucket and update payload with new file-name
      if (formData.uploadedImageData) {
        const fileNameInAWS = await uploadImageToS3(
          `${pb_service}/api/branded-merchandise/preSignedUrl/putObject`,
          formData.uploadedImageData,
          'product',
          isEditProduct ? formData.resourceUuid : null
        );
        payload.imageUrl = [fileNameInAWS];
      }
      isEditProduct ? await editProduct(payload) : await addProduct(payload);
      displayToastMsg({
        message: isEditProduct
        ? brandedMerchandiseMsgs.editProductSuccess
        : brandedMerchandiseMsgs.addProductSuccess,
        variant: "success",
      });
      handleExitAddProduct();
    } catch (error) {
      const defaultFailureMessage = isEditProduct
        ? brandedMerchandiseMsgs.editProductFailure
        : brandedMerchandiseMsgs.addProductFailure;
      const toastMsg = error.response?.data?.message || defaultFailureMessage;
      displayToastMsg({
        message: toastMsg,
        variant: "error",
      });
    }
    finally {
      setLoading(false);
    }
  };

  const handleExitAddProduct = () => {
    navigate("/manageCollections");
    setFormData([]);
  };

  const handleDeleteConfirmation = () => {
    resetDeleteState();
    handleExitAddProduct();
  };

  const resetDeleteState = () => {
    setDeletePdt(null);
    setShowDeleteUtil(false);
  }

  const handleDeleteUtil = () => {
    setDeletePdt({ productName: editProductObj.shortDescription, id:  editProductObj.itemMasterID});
    setShowDeleteUtil(true);
  };

  return (
    <div className="layout-wrapper">
      {loading && <LoadingIndicator />}
      <div className="form-wrapper">
        <div className="form-header">
          <div className="form-heading">
            <IconButton onClick={() => navigate("/manageCollections")}>
              <ArrowBackIos />
            </IconButton>
            <h2>{isEditProduct ? "Edit Product" : "Add Product"}</h2>
          </div>
        </div>
        <div className="centre-aligned-form-container">
          <Grid container spacing={3} sx={{ mb: 1 }}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={<>Style <span style={{ color: 'red' }}>*</span></>}
                placeholder="Style e.g. Summer collection, under $100"
                variant="outlined"
                name="productStyle"
                value={formData.productStyle}
                onChange={handleChange}
                error={!!requiredFieldError.productStyle}
                helperText={requiredFieldError.productStyle}
                InputLabelProps={{
                  sx: {
                    "&.Mui-focused": {
                      color: "green",
                    },
                  },
                }}
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor:
                        formData.productStyle.length > MAX_CHAR_LIMIT
                          ? "#d32f2f"
                          : "default",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "green",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "8px 8px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={<>Product Name <span style={{ color: 'red' }}>*</span></>}
                placeholder="Product Name e.g. Stonewashed Cap"
                variant="outlined"
                name="shortDescription"
                value={formData.shortDescription}
                onChange={handleChange}
                error={!!requiredFieldError.shortDescription}
                helperText={requiredFieldError.shortDescription}
                InputLabelProps={{
                  sx: {
                    "&.Mui-focused": {
                      color: "green",
                    },
                  },
                }}
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor:
                        formData.shortDescription.length > MAX_CHAR_LIMIT
                          ? "#d32f2f"
                          : "default",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "green",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "8px 8px",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mb: 1 }}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Sizing"
                placeholder="Sizing e.g. Age 4-5yrs, One size"
                variant="outlined"
                name="productSizing"
                value={formData.productSizing}
                onChange={handleChange}
                error={!!requiredFieldError.productSizing}
                helperText={requiredFieldError.productSizing}
                InputLabelProps={{
                  sx: {
                    "&.Mui-focused": {
                      color: "green",
                    },
                  },
                }}
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor:
                        formData.productSizing.length > MAX_CHAR_LIMIT
                          ? "#d32f2f"
                          : "default",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "green",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "8px 8px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Port"
                placeholder="Port e.g. FOB Ningbo"
                variant="outlined"
                name="productPort"
                value={formData.productPort}
                onChange={handleChange}
                error={!!requiredFieldError.productPort}
                helperText={requiredFieldError.productPort}
                InputLabelProps={{
                  sx: {
                    "&.Mui-focused": {
                      color: "green",
                    },
                  },
                }}
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor:
                        formData.productPort.length > MAX_CHAR_LIMIT
                          ? "#d32f2f"
                          : "default",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "green",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "8px 8px",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Description"
                placeholder="Description"
                variant="outlined"
                name="longDescription"
                value={formData.longDescription}
                onChange={handleChange}
                error={!!requiredFieldError.longDescription}
                helperText={requiredFieldError.longDescription}
                InputLabelProps={{
                  sx: {
                    "&.Mui-focused": {
                      color: "green",
                    },
                  },
                }}
                sx={{
                  mb: 2,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor:
                        formData.longDescription.length > MAX_CHAR_LIMIT
                          ? "#d32f2f"
                          : "default",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "green",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "8px 8px",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
          <CustomFileUpload
            onFileUpload={handleFileUpload}
            imageError={imageError}
            isRequired={true}
            imageUrl={editProductObj.imageUrl?.[0]}
          />
        </div>
        <div className="centre-aligned-form-container">
          <Typography variant="h6" className="form-input-heading">
            Pricing 
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={<>Price USD <span style={{ color: 'red' }}>*</span></>}
                placeholder="Price USD $"
                variant="outlined"
                name="productPrice"
                value={formData.productPrice}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!requiredFieldError.productPrice}
                helperText={requiredFieldError.productPrice}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                InputLabelProps={{
                  sx: {
                    "&.Mui-focused": {
                      color: "green",
                    },
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor:
                        formData.productPrice.length > MAX_CHAR_LIMIT
                          ? "#d32f2f"
                          : "default",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "green",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "8px 8px",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label={<>SRP USD $ <span style={{ color: 'red' }}>*</span></>}
                placeholder="SRP USD $"
                variant="outlined"
                name="productSRP"
                value={formData.productSRP}
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!requiredFieldError.productSRP}
                helperText={requiredFieldError.productSRP}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                InputLabelProps={{
                  sx: {
                    "&.Mui-focused": {
                      color: "green",
                    },
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor:
                        formData.productSRP.length > MAX_CHAR_LIMIT
                          ? "#d32f2f"
                          : "default",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "green",
                    },
                    "& .MuiOutlinedInput-input": {
                      padding: "8px 8px",
                    },
                  },
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className="centre-aligned-form-container">
          <Typography variant="h6" className="form-input-heading">
            Supplier
          </Typography>

          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Supplier Name"
              placeholder="Supplier Name"
              variant="outlined"
              name="supplierName"
              value={formData.supplierName}
              onChange={handleChange}
              error={!!requiredFieldError.supplierName}
              helperText={requiredFieldError.supplierName}
              InputLabelProps={{
                sx: {
                  "&.Mui-focused": {
                    color: "green",
                  },
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor:
                      formData.supplierName.length > MAX_CHAR_LIMIT
                        ? "#d32f2f"
                        : "default",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "green",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "8px 8px",
                  },
                },
              }}
            />
          </Grid>
        </div>
        <div className="centre-aligned-form-container">
          <Typography variant="h6" className="form-input-heading">
            Add to Collection
          </Typography>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                options={getFilteredCollectionOptions()}
                getOptionLabel={(option) => option.description}
                value={formData.collectionList}
                onChange={(event, newValue) => {
                  isDataUpdated= true;
                  setFormData((prev) => ({
                    ...prev,
                    collectionList: newValue,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Collection"
                    error={!!requiredFieldError.collectionList}
                    helperText={requiredFieldError.collectionList}
                    onChange={handleSearchChange} 
                    size="medium"
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        padding: "8px 8px",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "green",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                        {
                          borderColor: "green",
                        },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "green",
                      },
                    }}
                  />
                )}
                clearOnEscape
                autoHighlight
                PopperComponent={(props) => (
                  <Popper
                      {...props}
                      sx={{
                          '& .MuiAutocomplete-listbox': {
                              maxHeight: 214, 
                              overflow: 'auto', 
                          },
                      }}
                  />
              )}
              />
            </FormControl>
          </Grid>
        </div>
        <Box className="button-container">
          <Button
            size="medium"
            sx={{
              backgroundColor: isSaveBtnDisabled() ? "rgba(0, 0, 0, 0.12)" : "#147350",
              color: "white",
              "&:hover": {
                backgroundColor: isSaveBtnDisabled() ? "rgba(0, 0, 0, 0.12)" : "#147350",
                opacity: isFormValid ? 1 : 0.9,
              },
            }}
            disabled={isSaveBtnDisabled()}
            className="save-btn"
            onClick={handleSaveProduct}
          >
            {isEditProduct ? 'Save Product' : 'Add Product'}
          </Button>

          {isEditProduct && (
            <Button
              size="medium"
              variant="outlined"
              sx={{
                color: "#EF5350",
                marginLeft: "0.75rem",
                border: "1px solid #EF5350",
                "&:hover": {
                  border: "1px solid #EF5350",
                  backgroundColor: "rgba(239, 83, 80, 0.1)"
                }
              }}
              onClick={handleDeleteUtil}
            >
              Delete Product
            </Button>
          )}
        </Box>
        {showDeleteUtil && (
          <BMDeletePopup row={deletePdt} type={'products'} onConfirm={handleDeleteConfirmation} onCancel={resetDeleteState} />
        )}
      </div>
    </div>
  );
};

export default AddProduct;
