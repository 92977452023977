import { useState, useEffect } from "react";
import { deleteCollections, deleteProducts } from "../BMService";
import { useSnackbar } from "notistack";
import CustomConfirmationPopup from "../../Common/CustomConfirmationPopup";
import { brandedMerchandiseMsgs } from "../../../../Constants/Constant";

const BMDeletePopup = ({ row, type, onConfirm, onCancel }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [confirmDialogContext, setConfirmDialogContext] = useState({
    content: {
      title: "",
      message: "",
      additionalMsg: "",
    },
    buttons: [],
    isVisible: false,
  });

  useEffect(() => {
    handleDelete();
  }, []);

  const resetDialog = () => {
    setConfirmDialogContext({
      content: null,
      buttons: null,
      isVisible: false,
    });
  };

  const displayToastMsg = (toastObj) => {
    enqueueSnackbar(toastObj.message, {
      variant: toastObj.variant,
      preventDuplicate: true,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right",
      },
    });
  };

  const handleDelete = () => {
    const content = {
      title: "Are you sure ?",
      message: "",
      additionalMsg: "This Process cannot be undone.",
    };
    const buttons = [
      {
        text: "Cancel",
        onClick: () => {
          resetDialog();
          onCancel();
        },
        variant: "secondary",
      },
    ];
    if (type === "products")
      content.message = `Do you really want to delete the product "${row.productName}" ?`;
    else if (type === "collections")
      content.message = `Do you really want to delete the collection "${row.collection}" ?`;
    buttons.push({
      text: "Confirm",
      onClick: () => handleDeleteConfirmation([row.id]),
      variant: "primary",
    });
    setConfirmDialogContext({
      content,
      buttons,
      isVisible: true,
    });
  };

  const handleDeleteConfirmation = async (resourceIDs) => {
    try {
      setLoading(true);
      if (type === "collections") {
        await deleteCollections(resourceIDs);
        displayToastMsg({
          message: brandedMerchandiseMsgs.deleteCollectionSuccess,
          variant: "success",
        });
      } else if (type === "products") {
        await deleteProducts(resourceIDs);
        displayToastMsg({
          message: brandedMerchandiseMsgs.deleteProductSuccess,
          variant: "success",
        });
      }
      await onConfirm();
    } catch (err) {
      console.error(err);
      let toastMsg = JSON.parse(err.response?.data?.body || "{}")?.message;
      if (type === "collections") {
        toastMsg = toastMsg || brandedMerchandiseMsgs.deleteCollectionFailure;
      } else if (type === "products") {
        toastMsg = toastMsg || brandedMerchandiseMsgs.deleteProductFailure;
      }
      displayToastMsg({ message: toastMsg, variant: "error" });
    } finally {
      setLoading(false);
      resetDialog();
    }
  };

  return (
    <CustomConfirmationPopup
      isVisible={confirmDialogContext.isVisible}
      content={confirmDialogContext.content}
      closeDialog={() => {
        resetDialog();
        onCancel();
      }}
      buttons={confirmDialogContext.buttons}
    />
  );
};

export default BMDeletePopup;
