import React from 'react';

const CategoryCollectionSection = ({
  collections,
  selectedCollectionId,
  handleCollectionChange,
}) => {
  return (
    <div className="collections-section">
      <div className="collections-section-header">Collections</div>
      <div className="collections-section-container">
        {collections.map((collection) => (
          <div key={collection.id} className="collection-item">
            <input
              type="radio"
              id={`collection-${collection.id}`}
              name="collection"
              value={collection.id}
              checked={selectedCollectionId === collection.id}
              onChange={handleCollectionChange}
            />
            <label htmlFor={`collection-${collection.id}`}>{collection.description}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoryCollectionSection;
