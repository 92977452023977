import { React, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UserContext } from "../../../App";
import { useNavigate } from "react-router-dom";
import {
  redirect_uri,
  login_domain,
  client_id,
} from "../../../config/environment";
import { checkFeatureAccess } from "../../../Utils/CommonUtils";
import { mobNavLinks } from "../../../Constants/Constant";

const NavItems = (props) => {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(UserContext);
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/contact-us`);
  };
  
  // const red_uri = "us-west-1_3HSi8LtrJ";
  const login_redirect = `https://${login_domain}/login?client_id=${client_id}&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https://${redirect_uri}/assets`;

  let mobNavItem;
  mobNavItem = (
    <div className="mobile-navbar">
      {mobNavLinks.map((link, index) => {
        const displayLink =
          !link.requiresAccessCheck ||
          checkFeatureAccess(link.feature, link.access);
        if (displayLink) {
          return (
            <Link
              key={index}
              to={link.url}
              className="buttons"
              onClick={() => {
                props.hamClose();
              }}
            >
              {link.text}
            </Link>
          );
        }
      })}
      {state ? (
        <Link
          to="/logout"
          className="buttons"
          onClick={() => {
            props.hamClose();
          }}
        >
          {t("Log_Out")}
        </Link>
      ) : (
        <a href={login_redirect} className="buttons">
          {t("Login")}
        </a>
      )}
    </div>
  );
  return mobNavItem;
};

export default NavItems;
