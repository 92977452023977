import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import "./CustomFileUpload.scss";

const CustomFileUpload = ({ onFileUpload, imageError, imageUrl, isRequired }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [uploadFileUrl, setUploadFileUrl] = useState(imageUrl || "");
  const [diffFileType, setDiffFileType] = useState(false);
  const [fileSizeLimit, setFileSizeLimit] = useState(false);

  const acceptedExtTypes=["image/jpeg",
  "image/jpg",
  "image/png",
  "image/gif",
  "image/svg+xml",]

  useEffect(()=>{
    let msg = '';
    if(diffFileType){
      msg= 'Unexpected file type.'
    } else if(fileSizeLimit){
      msg= 'File size exceeded.'
    }
    if(diffFileType || fileSizeLimit){
      displayToastMsg({
        message: msg,
        variant: "error",
      });
      setDiffFileType(false);
      setFileSizeLimit(false);
    }
  },[diffFileType,fileSizeLimit])

  const displayToastMsg = (toastObj) => {
		enqueueSnackbar(toastObj.message, {
			variant: toastObj.variant,
			preventDuplicate: true,
			anchorOrigin: {
				vertical: "top",
				horizontal: "right",
			},
		});
	};

  const handleImageUpload = (acceptedFiles) => {
    const filteredFiles = acceptedFiles.filter((file) =>
      acceptedExtTypes.includes(file.type)
    );
    if (acceptedFiles.length !== filteredFiles.length) {
      setDiffFileType(true);
      return;
    }
    const sizeFilteredFiles = filteredFiles.filter(
      (file) => file.size <= 3145728 //3mb
    );
    if (filteredFiles.length !== sizeFilteredFiles.length) {
      setFileSizeLimit(true);
      return;
    }

    const newFile = acceptedFiles[0];
    if (newFile) {
      const fileUrl =  URL.createObjectURL(newFile)
      setUploadFileUrl(fileUrl);
      onFileUpload({ fileUrl, file: newFile });
    }
  };

  const removeFile = () => {
    setUploadFileUrl("");
    onFileUpload(null);
  };

  let dropDiv = ({ getRootProps, getInputProps }) => (
    <div
      {...getRootProps()}
      className={`drop-wrapper ${uploadFileUrl.length ? "no-hover" : ""}`}
    >
      <input {...getInputProps()} />
      {!uploadFileUrl.length ? (
        <>
          <div className="icon-container">
            <UploadFileIcon />
          </div>
          <p className="text-1">Click to upload, or drag and drop</p>
          <p className="text-2">SVG, PNG, JPG or GIF (max. 3MB)</p>
        </>
      ) : (
        <>
          <img
            src={uploadFileUrl}
            alt="Uploaded"
            className="uploaded-image"
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          <CloseIcon
            className="remove-icon"
            onClick={(e) => {
              e.stopPropagation();
              removeFile();
            }}
          />
        </>
      )}
    </div>
  );

  return (
    <div>
      <div>
        <Typography variant="h6" sx={{mt: 1}}>Featured Image {isRequired && <span style={{ color: 'red' }}>*</span>}</Typography>
        <div className="dropzone-wrapper">
          <Dropzone onDrop={(acceptedFiles) => handleImageUpload(acceptedFiles)}>
            {dropDiv}
          </Dropzone>
        </div>
      </div>
      {imageError && <p className="error-helper-text">{imageError}</p>}
    </div>
  );
};

export default CustomFileUpload;
