import React, { useEffect, useState, useContext } from "react";
import { Avatar } from "@mui/material";
import styled from "styled-components";
import { SkeletonLoader } from "../../Utils/CircularLoader";
import NewsFeedItem from "./SocialMediaPosts/NewsFeedItem";
import { IoIosArrowBack } from "react-icons/io";
import "./NewsFeed.css";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  margin-bottom: 2rem;
  background: white;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 90%;
  border: 1px solid #e0dede;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
`;

const ProfilePosts = styled.div`
  width: 40rem;
  margin: 28rem 0 0 0;
  @media (max-width: 1200px) {
    width: 38rem;
  }
  @media (max-width: 1100px) {
    margin-left: 7rem;
  }
  @media (max-width: 1000px) {
    width: 33rem;
    margin-left: 5rem;
  }
  @media (max-width: 875px) {
    margin-left: 9rem;
  }
  @media (max-width: 810px) {
    width: 28rem;
    margin-left: 10rem;
  }
  @media (max-width: 700px) {
    width: 25rem;
    margin-left: 9rem;
    margin-top: 24rem;
  }
  @media (max-width: 640px) {
    width: 27rem;
    margin-left: 6rem;
    margin-top: 24rem;
  }
  @media (max-width: 480px) {
    width: 25rem;
    margin-left: 6rem;
  }
`;

const CustomBtn = styled.button`
  width: 2.8rem;
  padding: 0.1rem 0rem;
  height: 1.2rem;
  border-radius: 2rem;
  font-size: 0.6rem;
  cursor: pointer;
  height: 2.5rem;
  position: relative;
  text-align: center;
  margin: 1rem 1rem 0 0;
`;

const BackBtn = styled(CustomBtn)`
  font-size: 15px;
  width: 6rem;
  border: 1px solid #d2d2d2;
  background-color: #ffffff;
  color: black;
  box-shadow: 0 5px 5px -5px rgba(33, 33, 33, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin: 1rem 1rem 0 0;
`;

const ModalWrapperEP = styled.div`
  position: fixed;
  top: -9rem;
  left: 0rem;
  right: 0;
  bottom: 0;
  display: ${(props) => (props.show ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9;
`;

const ModalBody = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 0px 16px;
  padding-top: 2px;
  width: 40rem;
  max-height: 30rem;
  height: ${(props) => (props.imageAvailable ? "30rem" : "auto")};
  overflow-y: scroll;
  top: 20rem;
  position: absolute;
`;

const RejectModalBody = styled(ModalBody)`
  width: 38rem;
  top: 20rem;
`;

const ModalBodyEP = styled(RejectModalBody)`
  background-color: rgb(1, 1, 1);
  border-radius: 0px;
  padding: 0px 16px;
  padding-top: 2px;
  width: 45rem;
  max-height: 100%;
  top: 20rem;
  position: absolute;
`;

const PostPreview1stRow = styled.div`
  width: 100%;
  height: 17rem;
  cursor: pointer;
`;

const ExtraPreviewContainer = styled(PostPreview1stRow)`
  width: 80%;
  height: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: relative;
`;

const PreviewImg = styled.img`
  width: 100%;
  height: inherit;
  object-fit: cover;
`;

const PostDetailComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0rem;
  padding-bottom: 1rem;
  width: 90%;
  max-height: 23rem;
  height: auto;
`;

const PostTextComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 1rem;
  padding-right: 0.7rem;
  word-break: break-word;
  white-space: pre-line;
`;

const ReadMoreSpan = styled.div`
  display: inline-block;
  color: #215bbe;
  cursor: pointer;
`;

const AvatarPostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const ProfileBody = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 0px 16px;
  padding-top: 2px;
  width: 40rem;
  max-height: 25rem;
  height: ${(props) => (props.imageAvailable ? "30rem" : "auto")};
  overflow-y: scroll;
  position: absolute;
  border: 1px solid #e0dede;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  @media (max-width: 1200px) {
    width: 36rem;
  }
  @media (max-width: 1100px) {
    margin-left: 7rem;
  }
  @media (max-width: 1000px) {
    width: 33rem;
    margin-left: 5rem;
  }
  @media (max-width: 875px) {
    width: 31rem;
    margin-left: 6rem;
  }
  @media (max-width: 810px) {
    width: 25rem;
    height: 24rem;
  }
  @media (max-width: 700px) {
    width: 23rem;
    height: 21rem;
    margin-left: 7rem;
  }
  @media (max-width: 655px) {
    margin-left: 5rem;
  }
  @media (max-width: 640px) {
    width: 25rem;
    margin-left: 5rem;
  }
  @media (max-width: 550px) {
    width: 22rem;
    height: 20rem;
    margin-left: 6rem;
  }
  @media (max-width: 480px) {
    width: 21rem;
    margin-left: 5rem;
  }
`;

const imageTypes = ["jpeg", "jpg", "png"];

const ProfileDetails = (props) => {
  const [readMoreBig, setReadMoreBig] = useState(true);
  const [profilePicPreviewModal, setProfilePicPreviewModal] = useState(false);

  const navigate = useNavigate();

  const readMoreBigHandler = () => {
    setReadMoreBig(!readMoreBig);
  };

  const profilePicPreviewHandler = () => {
    setProfilePicPreviewModal(true);
  };

  document.addEventListener("click", function (e) {
    if (
      document.getElementById("extraPreviewModal") &&
      !document.getElementById("extraPreviewModal").contains(e.target)
    ) {
      setProfilePicPreviewModal(false);
    }
  });

  function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = "#";
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${
        name.includes(" ")
          ? name?.split(" ")[0][0] + "" + name?.split(" ")[1][0]
          : name[0][0]
      }`,
    };
  }
  const handleDeleteReload = () => {
    props.handleDeleteReload();
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
      className="container"
    >
      {props.profileInfo ? (
        <div className="newsFeedBlockProfile">
          <AvatarPostWrapper>
            <ProfileBody>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "1rem 0 0 0",
                }}
                id="extraPreviewModal"
              >
                {props.profileInfo.profilePic?.split("//").length <= 2 ? (
                  <Avatar
                    alt="Profile Picture"
                    src={props.profileInfo.profilePic}
                    style={{
                      padding: "0rem",
                      margin: "0rem 0rem 0rem 1rem",
                      top: "2px",
                      width: "5rem",
                      height: "5rem",
                      border: "1px solid #dedede",
                      cursor: "pointer",
                    }}
                    onClick={profilePicPreviewHandler}
                  />
                ) : (
                  <Avatar
                    {...stringAvatar(
                      String(props.profileInfo.fullName.trim()).toUpperCase()
                    )}
                    style={{
                      padding: "0rem",
                      margin: "0rem 0rem 0rem 1rem",
                      top: "2px",
                      width: "5rem",
                      height: "5rem",
                      cursor: "pointer",
                    }}
                    onClick={profilePicPreviewHandler}
                  />
                )}
                <div
                  style={{
                    margin: "2.25rem 0 0 1rem",
                    fontSize: "19px",
                    width: "100%",
                    height: "14px",
                    top: "103px",
                    lineHeight: "14.06px",
                  }}
                >
                  {`${String(
                    props.profileInfo.fullName[0]
                  )}${String(
                    props.profileInfo.fullName.slice(1)
                  )}`}
                </div>
                <BackBtn
                  onClick={() => {
                    props.onBackBtnHandler(false);
                  }}
                >
                  <IoIosArrowBack
                    sx={{
                      scale: "1.5",
                      textAlign: "center",
                      paddingRight: "4px",
                      marginRight: "-0.3rem",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    Back
                  </div>
                </BackBtn>
              </div>
              <PostDetailComponent>
                <div
                  style={{
                    margin: "2rem 1rem 0rem 1rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                    float: "right",
                  }}
                >
                 
                  <div style={{ width: "10rem", color: "black" }}>Title:</div>
                  <div style={{ width: "100%", color: "black" }}>
                    {props.profileInfo.userAbout?.length > 100 ? (
                      readMoreBig ? (
                        <PostTextComponent>
                          {props.profileInfo.userAbout.substring(0, 100)}
                          <ReadMoreSpan onClick={readMoreBigHandler}>
                            See more.
                          </ReadMoreSpan>
                        </PostTextComponent>
                      ) : (
                        <PostTextComponent>
                          {props.profileInfo.userAbout}
                          <ReadMoreSpan onClick={readMoreBigHandler}>
                            See less.
                          </ReadMoreSpan>
                        </PostTextComponent>
                      )
                    ) : (
                      <PostTextComponent>
                        {props.profileInfo.userAbout}
                      </PostTextComponent>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    margin: "2rem 1rem 2rem 1rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                    float: "right",
                  }}
                >
                  <div style={{ width: "10rem", color: "black" }}>Company:</div>
                  <div style={{ width: "100%", color: "black" }}>
                    {props.profileInfo.company}
                  </div>
                </div>
                <div
                  style={{
                    padding: "0rem 1rem 2rem 1rem",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    width: "100%",
                    float: "right",
                  }}
                >
                  <div style={{ width: "10rem", color: "black" }}>Country/Region:</div>
                  <div style={{ width: "100%", color: "black" }}>
                    {props.profileInfo.country}
                  </div>
                </div>
              </PostDetailComponent>
            </ProfileBody>
          </AvatarPostWrapper>
          <ModalWrapperEP show={profilePicPreviewModal}>
            <ModalBodyEP
              id="extraPreviewModal"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "1rem",
                  marginTop: "1rem",
                }}
              >
                <ExtraPreviewContainer>
                  {
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {
                        <PreviewImg
                          src={props.profileInfo.profilePic}
                          alt="pendingImg"
                        />
                      }
                    </div>
                  }
                </ExtraPreviewContainer>
              </div>
            </ModalBodyEP>
          </ModalWrapperEP>
        </div>
      ) : (
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <SkeletonLoader />
        </div>
      )}
      <ProfilePosts>
        {props.profilePosts ? (
          props.profilePosts.map((post) => {
            return (
              <NewsFeedItem
                postItem={post}
                handleDeleteReload={handleDeleteReload}
              />
            );
          })
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}></div>
        )}
      </ProfilePosts>
    </div>
  );
};

export default ProfileDetails;
