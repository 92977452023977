export const initialState = null;
export const initialStatus = null;
export const initialName = null;

export const reducer = (state, action) => {
    if(action.type === "USER") {
        return action.payload;
    }

    return state;
}

export const accessReducer = (status, accessAction) => {
    if(accessAction.type === "success") {
        return accessAction.payload;
    }

    return status;
}

export const nameReducer = (name, nameAction) => {
    if(nameAction.type === "username") {
        return nameAction.payload;
    }

    return name;
}