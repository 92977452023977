import React, { useEffect, useState } from "react";
import NewsFeedItem from "./NewsFeedItem";
import styled from "styled-components";
import {
  CircularRotatingLines,
  SkeletonLoader,
} from "../../../Utils/CircularLoader";
import { fetch_post } from "../../../config/environment";

var qs = require("qs");
var axios = require("axios");

const NewsFeedWrapper = styled.div`
  width: 100%;
`;

const NewsFeedPosts = (props) => {
  const checkAccessToken = localStorage.getItem("access_token");
  // const fetch_post = process.env.REACT_APP_FETCH_POST;
  const [fetchResponse, setFetchResponse] = useState(props.posts);
  const [isFetching, setIsFetching] = useState(false);
  const [pageNum, setPageNum] = useState(2);
  const [complete, setComplete] = useState(false);

  var data = qs.stringify({
    accessToken: checkAccessToken,
    pageNum: pageNum,
  });

  var config = {
    method: "post",
    url: fetch_post,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: data,
  };

  const fetchPost = async () => {
    setPageNum((prev) => prev + 1);
    setIsFetching(true);
    axios(config)
      .then(function (response) {
        const res = response.data.body.message.allPosts;
        if (res.length < 10) {
          setComplete(true);
        }
        return res;
      })
      .then(function (res) {
        setFetchResponse(fetchResponse.concat(res));
        setIsFetching(false);
      })
      .catch(function (error) {
        setIsFetching(false);
      });
  };

  const handleScroll = () => {
    if (
      (Math.ceil(window.innerHeight + document.documentElement.scrollTop) ===
        document.documentElement.offsetHeight ||
        Math.floor(window.innerHeight + document.documentElement.scrollTop) ===
          document.documentElement.offsetHeight) &&
      !complete
    ) {
      fetchPost();
    }
    return;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isFetching]);
  const handleDeleteReload = () => {
    props.handleDeleteReload();
  };

  return (
    <NewsFeedWrapper>
      <div>
        {props.gettingBucket && !props.refresh ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SkeletonLoader />
          </div>
        ) : props.isLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SkeletonLoader />
          </div>
        ) : fetchResponse.length === 0 && props.posts ? (
          props.posts.map((post) => {
            return (
              <NewsFeedItem
                postItem={post}
                key={`${post._id}`}
                setProfileClicked={props.setProfileClicked}
                setUserProfile={props.setUserProfile}
                reloadComponent={props.reloadComponent}
                handleDeleteReload={handleDeleteReload}
              />
            );
          })
        ) : fetchResponse !== [] ? (
          <>
            {props.posts.map((post) => {
              return (
                <NewsFeedItem
                  postItem={post}
                  key={`${post._id}`}
                  setProfileClicked={props.setProfileClicked}
                  setUserProfile={props.setUserProfile}
                  reloadComponent={props.reloadComponent}
                  handleDeleteReload={handleDeleteReload}
                />
              );
            })}
            {fetchResponse.map((post) => {
              return (
                <NewsFeedItem
                  postItem={post}
                  key={`${post._id}`}
                  setProfileClicked={props.setProfileClicked}
                  setUserProfile={props.setUserProfile}
                  reloadComponent={props.reloadComponent}
                  handleDeleteReload={handleDeleteReload}
                />
              );
            })}
          </>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <SkeletonLoader />
          </div>
        )}
      </div>
      {isFetching && fetchResponse !== [] ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SkeletonLoader />
        </div>
      ) : !props.posts && !(fetchResponse !== []) ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <SkeletonLoader />
        </div>
      ) : (
        <></>
      )}
    </NewsFeedWrapper>
  );
};

export default NewsFeedPosts;
