import React from "react";
import Assets from "../../Assets/Assets";
import "./PageUnderCons.css";

const PageUnderCons = () => {
  return (
    <div className="newsFeedLayout">
      <div className="assetsContainer">
        <Assets />
      </div>
      <div className="PageUnderCons">
        <div className="headingContainer">
          <h1>Page under construction...</h1>
        </div>
      </div>
    </div>
  );
};

export default PageUnderCons;
