import React from "react";
import { useTranslation } from "react-i18next";

const RingLoader = () => {
  const { t } = useTranslation();

  return <h1 className="container1-h1">{t("Suspense_Loading")}</h1>;
};

export default RingLoader;
