import { React, useState } from "react";
import "./Footer.css";
import { useTranslation } from "react-i18next";
import FooterLayout from "../Layout/FooterLayout";
import footerLogo from "../../assets/images/7Eleven-header-white.png";

import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import EastOutlinedIcon from "@mui/icons-material/EastOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import { countryUrl } from "../../Constants/Constant";
import styled from "styled-components";

  const Wrapper = styled.div`
    /* Your custom styles here */
    .MuiInputBase-input-MuiInput-input {
      color: white !important;
    }
    .MuiFormLabel-root-MuiInputLabel-root {
      color: white !important;
    }
    .MuiAutocomplete-input {
      font-size: 1.5rem;
    }
    .MuiFormLabel-root-MuiInputLabel-root {
      color: white !important;
      font-size: 1.5rem;
    }
    .MuiAutocomplete-popupIndicator {
      color: white !important;
    }
    .MuiInputLabel-root {
      color: white !important;
      font-size: 1.5rem;
    }
    .MuiInputLabel-shrink {
      color: white !important;
      font-size: 1rem;
    }
    .MuiInput-root {
      color: white !important;
    }
    .MuiAutocomplete-clearIndicator:hover {
      background-color: transparent !important;
    }
    .MuiInput-root:after {
      border-bottom: none !important;
    }
    .MuiInput-root:hover {
      outline: none !important;
    }
    .MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
      border-bottom: none !important;
    }
    @media (pointer: fine) {
      .MuiAutocomplete-clearIndicator {
        visibility: hidden !important;
      }
    }
    .MuiAutocomplete-clearIndicator {
      visibility: hidden !important;
    }
    .MuiInput-input {
      /* font-size: 1.5rem; */
    }
    .MuiFormLabel-root-MuiInputLabel-root {
      font-size: 1.5rem;
    }

    @media print {
    display: none; 
    }
  `;

function Footer() {
  const { t } = useTranslation();
  const [contactIsOpen, setContactIsOpen] = useState(false);
  const countryUrlKeys = Object.keys(countryUrl);
  countryUrlKeys.sort((a, b) => a.localeCompare(b));

  function openLinkInNewTab(key) {
    const link = countryUrl[key];
    if (link) {
      window.open(link, "_blank");
    }
  }

  return (
    <Wrapper>
      <div className="main-footer">
        <FooterLayout>
          <div className="container-footer">
            <div className="container-row-logo">
              <div className="row-footer">
                <div className="col-footer">
                  <h4>{t("footer_company")}</h4>
                  <ul className="references">
                    <li>
                      <Link to="/about" className="footer-links">
                        {t("about_us")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/leadership" className="footer-links">
                        {t("who_we_are")}?
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://www.7andi.com/en/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="footer-links"
                      >
                        {t("7&i")}
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-footer">
                  <h4>Partnerships</h4>
                  <ul className="references">
                    <li>
                      <Link to="/int-partnerships" className="footer-links">
                        International Partnerships
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-footer">
                  <h4>{t("footer_careers")}</h4>
                  <ul className="references">
                    <li>
                      <Link to="/careers" className="footer-links">
                        {t("footer_careers")}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-footer">
                  <h4>Sustainability</h4>
                  <ul className="references">
                    <li>
                      <Link to="/sustainability" className="footer-links">
                        {t("footer_sustainability")}
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-footer">
                  <h4>{t("footer_help")}</h4>
                  <ul className="references">
                    <li>
                      <Link
                        to="/contact-us"
                        style={{ cursor: "pointer" }}
                        className="footer-links"
                      >
                        Contact Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/faqs" className="footer-links">
                        FAQs
                      </Link>
                    </li>
                  </ul>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
              <div className="logo-footer">
                <img src={footerLogo} alt="7-11 Logo"></img>
              </div>
            </div>
            <br />
            <br />
            <hr />
            <div className="row1">
              <div className="Social-Media-TnC-Container">
                <p className="col-footer-sm">
                  <Link to="/terms" className="footer-links">
                    {t("t&c")}
                  </Link>
                </p>
                <div className="Social-Media">
                  <div
                    //className="social-links"
                    style={{
                      backgroundColor: "black",
                      padding: "0",
                      borderRadius: "1%",
                      marginTop: "-0.15rem",
                    }}
                  >
                    <a
                      href="https://www.facebook.com/7ElevenInternational"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-links"
                    >
                      <FacebookRoundedIcon
                        style={{
                          color: "white",
                          height: "auto",
                          width: "2.15rem",
                        }}
                      />
                    </a>
                  </div>
                  <div className="social-links">
                    <a
                      href="https://www.instagram.com/7elevenintl/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-links"
                    >
                      <FaInstagram
                        color="black"
                        style={{ height: "auto", width: "1rem" }}
                      />
                    </a>
                  </div>
                  <div className="social-links">
                    <a
                      href="https://www.linkedin.com/company/7-eleven-international"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="footer-links"
                    >
                      <FaLinkedinIn
                        color="black"
                        style={{ height: "auto", width: "1rem" }}
                      />
                    </a>
                  </div>
                </div>
              </div>
              <br />
              <p className="col-footer-sm1">
                &copy;{new Date().getFullYear()} 7-Eleven International LLC. This site is intended for residents of the U.S. All rights reserved.
                <br />
                {t("footer_quote2")}
                <br />
                7‑Eleven Inc., P.O. Box 711, Dallas, TX 75221-0711.
              </p>
            </div>
          </div>
        </FooterLayout>
      </div>
    </Wrapper>
  );
}

export default Footer;
