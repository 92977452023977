import axios from 'axios';
import { pb_service } from "../../../config/environment";

export const unpublishItem = async (itemOriginID) => {

  let data = JSON.stringify({
    "itemOriginID": itemOriginID
  });

  let config = {
    method: 'patch',
    maxBodyLength: Infinity,
    url: `${pb_service}/api/itemmaster/unpublish`,
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
       
  return await axios.request(config);
}