import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import getCountryName from "../../../Utils/GetCountryName";
import { getCountryDataByProperty } from "../../../Utils/CommonUtils";

const currentCountry = getCountryDataByProperty(localStorage.getItem("country_id"), 'countryName')

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#1E795E",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    width: 150,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#1E795E66",
    color: "black",
  },
  "&:nth-of-type(even)": {
    backgroundColor: "#8BB8AB4D",
    color: "black",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, calories) {
  return { name, calories };
}

const PSAItemHierarchyTypeCodeId = 1;
const CatItemHierarchyTypeCodeId = 2;
function getHierarchyDesc(data, codeId) {
  let descResult = '';
  if (data.itemHierarchies) {
    data.itemHierarchies.forEach(itemHierarchy => {
      if (itemHierarchy.itemHierarchyCode && itemHierarchy.itemHierarchyCode.itemHierarchyTypeCodeId == codeId) {
        descResult =  itemHierarchy.itemHierarchyCode.description;
      }
    });
  }

  // return default empty string
  return descResult;
}

function getCurrencyHeader(){
  const currency = getCountryDataByProperty(localStorage.getItem("country_id"), 'currency')
  
  if(currentCountry === 'JAPAN') return `SEJ Sales Price (Tax Included)(${currency})`
  else
    return "Suggested Retail Price" + `(${currentCountry}${currency})` 
  

}

export default function CustomizedTables({ data }) {
  // const month=data.shelfLife?.substr(0,2);
  // const day=data.shelfLife?.substr(3,2);
  // month==="00"? day+` day`: day==="00"? month+` month` : month+` month `+day+` day`
  const countryOfOrigin= data.countryOfOrigin? getCountryName(data.countryOfOrigin): "";

  
  
  const sizeUnit= data.sizeUnitOfMeasureID ? data.sizeUnitOfMeasureID : "";
  const width= data.sizeWidth ? ((sizeUnit !== "")? data.sizeWidth + ` ${sizeUnit}`: data.sizeWidth) : "";
  const length= data.sizeLength ? ((sizeUnit !== "")? data.sizeLength + ` ${sizeUnit}`: data.sizeLength) : "";
  const height= data.sizeHeight ? ((sizeUnit !== "")? data.sizeHeight + ` ${sizeUnit}`: data.sizeHeight) : "";
  const weightUnit= data.weightUnitOfMeasureID ? data.weightUnitOfMeasureID : "";
  const weight= currentCountry === 'JAPAN' ? data.retailUnitOfMeasure : data.netWeight ? ((weightUnit !== "")? data.netWeight +` ${weightUnit}`: data.netWeight) : "";
  const cps= data.innerPackQuantity ? data.innerPackQuantity : "";
  const psa= getHierarchyDesc(data, PSAItemHierarchyTypeCodeId);
  const category= getHierarchyDesc(data, CatItemHierarchyTypeCodeId);
  const ldu= data.lowestDeliverableUnit ? data.lowestDeliverableUnit : "";
  const srp= data.minSrp ? data.minSrp===data.maxSrp ? `${data.minSrp.toFixed(2)} (*)` : `${data.minSrp.toFixed(2)} - ${data.maxSrp.toFixed(2)} (*)` : "";
  const shelfLife= data.shelfLife ? data.shelfLife : "";
  const longDescription= data.longDescription ? data.longDescription : "";
  const rows = [
    createData("Product Name", data.shortDescription),
    createData("Product Description", longDescription),
    createData("Country of Origin", countryOfOrigin),
    createData("Product Width", width),
    createData("Product Length", length),
    createData("Product Height", height),
    createData("Product Weight", weight),
    createData("Case Pack Size", cps),
    createData("PSA/PMA", psa),
    createData("Category", category),
    createData("LDU", ldu),
    createData(getCurrencyHeader(), srp),
    createData("Shelf Life", shelfLife),
  ];
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 300 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell
              style={{
                borderRight: "1px solid white",
                width: "35%",
                fontFamily: "Roboto",
                fontWeight: "500",
                wordWrap: "break-word",
                padding: "0.5rem 1rem",
              }}
            >
              Product Number
            </StyledTableCell>
            <StyledTableCell
              align="left"
              style={{
                fontFamily: "Roboto",
                fontWeight: "500",
                wordWrap: "break-word",
                padding: "0.5rem 1rem",
              }}
            >
              {currentCountry === 'USA' ? data.itemOriginID : data.licenseeSystemItemID}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => {
            if(row.calories === null || row.calories === undefined || row.calories === ""){
              return
            }
            return(
            <StyledTableRow key={row.name}>
              <StyledTableCell
                style={{
                  borderRight: "1px solid white",
                  width: "35%",
                  fontFamily: "Roboto",
                  fontWeight: "500",
                  wordWrap: "break-word",
                  padding: "0.5rem 1rem",
                }}
              >
                {row.name}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                style={{
                  fontFamily: "Roboto",
                  fontWeight: "500",
                  wordWrap: "break-word",
                  padding: "0.5rem 1rem",
                }}
              >
                {row.calories}
              </StyledTableCell>
            </StyledTableRow>
)})}
        </TableBody>
      </Table>
     {  (currentCountry === 'USA') ? 
       (<div><p style={{ margin: "0.5rem 0.8rem", color: "rgb(93, 93, 93)", fontWeight: 500, fontSize: "0.8rem"}}>(*) This product has been sold in this range at 7-Eleven stores in the United States (for your reference).  Additional costs (such as distribution fees, shipping fee, container handling charge, tax, GP and etc.) need to be added. Note that final costs and retail price will vary based on these factors.</p></div>
       )
       :null
     }
    </TableContainer> 
  );
}
