import * as React from "react";
import Modal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import "../components/Footer/ContactUs.css";
import "../components/Table/CustomTable.css";
import "../components/Assets/DigitalAssets.css";

const OptionsModal = (props) => {
    return (
        <Modal
            className="contactModal"
            isOpen={props.modalIsOpen}
            onRequestClose={props.closeModal}
            appElement={document.getElementById("root")}
          >
            <div className="contact-block">
              <div className="contact-container">
                <div className="btnContainer">
                  <div className="contactModalCloseBtn">
                    <button onClick={props.closeModal}>
                      <AiOutlineClose className="modal-close-btn" />
                    </button>
                  </div>
                </div>
                <div className="contactFormInput">
                  <div className="formInputLabel">
                    <h1 className="confirm-dialog-header">{props.headerText}</h1>
                    <p className="confirm-dialog-message">
                      {props.headerQuestion}
                    </p>
                  </div>
                  <div className="confirmationBtnContainer">
                    <button
                      className="submitBtn confirmBtn"
                      style={{ backgroundColor: "#b7b4b4" }}
                      onClick={() => props.closeModal() }
                    >
                      {props.leftButtonText}
                    </button>

                    <button
                      className="submitBtn confirmBtn"
                      onClick={() => {
                        props.closeModal();
                        if (props.isFolder) props.deleteFolder(props.deleteFolderKey);
                        else props.deleteFile(props.deleteKey);
                        props.setDeleteProcessing(true);
                      }}
                    >
                      {props.rightButtonText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
    )
}

export default OptionsModal;