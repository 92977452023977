import React from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Button from '@mui/material/Button';
import './ProductDetailsPage'; 
import { emailMe } from './BMService';
import { enqueueSnackbar } from 'notistack';
import { brandedMerchandiseMsgs } from '../../../Constants/Constant';


const EmailListButton = ({ emailList }) => {
  const displayToastMsg = (toastObj) => {
		enqueueSnackbar(toastObj.message, {
			variant: toastObj.variant,
			preventDuplicate: true,
			anchorOrigin: {
				vertical: "top",
				horizontal: "right",
			},
		});
	};

  const transformedList = emailList.map(item => ({
    itemMasterID: item.id,
    quantity: item.count
  }));
  const handleEmailClick = async () => {
    try {
      const response = await emailMe({data: transformedList});
      displayToastMsg({
        message: brandedMerchandiseMsgs.emailSuccess,
        variant: "success",
      });
    } catch (error) {
      console.error("Error sending email:", error);
      displayToastMsg({
        message: error.response?.data?.message || brandedMerchandiseMsgs.emailFailure,
        variant: "error",
      });
    }
  };

  return (
    <div className="email-list-wrapper"> 
      <Button
        variant="contained"
        size="large"
        startIcon={<MailOutlineIcon />}
        disabled={!emailList || emailList.length === 0} 
        onClick={handleEmailClick}
      >
        EMAIL LIST
      </Button>
    </div>
  );
};

export default EmailListButton;
