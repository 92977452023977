import React from 'react';
import Button from '@mui/material/Button';
import { Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './CommonButton.scss';

const CommonButton = ({ buttonText, tooltipText, routePath }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(routePath);
  };

  return (
    <div className="common-button-wrapper">
      <Tooltip title={tooltipText} arrow>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleClick}
        >
          {buttonText}
        </Button>
      </Tooltip>
    </div>
  );
};

CommonButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
  routePath: PropTypes.string.isRequired,
};

export default CommonButton;

 