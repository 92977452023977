import { React, useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import Button from '@mui/material/Button';
import { Stack } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useDispatch, useSelector } from 'react-redux';
import { addItemToList } from './PBCatalogSlice';
import { ItemNameContainer } from "./SubCategoryLayout";
import UnpublishConfirmation from "../Common/UnpublishConfirmation";
import { unpublishItem } from "./PBCatalogService";
import { useSnackbar } from "notistack";
import ProductCard from "../Common/ProductCard";

const ArrowCardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
`;
const CardsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  scroll-behavior: smooth;
  
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* leaving some space for gap */
  /* gap: 3rem; */

  /* @media (max-width: 1200px) {
    gap: 2rem;
  }
  @media (max-width: 1000px) {
    gap: 1rem;
  } */
  @media (max-width: 770px) {
    grid-template-columns: repeat(2, 1fr); /* leaving some space for gap */
    /* gap: 2rem; */
  }
`;
const ItemDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: start;
  justify-content: flex-start;
  align-items: flex-start;
  width: 14rem;
  :last-child {
    gap: 0;
  }
  
  .MuiButton-startIcon{
    margin-right: 2px;
    margin-left: 0px;
  }
  .MuiButton-outlined{
    margin: 0.5rem 0.5rem 0 0;
    color: #34a07c; 
    font-size: 0.75rem !important;
    align-items: center;
  }
  .add-btn{
    color: #34a07c;
    border: 1px solid #34a07c;
    :hover{
      border: 1px solid #34a07c;
    }
  }
  .rmv-btn{
    color: #EF5350;
    border: 1px solid #EF5350;
    :hover{
      border: 1px solid #EF5350;
    }
  }

  @media (max-width: 1200px) {
    width: 11rem;
  }
  @media (max-width: 1000px) {
    width: 9rem;
  }
  @media (max-width: 770px) {
    width: 14rem;
  }
`;
const ItemCard = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: white;
  /* border: 0.3px solid rgba(144, 144, 144, 0.3); */
  border-radius: 0.25rem;
  /* box-shadow: rgba(135, 134, 134, 0.35) 0px 3px 3px; */
  max-width: 100%;
  cursor: pointer;
`;
const SubCatCardImg = styled.img`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
/* const ItemNameContainer = styled.div`
  margin-top: 0.7rem;
  width: 100%;
  text-align: left;
  font-size: 1rem;
  min-height: 3rem;
  font-weight: 500;
  cursor: pointer;
`; */
const ScrollButton = styled.div`
  display: flex;
  align-items: center;
  z-index: 2;
  cursor: pointer;
  margin-left: 1rem;
  top: 35%;
`;
const LeftScrollButton = styled.div`
  display: flex;
  align-items: center;
  z-index: 2;
  cursor: pointer;
  background: linear-gradient(to right, rgb(0 0 0 / 7%), transparent);
  border-radius: 0.9rem 0 0 0.9rem;
  height: 12.7rem;
`;

const ScrollableSubCategory = ({ categoryId, subCatId, cardDetail, fetchSubCatData, psaId, shelfLifeFilterPayload }) => {
  const navigate = useNavigate();
  const [startIndex, setStartIndex] = useState(0);
  const [showUnpublishModal, setShowUnpublishModal] = useState(null);
  const [unpublishResp, setUnpublishResp] = useState("");
  const { countryName } = useParams();
  
  const dispatch = useDispatch();
  const {myList=[]} = useSelector(state => state.pbCatalog);
  const { enqueueSnackbar } = useSnackbar();

  const activeUsername = localStorage.getItem("userpermission");

  const showNextCards = () => {
    setStartIndex((prevIndex) => prevIndex + 1);
  };
  const showPrevCards = () => {
    setStartIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const visibleCards = cardDetail?.items.slice(startIndex, startIndex + 4);

  const handleCardClick = (psaName, subCat, itemID, itemName) => {
    const encodedPSA = encodeURIComponent(psaName);
    const encodedSubCat = encodeURIComponent(subCat);
    navigate(`/privateBrandCatalog/${countryName}/${encodedPSA}/${encodedSubCat}/${itemID}`);
  };

  const handleUnpublishBtnClick = (itemOriginID) => {
    setShowUnpublishModal(itemOriginID);
  };

  const displayToastMsg = (toastObj) => {
    enqueueSnackbar(toastObj.message, {
        variant: toastObj.variant,
        preventDuplicate: true,
        anchorOrigin: {
            vertical: "top",
            horizontal: "right",
        },
    });
  };
  
  const handleUnpublishConfirmation = (selectedBtn) => {
    const itemID=showUnpublishModal;
    setShowUnpublishModal(false);
    if (selectedBtn === "cancel") {
      return;
    }
    unpublishItem(itemID)
      .then((response)=>{
        setUnpublishResp("success");
        const isAdded=isItemAdded(itemID)
        if(isAdded){
          handlePdtToList(parseInt(itemID), isAdded);
        };
        let shelfLifeParams = null;
	      if(shelfLifeFilterPayload) {
		      shelfLifeParams = encodeURI(JSON.stringify(shelfLifeFilterPayload));
	      }
        fetchSubCatData(psaId, shelfLifeParams);
        return displayToastMsg({message: "Product unpublished successfully", variant: 'success'});
      })
      .catch((error)=>{
        console.error(error);
        setUnpublishResp("fail");
        return displayToastMsg({message: "Something went wrong", variant: 'error'});
      })
  };

  const isItemAdded = (itemId) => {
    return myList.some(item => item === itemId);
  };

  const handlePdtToList = (item,itemIsAdded) => {
    let updatedList;
    if (itemIsAdded) {
       updatedList = myList.filter(listItem => listItem !== item);
    } else {
       updatedList = [...myList, item];
    }
    dispatch(addItemToList(updatedList));
  };

  return (
    <ArrowCardsWrapper>
      {startIndex > 0 && (
        <LeftScrollButton onClick={showPrevCards}>
          <FaChevronLeft />
        </LeftScrollButton>
      )}
      <CardsContainer>
        {visibleCards.length?
          visibleCards.map((pdt, i) => {
          const itemIsAdded= isItemAdded(pdt.itemOriginID)
          return (
            <ProductCard 
            item={pdt}
            itemIsAdded={itemIsAdded}
            handleUnpublishBtnClick={handleUnpublishBtnClick}
            handlePdtToList={handlePdtToList}
            >  
            </ProductCard>
          );
        }) :
        "No Data"
        }
      </CardsContainer>
      {startIndex + 4 < visibleCards.length && (
        <ScrollButton onClick={showNextCards}>
          <FaChevronRight style={{ marginBottom: "1.7rem" }} />
        </ScrollButton>
      )}
      <UnpublishConfirmation show={showUnpublishModal} handleUnpublishConfirmation={handleUnpublishConfirmation}/>
    </ArrowCardsWrapper>
  );
};
export default ScrollableSubCategory;
